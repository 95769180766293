import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-action',
  template: `
  <button title="Edit" (click)="edit($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem" id="115" [disabled]="!hasPermission(128)">
    <i class="fas fa-pencil-alt"></i>
  </button>
  <button title="Delete" (click)="delete($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light"  style="padding: .3rem .8rem;margin-top:0rem;background:#d71920" [disabled]="!hasPermission(129)">
    <i class="far fa-trash-alt"></i>
  </button>`
})

export class Actionbutton implements ICellRendererAngularComp {

  params: any;
    label: string;
    permissions: any[] = [];

    agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;
    this.permissions = this.params.values || [];
    }

   refresh(params?: any): boolean {
    return true;
   }

   hasPermission(buttonId: number): boolean {
    // Check if 'screen' exists in the permissions array
    const hasScreen = this.permissions.some(permission =>
        typeof permission === 'string' && permission.includes('screen')
    );

    // If 'screen' is not present, enable all buttons
    if (!hasScreen) {
        return true;
    }

    // Otherwise, check if the specific buttonId is in the permissions list
    return this.permissions.includes(buttonId);
   }


  edit($event) {
    console.log("----------------")
    console.log($event)
    console.log("-------------------")
    if (this.params.edit instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.edit(params);

    }
  }
  delete($event) {
    if (this.params.delete instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.delete(params);

    }
  }
}
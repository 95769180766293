import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class constants {
    public Norows = "<span>No more details to show</span>"
    public deletedrows = "deletedrows"
    public ifDeleted = "No"
    public rowselection = "single"
    public vaultpagesize =400
    public pagesize = 10
    public btnname = "Save"
    public btnnameupdate = "Update"
    public deleterowMsg = "This record has been already deleted"
    public confirmDeletion = "Are you sure you want to delete the record ?"
    public Status = "No"
    public agelimit = 999
    public minagelimit = 1
    public salarylimit = 99999
    public minsalarylimit = 1
    public mindisplayorder = 1
    public maxdisplayorder = 127
    public Pincode = 999999
    public min = 1;
    public bigint = 999999;
    public validAttachement = "Please Upload valid attachment";
    public codeListDecodeTableName = "codelistdecode_t";
    INT11_value = 2147483647;
    Decimal10_2 = 99999999.99;
    materialCatTable = "materialcategory";
    materialCatColumns = "materialcategoryid,prodcatname";
    bpTable = "batchplant";
    bpColumns = "batchplantid,bpname";
    MATERIALSTABLE = 'materials';
    UserTable = "savedreports";
    ReportCreation = "reportcreation";
    UserColoumns = "userid"
    userid = JSON.parse(localStorage.getItem("UserId"));

    public Deleted_Dataset ="Selected Dataset is already deleted";

    public Access_Denied_delete ="Access Denied. Only Owner Of Dataset Can Delete";
   
    public Access_Denied = "Access Denied. Only Owner Of Dataset Can Modify";

    public Access_report_Denied = "Access Denied. Only Owner Of ReportTemplate Can Modify";

    public Request_Failed = "Permission Request Failed";

    public Access_Unchanged="Dataset Access Remains Unchanged.";

    public DATEFORMAT_WITHOUTTIME = 'YYYY-MM-DD';

    public endDateName = "Column Type Start Date Field Name and End Date must not be same.";

    public startDateName = "Column Type End Date Field Name and Start Date must not be same.";

    public endDateTimeName = "Column Type Start Date Time Field Name and End Date Time must not be same.";

    public startDateTimeName = "Column Type End Date Time Field Name and Start Date Time must not be same.";

    public imageName = "Column Type File Path Field Name and File Name must not be same.";

    public dropDownChildFieldName = "Column Type Parent DropDown Field Name and Drop Down Child must not be same.";

    public duplicateEndDateName = "Remove duplicate End Date Name ";

    public duplicateEndDateNameSuffix = " of column type Start Date. ";

    public duplicateStartDateName = "Remove duplicate Start Date Name ";

    public duplicateStartDateNameSuffix = " of column Name End Date. ";

    public duplicateEndDateTimeName = "Remove duplicate End Date Time Name ";

    public duplicateEndDateTimeNameSuffix = " of column type Start Date Time. ";

    public duplicateStartDateTimeName = "Remove duplicate Start Date Time Name ";

    public duplicateStartDateTimeNameSuffix = " of column type End Date Time. ";

    public duplicateFileName = "Remove duplicate File Name ";

    public duplicateFileNameSuffix = " of column Type File Path. ";

    public duplicateDropDownName = "Remove duplicate Child DropDown Field Name ";

    public duplicateDropDownNameSuffix = " of column type Parent DropDown. ";

    public columnExist = "Please create ";

    public columnExistEndDate = " column with column type End Date.";

    public columnExistStartDate = " column with column type Start Date.";

    public columnExistEndDateTime = " column with column type End Date Time.";

    public columnExistStartDateTime = " column with column type Start Date Time.";

    public columnExistFileName = " column with column type File Name.";

    public columnExistDropDown = " column with column type DropDown.";

    public isColumnSelected = "Please select column name.";

    public inputValueGreater = "Please enter value lesser or equal to 100";

    public duplicateFieldName = "Field Name will not accept duplicate value. The duplicate value is ";

    public parentLevel = "Please select Parent Level.";

    public masterTableMessage = "Please add minimum one field in Master Table.";

    public transactionTableMessage = "Please add minimum one field in Transaction Table.";

    public deleteMasterTable = "It is a system table, you can not delete it.";

    public confirmMeassageForTool = "This action will convert your file. Do you want to continue!!";

    //Constants for JMigrator Tools
    public toolNotSelected = "You should  select tool from the tool list";

    public noOperationForTools = "Id you have selected is not match with any operations";

    public confirmMessageForXmlToCsvToSql = "This action will Convert and load your Xml to sql. Do you want to continue!!";

    public confirmMessageFoXmlModifier = "This action will modify your Xml. Do you want to continue!! ";

    public confirmMessageForXmlSplitter = "This action will split your Xml file.  Do you want to continue!!";

    public confirmMessageForCsvToSql = "This action will convert your Csv file to Sql.  Do you want to continue!! ";

    public noRowsSelectedInGrid = "No rows selected in the Grid. Please Select Atleast one row to continue!!";

    public checkBoxnotSelected ="Please select the Default value and checkbox of the Default value should be selected!!";

    public noFileSelected ="Please select the Zip File";

    public noRecordSeleted ="Please Enter No of Record";

    public minRecord ="Must select at least 1000 records";

    public noCsvFileSelected ="Please select the CSV File";

    public selectR2XMLorR3XML='please select XML type'

    public noUserActions = 'No User Actions to Perform or Please check the csv format.';

    public confirmMessageForUpdateCaseState = "This action will update the case state of your csv file. Do you want to continue!!"

    public secretKey = "123456$#@$^@1ERF"

    public pleaseUploadCsvFile = "Please Upload Csv File";

    public isroleassigned ="Insert operation can not be perform as user role already configured."

    public userNoPermissionAccessScreen = "The user lacks authorization to view any screen."

    public noDetailForAttachmentExtraction = "No load details available for Attachment Extractions."

    public loadFtpisNotCompleted = " Load to FTP is not completed, please wait for load to complete."

    public loaderFileGenerationStatus = "The generation of the Loader file is currently \"In Progress\" Please wait till the task is completed."

    public failureLogNotAvailable = "Failure log is not available."

    public successLogNotAvailable = "Success log is not available."

    public  loaderFileStatusFailed = "This attachment cannot be loaded as loader file generation \"Failed.\""

    public migrationAllReadyRunning =  "Already one migration for ETL or E2B is running, please abort that and rerun."

    public fileNotFound =  "File does not exist."

    public objectRefreshed = "Object Refreshed."

    public deletionConformationMessage = "Initiating Deletion for Cases Loaded into Vault, for the selected set. The data deleted from Vault, " +
    "connot be retrieved.\nTo Initiate deletion, enter \"delete\" in the text input field"

    public checkingReportId = "Please select report name.";

    public successfullyInserted = "Successfully record is inserted."

    public successfullyUpdated = "Successfully record is updated."

    public charecterDot = ".";

    

    public charecterUnderScore = "_";



     //auditTrail Constraints
     public selectFromDate="Please select From Date."
     public selectToDate="Please select To Date."
     public toDateShouldBeGreator="To Date should be equal or greater than From Date."

    public instructionsNotAvaialble="Instructions not available"
    public exceptionError="Error Encoutered. Please try Again"
}

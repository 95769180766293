import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { RestApi } from "src/app/shared/RestApi/restApi";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

interface trnxCommonServiceInfo {
  statusMessage: any;
  error: boolean;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class TrnxCommonService {

  private headers = new HttpHeaders({ "Content-Type": "application/json" });
  childFailure = "";
  deleteresponse: any

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private spinner: NgxUiLoaderService,
    private restAPI: RestApi,
    private router: Router
  ) { }

  async retrieveTrnxService(tableName, recordsStart) {

    this.spinner.start();

    console.log(this.restAPI.GET_TRNXTABLE_DATA_URL + "/" + tableName + "/" + recordsStart + "/" + 1 + "/" + "a");

    let response = await this.http.get<trnxCommonServiceInfo>(this.restAPI.GET_TRNXTABLE_DATA_URL + "/" + tableName + "/" + recordsStart + "/" + 1 + "/" + "a", { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }
  async retrieveTrnxServicePaginated(tableName, recordsStart,pageNo,pageSize,totalRecords) {

    this.spinner.start();

    console.log(this.restAPI.GET_TRNXTABLE_DATA_URL + "/" + tableName + "/" + recordsStart + "/" + 1 + "/" + "a"+"/"+ pageNo +"/" +pageSize);

    let response = await this.http.get<trnxCommonServiceInfo>(this.restAPI.GET_TRNXTABLE_DATA_URL + "/" + tableName + "/" + recordsStart + "/" + 1 + "/" + "a"+"/"+ pageNo +"/" +pageSize+"/" +totalRecords, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async retrieveChildTrnxService(tableName, recordsStart, childKey, tableId, screenId) {

    this.spinner.start();

    let response = await this.http.get<trnxCommonServiceInfo>(this.restAPI.GET_CHILDTRNXTABLE_DATA_URL + "/" + tableName + "/" + recordsStart + "/" + childKey + "/" + tableId + "/" + screenId + "/" + 1 + "/" + "a", { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async retrieveTrnxDropdownService(tableName, recordsStart, dropDownValuesDisplay) {

    this.spinner.start();

    /**
     * @modified By Vijay Badha
     * @Date 05-04-2024
     * changed the api from GET_TRNXTABLE_DATA_URL  to GET_TRNX_TABLE_DATA_FOR_DROPDOWNS for
     * retrieving the tables data for drop down
     */
    let response = await this.http.get<trnxCommonServiceInfo>(this.restAPI.GET_TRNX_TABLE_DATA_FOR_DROPDOWNS + "/" + tableName + "/" + dropDownValuesDisplay, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async insertTrnxService(temporaryObject, temporaryChildObject, idName, temporarytableName, screenTypeTOrM) {

    console.log(temporaryObject);

    console.log(temporaryChildObject);

    let temporaryChildObjectDuplicate = [];

    temporaryChildObject.filter(temporaryChildObjectTemp => {

      let listTemporary: any = [];

      temporaryChildObjectTemp.filter(temporaryChildObjectTemporary => {

        listTemporary.push(Object.assign({}, temporaryChildObjectTemporary));

      });

      temporaryChildObjectDuplicate.push(listTemporary);
   

    });

    temporaryChildObjectDuplicate.unshift(temporaryObject);

    console.log(temporaryChildObjectDuplicate);

    this.spinner.start();

    let response = await this.http.post<trnxCommonServiceInfo>(this.restAPI.CREATE_TRNXRECORD_URL, temporaryChildObjectDuplicate, { headers: this.headers }).toPromise();
    console.log(temporaryChildObjectDuplicate);
    if (response.error == true) {

      temporaryChildObject = [];

      this.spinner.stop();

      console.log(response);

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return response.error;

    }

    else {

      console.log(response);

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return response.error;

    }

  }

  async updateTrnxService(temporaryObject, tableName, idName, idValue) {

    console.log(temporaryObject);

    this.spinner.start();

    let response = await this.http.put<trnxCommonServiceInfo>(this.restAPI.UPDATE_TRNXRECORD_URL + "/" + tableName + "/" + idName + "/" + idValue, temporaryObject, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

    }

    else {

      this.spinner.stop();

      console.log(response);

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

    }

    return response.error;

  }

  async deleteTrnxService(tableName, idName, idValue, screenTypeTOrM,ngModelObject) {

    this.spinner.start();
       console.log(ngModelObject);

       const options = {
        headers: this.headers,
        body: JSON.stringify(ngModelObject)
     };
    let response = await this.http.delete<trnxCommonServiceInfo>(this.restAPI.DELETE_TRNXRECORD_URL + "/" + tableName + "/" + idName + "/" + idValue + "/" + screenTypeTOrM,options).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

    }
    else {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 50000 });
    }


  }

  public download_test(supportingDoc): Observable<Blob> {

    return this.http.get(supportingDoc, { responseType: 'blob' });

  }

  async downloadImage(supportingDoc) {

    let response = await this.http.get(supportingDoc, { responseType: 'blob' }).toPromise();

    return response;

  }

  async retrieveReportService(reportCreatorMstrId, startDate, endDate) {

    console.log(reportCreatorMstrId);

    console.log(startDate);

    console.log(endDate);

    this.spinner.start();

    console.log(this.restAPI.RETRIEVE_COMMON_REPORT_URL + "/" + reportCreatorMstrId + "/" + startDate + "/" + endDate);

    let response = await this.http.get<trnxCommonServiceInfo>(this.restAPI.RETRIEVE_COMMON_REPORT_URL + "/" + reportCreatorMstrId + "/" + startDate + "/" + endDate, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  


}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-action',
  template: `
  <button title="Edit" (click)="edit($event);" [disabled]="status" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
    <i class="fas fa-pencil-alt"></i>
  </button>
  <button title="Delete" (click)="delete($event);" [disabled]="status" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light"  style="padding: .3rem .8rem;margin-top:0rem;background:#d71920">
    <i class="far fa-trash-alt"></i>

  </button>
    <button title="Schedule" (click)="schedule($event);"   [disabled]="status || isschedulerdisable" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem;background:#F4C430">
  <i class="fas fa-calendar-alt"></i>
    </button>
    <button title="Report_Template_Permission" (click)="access_permission($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem;background:#00cc66">
        <i class="	fas fa-user-shield"></i>
    </button>
    <button title="Run Adhoc Report" (click)="runAdhoc($event);" [disabled]="status || isRunnable" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem;background:green">
  <i class="fas fa-play"></i>
  </button>`
  
 
})

export class TemplateReportButtons implements ICellRendererAngularComp {

  params;
  label: string;
  status
  isschedulerdisable = false
  isRunnable = false

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    console.log(this.params.node.data);
    if(this.params.node.data.reportTypeName == "Sub Report" ){
      this.isschedulerdisable = true
    }else if(this.params.node.data.reportTypeName == "Stand Alone" ){
      this.isschedulerdisable = false
    }
    else{ 
      this.isRunnable=true
    }
   
    if(this.params.node.data.status== 'Yes'){
      this.status = false
    }else {
      this.status = true
    }
    
  }

  refresh(params?: any): boolean {
    return true;
  }

  edit($event) {
    if (this.params.edit instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.edit(params);

    }
  }
  delete($event) {
    if (this.params.delete instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.delete(params);

    }
  }

  schedule($event) {
    if (this.params.schedule instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.schedule(params);

    }
  }

  /**
   * @author Nitin kumar
   * @param $event
   * @apiNote - for run button event 
   */
  runAdhoc($event) {
    if (this.params.runAdhoc instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.runAdhoc(params);

    }
  }
  access_permission($event){
    if (this.params.access_permission instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.access_permission(params);

    }

  }
}

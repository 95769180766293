import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RestApi } from "src/app/shared/RestApi/restApi";

interface reportlinkinfo {
  statusMessage: any,
  error: boolean,
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class ReportlinksserviceService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private Http: HttpClient, private rest: RestApi) { }

  
  retrievereportlinks(){
    return this.Http.get<reportlinkinfo>(this.rest.RETRIEVE_REPORTLINKS_URL, { headers: this.headers })

  }

  retrevegroupvalues(){
    return this.Http.get<reportlinkinfo>(this.rest.GET_TABLE_SINGLE_RECORD_URL+"/"+"lookupvalue/lookuptypeid/20", { headers: this.headers })
  }

  insertreportlinks(reportlink,parentKey) {
    return this.Http.post<reportlinkinfo>(this.rest.INSERT_REPORTLINKS_URL+"/"+parentKey, JSON.stringify(reportlink), { headers: this.headers });
  }

  insertReportPopUp(reportPopUp){
    return this.Http.post<reportlinkinfo>(this.rest.INSERT_REPORTPOPUP_URL, JSON.stringify(reportPopUp), { headers: this.headers });
  }

  insertToVault(filedata,reportInstanceChildId){
    return this.Http.put<reportlinkinfo>(this.rest.UPDATE_SENTVAULT_URL+"/"+reportInstanceChildId,JSON.stringify(filedata), { headers: this.headers });
  }

  insertReportInstance(reportPopUp){
    return this.Http.post<reportlinkinfo>(this.rest.INSERTONLYREPORTINSTANCE, JSON.stringify(reportPopUp), { headers: this.headers });
  }

  scheduleNextReport(startDate,endDate,dnsId){
    return this.Http.get<reportlinkinfo>(`${this.rest.SCHEDULENEXTREPORT}/${startDate}/${endDate}/${dnsId}`, { headers: this.headers })
  }

  generateAnalysis(reportInstanceDetailsId, dnsId){
    return this.Http.post<reportlinkinfo>(this.rest.GENERATE_ANALYSIS_URL + "/" + reportInstanceDetailsId + "/" + dnsId, { headers: this.headers });
  }
  
  adhocReport(reportTemplateId,scheduleId,filterArray,report){
    const reportName = report || 'default_Report_Name';
    return this.Http.post<reportlinkinfo>(`${this.rest.ADHOC_REPORT}/${reportTemplateId}/${scheduleId}/${reportName}`,JSON.stringify(filterArray),{ headers: this.headers })
  }


  retrievereport(UserId,dnsName,AuthUsername){
    return this.Http.get<reportlinkinfo>(this.rest.RETRIEVE_REPORT_URL+"?userid="+UserId+"&dnsid="+dnsName+"&datasetowner="+AuthUsername, { headers: this.headers })

  }

  generateDatasetAnalysis(DatasetQuickSight){
    return this.Http.post<reportlinkinfo>(this.rest.UPDATEDATASET_QUICK_SIGHT_URL + "/" ,JSON.stringify(DatasetQuickSight), { headers: this.headers });
  }
  
  fetchUserRole(dnsName,username){
    return this.Http.get<reportlinkinfo>(this.rest.FetchUserRole_URL+dnsName+"/"+username,{ headers: this.headers })
  }

  fetchUserRoleAndName(dnsName,username){
    return this.Http.get<reportlinkinfo>(this.rest.FetchUserRoleAndName_URL+dnsName+"/"+username,{ headers: this.headers })
  }


  updateDatasetVisuals(visualObject) {
    return this.Http.post<reportlinkinfo>(this.rest.UPDATE_DATASET_VISUALS, JSON.stringify(visualObject), { headers: this.headers });
  }

  updateDatasetColumnsVisuals(visualObject) {
    return this.Http.post<reportlinkinfo>(this.rest.UPDATE_DATASET_COLUMNS_VISUALS, JSON.stringify(visualObject), { headers: this.headers });
  }
  
 
  screenoperationspermission(screenid,userId){
    return this.Http.get<reportlinkinfo>(this.rest.Retrivescreenoperationspermission+"/"+screenid+"/"+userId, { headers: this.headers });
  }

  insertStageObject(reportlink,parentKey,dnsid) {
    
    return this.Http.post<reportlinkinfo>(this.rest.INSERT_STAGE_OBJECT_URL+"/"+parentKey+"/"+dnsid, JSON.stringify(reportlink), { headers: this.headers });
  }

  retrieveStageObject(dnsid){
    return this.Http.get<reportlinkinfo>(this.rest.RETRIEVE_STAGE_OBJECT_URL+"/"+dnsid, { headers: this.headers })

  }

}

import { Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ErrorMessages {

    constructor() { }

    //Message for valid attachment.
    validAttachement = "Please Upload valid attachment";

    // Field validation messages
    forgotpassword = {
        'emailformcontrol': [
            { type: 'required', message: 'Please Enter Email.' },
            { type: 'email', message: 'Please Enter Valid Email.' }
        ],
    }
    login = {
        'emailFormControl': [
            { type: 'required', message: 'Username is required.' },
            { type: 'email', message: 'Enter valid Username.' }
        ],
        'passwordFormControl': [
            { type: 'required', message: ' Password is required.' },
            { type: 'minlength', message: 'Password must be at least 8 characters long.' },
            { type: 'pattern', message: ' Your password must contain at least one uppercase, one lowercase, one special character and one number.' }
        ],
    }

    groupByMessages = {
        'fieldsGroupBy': [
            { type: 'required', message: 'Fields is required.' }
        ]
    }

    orderByMessages = {
        'fieldsOrderBy': [
            { type: 'required', message: 'Fields is required.' }
        ],
        'arrangeOrderBy': [
            { type: 'required', message: 'Arrange is required.' }
        ]
    }

    changepassword = {
        'oldPassword': [
            { type: 'required', message: 'Current password is required.' },
            { type: 'whitespace', message: 'Space not allowed.' }
        ],
        'newPassword': [
            { type: 'required', message: 'Please enter new password.' },
            { type: 'pattern', message: 'Enter valid Password.' }
        ],
        'cPassword': [
            { type: 'required', message: 'Please Re-enter password.' },
            { type: 'equalTo', message: 'Password and Re-enter password did not match.' }
        ],
    }

    screenCreatorMessages = {
        'screenName': [
            { type: 'required', message: 'Screen Name is required.' },
            { type: 'whitespace', message: 'Screen Name should not contain any white spaces.' },
            { type: 'maxlength', message: 'Screen Name is exceed maximum length 50.' }
        ],
        'tableName': [
            { type: 'required', message: 'Table Name is required.' },
            { type: 'whitespace', message: 'Table Name should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'Table Name is exceed maximum length 64.' }
        ],
        'tableID': [
            { type: 'required', message: 'Table ID is required.' },
            { type: 'whitespace', message: 'Table ID should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'Table ID is exceed maximum length 64.' }
        ],
        'numberOfColumnsInRow': [
            { type: 'required', message: 'Please Select Number Of Columns In Row.' },
        ],
        'screenImageName': [
            { type: 'required', message: 'Screen Image is required.' }
        ]
    }

    screenCreatorDataMessages = {
        'columnName': [
            { type: 'required', message: 'Field Name is required.' },
            { type: 'whitespace', message: 'Field Name should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'Field Name is exceed maximum length 64.' }
        ],
        'displayFormColumnName': [
            { type: 'required', message: 'Form Name is required.' },
            { type: 'whitespace', message: 'Form Name should not contain any white spaces.' }
        ],
        'formColumnShow': [
            { type: 'required', message: 'Please Select Show Form.' },
        ],
        'displayAgGridColumnName': [
            { type: 'required', message: 'AgGrid Name is required.' },
            { type: 'whitespace', message: 'AgGrid Name should not contain any white spaces.' }
        ],
        'agGridColumnShow': [
            { type: 'required', message: 'Please Select Show AgGrid.' }
        ],
        'isRequired': [
            { type: 'required', message: 'Please Select Required.' }
        ],
        'isUnique': [
            { type: 'required', message: 'Please Select Unique.' }
        ],
        'maximumLength': [
            { type: 'required', message: 'Maximum Length is required.' },
            { type: 'whitespace', message: 'Maximum Length should not contain any white spaces.' },
            { type: 'max', message: 'Maximum Length is exceed maximum length.' },
            { type: 'pattern', message: 'Maximum Length allows only positive integers.' }
        ],
        'maximumValue': [
            { type: 'required', message: 'Maximum Value is required.' },
            { type: 'pattern', message: 'Please enter numeric value.' },
            { type: 'max', message: 'Maximum value is exceed.' }
        ],
        "maximumDecimalValue": [
            { type: 'required', message: 'Maximum Decimal Value is required.' },
            { type: 'pattern', message: 'Please enter numeric value.' },
            { type: 'max', message: 'Maximum value is exceed.' }
        ],
        'endDateName': [
            { type: 'required', message: 'End Date is required.' },
            { type: 'whitespace', message: 'End Date should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'End Date is exceed maximum length 64.' }
        ],
        'startDateName': [
            { type: 'required', message: 'Start Date is required.' },
            { type: 'whitespace', message: 'Start Date should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'Start Date is exceed maximum length 64.' }
        ],
        'endDateTimeName': [
            { type: 'required', message: 'End Date Time is required.' },
            { type: 'whitespace', message: 'End Date Time should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'End Date Time is exceed maximum length 64.' }
        ],
        'startDateTimeName': [
            { type: 'required', message: 'Start Date Time is required.' },
            { type: 'whitespace', message: 'Start Date Time should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'Start Date Time is exceed maximum length 64.' }
        ],
        'dropDownTableName': [
            { type: 'required', message: 'Please Select DropDown Table Name.' }
        ],
        'dropDownIDs': [
            { type: 'required', message: 'DropDown IDs is required.' },
            { type: 'whitespace', message: 'DropDown IDs should not contain any white spaces.' }
        ],
        'dropDownValues': [
            { type: 'required', message: 'Please Select DropDown Values.' }
        ],
        'dropDownChildFieldName': [
            { type: 'required', message: 'Child DropDown Field Name is required.' },
            { type: 'whitespace', message: 'Child DropDown Field Name should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'Child DropDown Field Name is exceed maximum length 64.' }
        ],
        'imageName': [
            { type: 'required', message: 'File Name is required.' },
            { type: 'whitespace', message: 'File Name should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'File Name is exceed maximum length 64.' }
        ],
        'maximumDate': [
            { type: 'required', message: 'Maximum Date is required.' }
        ],
        'maximumDateTime': [
            { type: 'required', message: 'Maximum Date Time is required.' }
        ]
    }

    screenCreatorTransactionMessages = {
        'screenName': [
            { type: 'required', message: 'Screen Name is required.' },
            { type: 'whitespace', message: 'Screen Name should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'Screen Name is exceed maximum length 64.' }
        ],
        'displayName': [
            { type: 'required', message: 'Display Name is required.' },
            { type: 'whitespace', message: 'Display Name should not contain any white spaces.' },
            { type: 'maxlength', message: 'Display Name is exceed maximum length 50.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'numberOfColumnsInRow': [
            { type: 'required', message: 'Please Select Number Of Columns In Row.' },
        ],
        'screenImageName': [
            { type: 'required', message: 'Screen Image is required.' },
        ]
    }

    numberOfFormsMessages = {
        'numberOfForms': [
            { type: 'required', message: 'Number Of Forms is required.' },
            { type: 'pattern', message: 'Please enter postive integer.' },
            { type: 'max', message: 'Number Of Forms maximum value is 100.' },
            { type: 'min', message: "Number Of Forms minimum value is 1." }
        ]

    }

    concreteUpdateReportMessages = {
        'materialcategoryid': [
            { type: 'required', message: 'Material categories is required.' }
        ],
        'date': [
            { type: 'required', message: 'Select date type is required.' }
        ],
        'requisitionstatus': [
            { type: 'required', message: 'Requistion Status is required.' }
        ],
        'orderstatus': [
            { type: 'required', message: 'Order Status is required.' }
        ],
        'issuedquantity': [
            { type: 'required', message: 'Dispatch Quantity is required.' },
            { type: 'pattern', message: 'Please enter numeric value.' },
            { type: 'min', message: 'Dispatched quantity should be greater than or equal to one.' },
            { type: 'max', message: 'Max Number of value reached.' },
        ],
        'dispatchnumber': [
            { type: 'required', message: 'Dispatch Number is required.' },
            { type: 'pattern', message: 'Please enter numeric value.' },
        ],
        'materialquality': [
            { type: 'required', message: 'Material Quality is required.' },
            { type: 'whitespace', message: 'Material Quality should not contain any white spaces.' }
        ],
        'quantity': [
            { type: 'required', message: 'Quantity is required.' },
            { type: 'pattern', message: 'Please enter numeric value.' },
            { type: 'min', message: 'Quantity should be greater than or equal to one.' },
            { type: 'max', message: 'Max Number of value reached.' },

        ],
        'batchplantid': [
            { type: 'required', message: 'Batch Plant is required.' }
        ],
        'retstoreqty': [
            { type: 'pattern', message: 'Please enter numeric value.' },
            { type: 'max', message: 'Max Number of value reached.' },
        ],
        'scrapqty': [
            { type: 'pattern', message: 'Please enter numeric value.' },
            { type: 'max', message: 'Max Number of value reached.' },
        ],
        'precessqty': [
            { type: 'pattern', message: 'Please enter numeric value.' },
            { type: 'max', message: 'Max Number of value reached.' },
        ],
        'remarks': [
            { type: 'required', message: 'Remarks is required.' },
            { type: 'whitespace', message: 'Remarks should not contain any white spaces.' },
        ],
      'assignuserroleid': [
        { type: 'required', message: 'User Role is required.' }
      ],
      'userid': [
        { type: 'required', message: 'User Name is required.' }
      ],
    }

    deleteScreenNameMessages = {
        'screenName': [
            { type: 'required', message: 'Screen Name is required.' },
            { type: 'whitespace', message: 'Screen Name should not contain any white spaces.' },
            { type: 'pattern', message: 'Please enter only lower case alphabets.' },
            { type: 'maxlength', message: 'Screen Name is exceed maximum length 64.' }
        ]
    }

    profileMessages = {
        'phoneNumber': [
            { type: 'required', message: 'Phone Number is required.' },
            { type: 'whitespace', message: 'Phone Number should not contain any white spaces.' },
            { type: 'maxlength', message: 'Phone Number is exceed maximum length 15.' }
        ]
    }

    reportMessages = {
        'dataSet': [
            { type: 'required', message: 'Dataset Name is required.' },
            { type: 'whitespace', message: 'Dataset Name  should not contain any white spaces.' },
            { type: 'maxlength', message: 'Dataset Name is exceed maximum length 100.' }
        ]
    }
    templatecreationmessages = {
        'templatename': [
            { type: 'required', message: 'Template Name is required.' },
            { type: 'whitespace', message: 'Template Name  should not contain any white spaces.' },
            { type: 'maxlength', message: 'Template Name is exceed maximum length 100.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'reportname': [
            { type: 'required', message: 'Report Name is required.' },
            { type: 'whitespace', message: 'Report Name should not contain any white spaces.' },
            { type: 'maxlength', message: 'Report Name is exceed maximum length 100.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'selectschedule': [
            { type: 'required', message: 'Select Schedule field is required.' }
        ],
        'selectfilter': [
            { type: 'required', message: 'Select filter field is required.' }
        ]
    }

    schedulerReportMessages = {
        'reportname': [
            { type: 'required', message: 'Report Name is required.' },
            { type: 'whitespace', message: 'Report Name  should not contain any white spaces.' },
            { type: 'maxlength', message: 'Report Name is exceed maximum length 150.' }
        ],
        'date': [
            { type: 'required', message: 'Select schedule frequency is required.' }
        ],
        'reporttypeid': [
            { type: 'required', message: 'Report Type is required.' }
        ],
        'reportreferenceid': [
            { type: 'required', message: 'Report Reference is required.' }
        ],
        'productid': [
            { type: 'required', message: 'Products is required.' }
        ],
        'authoritiesid': [
            { type: 'required', message: 'Authorities is required.' }
        ],
        'scheduletime': [
            { type: 'required', message: 'Schedule Time is required.' }
        ],
    }

        reportConfiguration = {
        'selectdataSet': [
            { type: 'required', message: 'Select Dataset is required.' },
            { type: 'whitespace', message: 'Select Dataset  should not contain any white spaces.' },
            { type: 'maxlength', message: 'Select Dataset is exceed maximum length 150.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'description': [
            { type: 'required', message: 'Description is required.' },
            { type: 'whitespace', message: 'Description  should not contain any white spaces.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'sourceDNS': [
            { type: 'required', message: 'SourceDNS is required.' },
            { type: 'whitespace', message: 'SourceDNS  should not contain any white spaces.' }
        ],
        'type': [
            { type: 'required', message: 'Type is required.' },
            { type: 'whitespace', message: 'Type  should not contain any white spaces.' }
        ],
        'groupname': [
            { type: 'required', message: 'Groupname is required.' },
            { type: 'whitespace', message: 'Groupname  should not contain any white spaces.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'objectGroup': [
            { type: 'required', message: 'Object Group is required.' },
            { type: 'whitespace', message: 'Object Group  should not contain any white spaces.' }
        ],
        'groupExtractionType': [
            { type: 'required', message: 'Group Extraction Type is required.' },
            { type: 'whitespace', message: 'Group Extraction Type should not contain any white spaces.' }
        ],'setsSplitRequired': [
            { type: 'required', message: 'Set Split Required is required.' },
            { type: 'whitespace', message: 'Set Split Required should not contain any white spaces.' }
        ],
        'csvtojsoncsvpartitionname': [
            { type: 'required', message: 'Partition By is required' },
            { type: 'whitespace', message: 'Partition By should not contain any white spaces.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'csvtojsonpartitionsubstringstartposition': [
            { type: 'required', message: 'Start Position is required.' },
            { type: 'whitespace', message: 'Start Position should not contain any white spaces.' }
        ],
        'csvtojsonpartitionsubstringlength': [
            { type: 'required', message: 'Partition Length is required.' },
            { type: 'whitespace', message: 'Partition Length should not contain any white spaces.' }
        ],
        'extractionsource': [
            { type: 'required', message: 'Extraction Method is required.' },
            { type: 'whitespace', message: 'Extraction Method should not contain any white spaces.' }
        ], 'fieldsfromobjectmetadata': [
            { type: 'required', message: 'Fields From Objectmetadata is required.' },
            { type: 'whitespace', message: 'Fields From Objectmetadata should not contain any white spaces.' }
        ],

    }

    filterMessages = {
        'filtername': [
            { type: 'required', message: 'Filter Name is required.' },
            { type: 'whitespace', message: 'Filter Name  should not contain any white spaces.' },
            { type: 'maxlength', message: 'Filter Name is exceed maximum length 100.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'filterdescription': [
            { type: 'required', message: 'FIlter Description is required.' },
            { type: 'whitespace', message: 'FIlter Description  should not contain any white spaces.' },
            { type: 'maxlength', message: 'FIlter Description is exceed maximum length 100.' },
            { type: 'specialCharacter', message: 'Special characters are not allowed.' }
        ],
        'selectdataSet': [
            { type: 'required', message: 'Select Dataset is required.' }
        ],
        'setAsDefaultFilter': [
            { type: 'required', message: 'Default Filter is required.' }
        ],
    }
    toolConfiguration ={
        'sourceFolder': [
            { type: 'required', message: 'Source Folder Path  is required.' },
            { type: 'whitespace', message: 'Source Folder Path should not contain any white spaces.' },
            { type: 'maxlength', message: 'Source Folder Path is exceed maximum length 100.' }
        ],
        'destFolder': [
            { type: 'required', message: 'Destination Folder Path is required.' },
            { type: 'whitespace', message: 'Destination Folder Path  should not contain any white spaces.' },
            { type: 'maxlength', message: 'Destination Folder Path is exceed maximum length 100.' }

        ],
        'csvFileFolder': [
            { type: 'required', message: 'CSV File Folder Path is required.' },
            { type: 'whitespace', message: 'CSV File Folder Path  should not contain any white spaces.' },
            { type: 'maxlength', message: 'CSV File Folder Path is exceed maximum length 100.' }

        ],
        'databasename': [
            { type: 'required', message: 'Database Name is required.' },
            { type: 'whitespace', message: 'Database Name  should not contain any white spaces.' },
            { type: 'maxlength', message: 'Database Name  is exceed maximum length 100.' },
            { type: 'pattern', message: 'Only [a-z, A-Z, 0-9] can be used as database names. ' }

        ],
        'action': [
            { type: 'required', message: 'Action is required.' },

        ],
        'objectName':[
            {type:'required', message:'Object Name is required.'},
        ]


    }

    objectattributes={
        'databasename':[
            { type: 'required', message: 'Database Name is required.' },
            { type: 'whitespace', message: 'Database Name  should not contain any white spaces.' },
            { type: 'maxlength', message: 'Database Name is exceed maximum length 100.' }
        ],
        'name':[
            { type: 'required', message: 'Name is required.' },
            { type: 'maxlength', message: 'Name is exceed maximum length 100.' },
        ],
        'objectGroup':[
            { type: 'required', message: 'Object Group is required.' }
        ]
    }
    E2B={
        'filename':[
            { type: 'required', message: 'Excel file is required.' }
        ],
        'type':[
            { type: 'required', message: 'Please select type.' }
        ],
        'object':[
            { type: 'required', message: 'Please select object name.' }
        ],
        'operation':[
            { type: 'required', message: 'Please select API operation.' }
        ]
    }
    dnsConfiguration={
        'compname':[
            { type: 'required', message: 'Company Name is required.' },
        ],
        'username':[
            { type: 'required', message: 'User Name is required.' },
        ]
    }

    etlTool={
        'imageName':[
            {type:'required' , message:'Excel file required'}
        ],
        'filterquery':[
            { type: 'required', message: 'Please enter filters to apply.' },
            { type: 'maxlength', message: 'Filter is exceed maximum length 1000.' },
            { type: 'minlength', message: 'Filter must be of minimum length 10.' }
        ]
    }

  //It is for permissions screen.
  permissionsMessage = {
    'userId': [
      { type: 'required', message: 'Role Name is required.' }
    ]
  }
  migrationRunDetailsPopup = {
    'comment':[
      { type: 'maxlength', message: 'Comment is exceed maximum length 100.' },
      { type: 'minlength', message: 'Comment must be of minimum length 15.' }

    ]
  }
  addSetForMigrationPopup = {
    'setNumber':[
      { type: 'pattern', message: 'Set Number must follow 1 to 9 or 1-2,1,45 pattern! ' },
      { type: 'required', message: 'Set Number is required' },
      { type: 'min', message: 'Set Number cannot be assigned to 0.' },
    ]
  }

  startMigrationPopup ={
    'source':[
        {type: 'required', message: 'Source is required'}
    ],
    'target':[
        {type: 'required', message: 'Target is required'}
    ],
    'migrationType':[
        {type: 'required', message: 'Migration Type is required'}
    ],
    'remarks':[
        {type:'required', message:'Remarks is required'},
        { type: 'whitespace', message: 'Remarks should not contain any white spaces.' },
        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'objectGroups':[
        {type:'required', message:'Object Groups Names are required'}
    ],
    'dataLakeAndReports':[
        {type:'required', message:'Data Lake And Reports is required'}
    ],
    'dataLakeType':[
        {type:'required', message:'Data Lake Type is required'}
    ],
    'DatasetName':[
        {type:'required', message:'DatasetName is required'}
    ],
    'selectdataSet':[
        {type:'required', message:'Remarks is required'}
    ],
    'persistenceEmr': [
        {type:'required', message:'Persistence EMR is required'}
    ],
    'JSONTOCSV_Validation_Reports':[
        {type:'required', message:'JSONTOCSV Validation Reports is required'}
    ]
  }

  webScraping ={
    'dnsName':[
        {type: 'required', message: 'Domain is required'}
    ],
    'Version':[
        {type: 'required', message: 'Vault Version is required'}
    ],
    'caseName':[
        {type: 'required', message: 'Case Name is required'}
    ],
    'AERName':[
        {type: 'required', message: 'AER Name is required'}
    ],
    'documentName':[
        {type: 'required', message: 'Document Name is required'}
    ],
    'Description':[
        {type: 'required', message: 'Description is required'}
    ],
  }

  vaultObjectRelationship={
    'objectName':[
        {type: 'required', message: 'Object Name is required'}
    ],
    'parentObjectName':[
        {type: 'required', message: 'Parent Object Name is required'}
    ],
    'uniqueKeyColumn':[
        {type: 'required', message: 'Unique Key Column is required'},


        { type: 'whitespace', message: 'Unique Key should not contain any white spaces.' },

        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'columnsFromParentFile':[
        {type: 'required', message: 'Columns From Parent File is required'},

        { type: 'whitespace', message: 'Columns From Parent File should not contain any white spaces.' },

        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'parentRefColumn':[
        {type: 'required', message: 'Parent Ref Column is required'},

        { type: 'whitespace', message: 'Parent Ref Column should not contain any white spaces.' },
        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'childFileName':[
        {type: 'required', message: 'Child File Name is required'},
        { type: 'whitespace', message: 'Child File Name should not contain any white spaces.' },

        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'parentFileName':[
        {type: 'required', message: 'Parent File Name is required'},

        { type: 'whitespace', message: 'Parent File Name should not contain any white spaces.' },

        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'uniquekeycolumnd2dvalidation':[
        {type: 'required', message: 'Unique key Column D2D Validation is required'},

    ],
  }

/* Sneha
*  AWS Scret Popup Validation Messages
*  10-01-2024
*/
  awsSecretPopup = {
    'dns': [
        { type: 'required', message: 'DNS is required.' },
        { type: 'whitespace', message: 'DNS should not contain any white spaces.' }
    ],
    'secretname': [
        { type: 'required', message: 'Secret Name is required.' },
        { type: 'whitespace', message: 'Secret Name should not contain any white spaces.' },
        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'region': [
        { type: 'required', message: 'Region is required.' },
        { type: 'whitespace', message: 'Region should not contain any white spaces.' },
        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
}


retportTemplatecreationmaster={
    'reportTemplateName':[
        {type: 'required', message: 'Template Name is required'},
        { type: 'whitespace', message: 'Template Name should not contain any white spaces.' },
        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'reportTemplateDescription':[
        {type: 'required', message: 'Template Description is required'},
        { type: 'whitespace', message: 'Template Name should not contain any white spaces.' },
        { type: 'specialCharacter', message: 'Special characters are not allowed.' }
    ],
    'reportType':[
        {type: 'required', message: 'Report Type is required'}
    ],
    'vaultBinderTemplate':[
        {type: 'required', message: 'vault Binder Template is required'}
    ],
    'parentTemplateId':[
        {type: 'required', message: 'Parent Template is required'}
    ],
    'datasetId':[
        {type: 'required', message: 'Dataset is required'}
    ],
    'templateFile':[
        {type: 'required', message: 'Template File is required'}
    ],
    'templateType':[
        {type: 'required', message: 'Template Type is required'}
    ],
    'quickSightReference':[
        {type: 'required', message: 'Quick Sight Reference is required'},

        { type: 'whitespace', message: 'Quick Sight should not contain any white spaces.' }

    ],
    'documentDocTypeLabel':[
        {type: 'required', message: 'Doc Type is required'}
    ],
    'documentDocSubTypeLabel':[
        {type: 'required', message: 'Doc Sub Type is required'}
    ],
    'documentDocClassificationLabel':[
        {type: 'required', message: 'Doc Classification is required'}
    ]
  }

 /**
   * Modified By Vijay B
   * 08/07/2024
   * Validations for Filter Popup
   */
  selectFilterPopup={
    'value':[
        {type: 'required', message: 'value is required'}
    ],
  }

  /**
   * Modified By Ashwin M
   * 08/07/2024
   * code modified to add validation messages for object level deletion
   */
  caseDeletionPopup = {
    'imageName': [
        { type: 'required', message: 'Parent Ids File is required.' },
        { type: 'whitespace', message: 'Parent Ids File cannot be empty' }
    ],
    'object': [
        { type: 'required', message: 'Parent Object is required.' },
        { type: 'whitespace', message: 'Parent Object should not contain any white spaces.' }
    ],
}

}

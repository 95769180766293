import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Actionbutton } from 'src/app/shared/common/Actionbutton';
import { CustomCellRenderer } from 'src/app/shared/common/CustomCellRenderer';
import { constants } from 'src/app/shared/common/contants';
import { ErrorMessages } from 'src/app/shared/common/errormessages';
import { LocalStorageSettingsService } from 'src/app/shared/services/local-storage-settings.service';
import { ReportingServiceService } from 'src/app/shared/services/reporting-service.service';
import { VaultObjectgroupService } from '../vaultobject-popup-screen/vault-objectgroup.service';
import { FacetsButton } from 'src/app/shared/common/FacetsButton';
import {UserRoutingModule} from 'src/app/user/user-routing/user-routing.module';
import { ReportlinksserviceService } from 'src/app/user/reportlinks/reportlinksservice.service';

@Component({
  selector: 'app-report-facet',
  templateUrl: './report-facet.component.html',
  styleUrls: ['./report-facet.component.scss']
})
export class ReportFacetComponent implements OnInit {

  backgroundColorScreen = "";
  buttonColor = "";
  butttonname = "Create Facets";
  editFacetId = null;
  facetCreation:any = true;

  facets = {'facetName' : '',"facets":[], "facetId": "", "autoRefreshDataset": "", 'objectGroupId': ''};

  columnDefsForVaultObjects: any;
  defaultColDef: any;
  defaultColDefForFacets: any;
  columnDefsForObjectAttributes: any;
  columnDefsForSelectedAttributes: any;
  columnDefsForFacets:any;

  rowSelection = "";
  rowSelectionForObjectAttributes = "";
  frameworkComponents: any;

  vaultObjects: any = [];
  allAttributes: any = [];
  selectedObjectAlias: any;
  selectedObjectName: any;
  selectedAttributes: any;
  selectedAttributesList = [];
  selectedAttributesToRemoveList = [];
  facetsList = [];

  vaultObjectGridApi:any;
  vaultObjectAttributesGridApi: any;
  selectedAttributesGridApi: any;
  facetsGripApi: any;
  allAttributesSelectionApi: any;
  validations_form: UntypedFormGroup = this.fb.group({
    facetName: '',
    objectGroupId: ''
  });;
  validation_messages;
  vaultGroup:any = [];
  vaultGroupId:any;

  constructor(private service: ReportingServiceService, private spinner: NgxUiLoaderService, private localStorageSettings: LocalStorageSettingsService,private reportLinkService:ReportlinksserviceService,
    private constants: constants, private snackbar: MatSnackBar, private fb: FormBuilder, private msg: ErrorMessages, private vaultObjectService: VaultObjectgroupService) { }


  ngOnInit(): void {
    this.validation_messages = this.msg.reportConfiguration;
    this.backgroundColorScreen = this.localStorageSettings.getSettings("ColorD");
    this.buttonColor = this.localStorageSettings.getSettings("ColorA");
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.rowSelection = this.constants.rowselection;
    this.rowSelectionForObjectAttributes = "multiple";

    this.vaultObjectService.retrieveAllObjectGroupForDataset(this.localStorageSettings.getSettings("dnsName")).subscribe(res => {
      this.vaultGroup = res;
    })


    this.columnDefsForVaultObjects = [
      {
        headerName: "Objects",
        headerTooltip: "Object",
        tooltipValueGetter: params => "Object : " + params.value,
        field: "objectlabel",
        filter: "agTextColumnFilter",
        width: 400,
        headerClass: 'headerClassName'
      }
    ];

    this.columnDefsForObjectAttributes = [
      {
        headerName: "Attributes ",
        headerTooltip: "Attributes",
        tooltipValueGetter: params => "Attributes : " + params.value,
        field: "label",
        filter: "agTextColumnFilter",
        width: 400,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellStyle: { " padding-left": "5px" }
      }
    ];

    this.columnDefsForSelectedAttributes = [
      {
        headerName: "Selected Attributes",
        tooltipValueGetter: params => "Selected Attributes : " + params.value,
        field: "attributeAlias",
        filter: "agTextColumnFilter",
        width: 400,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      }
    ];

    this.columnDefsForFacets = [
      {
        headerName: 'Action', filter: false, cellRenderer: 'buttonRenderer',
        pinned: 'left',
        lockPinned: true,
        cellRendererParams: {
          refreshFacets: this.refreshFacets.bind(this),
          edit: this.edit.bind(this),
          // delete: this.deleteIcon.bind(this),
        }, width: 200
      },
      {
        headerName: "Facets ID",
        headerTooltip: "Facets ID",
        tooltipValueGetter: params => "Facets ID : " + params.value,
        field: "facetId",
        filter: "agTextColumnFilter",
        width: 150,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      },
      {
        headerName: "Facets Name",
        headerTooltip: "Facets Name",
        tooltipValueGetter: params => "Facets Name : " + params.value,
        field: "facetName",
        filter: "agTextColumnFilter",
        width: 250,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      },
      {
        headerName: "Object Group Name",
        headerTooltip: "Object Group Name",
        tooltipValueGetter: params => "Object Group Name : " + params.value,
        field: "objectGroupId",
        filter: "agTextColumnFilter",
        width: 250,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      },
      {
        headerName: "Facets Creation Status",
        headerTooltip: "Facets Creation Status",
        tooltipValueGetter: params => "Facets Creation Status : " + params.value,
        field: "creationStatus",
        filter: "agTextColumnFilter",
        width: 250,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      },
      {
        headerName: "Facets Auto refresh",
        headerTooltip: "Facets Auto refresh",
        tooltipValueGetter: params => "Facets Auto refresh : " + params.value,
        field: "autoRefresh",
        filter: "agTextColumnFilter",
        width: 250,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      },
      {
        headerName: "Facets",
        headerTooltip: "Facets",
        tooltipValueGetter: params => "Facets : " + params.value,
        field: "facet",
        filter: "agTextColumnFilter",
        width: 500,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      },
      {
        headerName: "Created By",
        headerTooltip: "Created By",
        tooltipValueGetter: params => "Created By : " + params.value,
        field: "createdBy",
        filter: "agTextColumnFilter",
        width: 200,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      },
      {
        headerName: "Created Dated And Time",
        headerTooltip: "Created Dated And Time",
        tooltipValueGetter: params => "Created Dated And Time : " + params.value,
        field: "createdDateAndTime",
        filter: "agTextColumnFilter",
        width: 200,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      },
      {
        headerName: "Tenant Id",
        headerTooltip: "Tenant Id",
        tooltipValueGetter: params => "Tenant Id : " + params.value,
        field: "tenantId",
        filter: "agTextColumnFilter",
        width: 150,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { " padding-left": "5px" },
        editable: false,
        rowDrag: false
      }
    ]

    this.frameworkComponents = {
      buttonRenderer: FacetsButton,
    }

    this.facetCreation = true;
    
  }

  valutGroupcheck(e) {
    console.log(e)
    this.vaultGroupId = e;
    this.onGridReadyForVaultObjects(this.vaultObjectGridApi)
    // console.log(e)
  }

  onGridReadyForVaultObjects(event){
    console.log("Inside grid ready");
    this.spinner.start()
    this.vaultObjectGridApi = event;
    this.vaultObjectGridApi.api.setRowData(null);
    if (this.vaultGroupId != undefined || this.vaultGroupId != null){
      this.service.retrieveObjectGroupObject(this.vaultGroupId).subscribe((res) => {
        console.log("--------------" + this.vaultGroupId);
        this.spinner.start();
  
        console.log(res);
  
        // checking if response is true or not
        if (res.error === true) {
          this.spinner.stop();
  
          this.snackbar.open(res.message, "OK", { duration: 5000 });
  
          this.vaultObjectGridApi.api.setRowData(null);
        } else {
          this.vaultObjects = res;
          this.vaultObjectGridApi.api.setRowData(this.vaultObjects);
          this.spinner.stop();
        }
      });
    } 
    // this.service.retrieveAllObjects(this.localStorageSettings.getSettings("dnsName")).subscribe(result=>{
    //   console.log(result);
    //   this.vaultObjects = result;
    //   this.vaultObjectGridApi.api.setRowData(this.vaultObjects);
    // })

    this.spinner.stop();
  }

  onCellClickedVaultObjects(event){
    this.allAttributesSelectionApi = event;

    console.log(event);
    
    this.service.retrieveTablesFieldsForDataset(event.data.id).subscribe(result=>{
      this.spinner.start();

      console.log(result);

      // checking if response is true or not
      if (result.error === true) {

        this.spinner.stop();

        this.snackbar.open(result.message, "OK", { duration: 5000 });

        this.allAttributes = [];

        this.vaultObjectAttributesGridApi.setRowData(null);

      } else {

        this.selectedObjectAlias = event.value;
        this.selectedObjectName = event.data.objectname;
        console.log(this.selectedObjectAlias, this.selectedObjectName);
        
        this.allAttributes = result[0];
        if (this.allAttributes === undefined) {
          this.vaultObjectAttributesGridApi.api.setRowData(null);
        } else {
          let tablefields = JSON.parse(this.allAttributes.attributeNameAndFields)
          /**
           * Modified By Ravi G
           * 23/01/2023
           * Made attributes name unique by adding name field after label name
           */
          tablefields.forEach(fields => {
            fields.tempfieldindex = tablefields.indexOf(fields)
            // console.log(fields);
            fields.label = fields.label + " (" + fields.name + ")";
            fields.attributeNameWithFields = this.selectedObjectAlias + " " + fields.label;
            fields.attributeNameWithFields = fields.attributeNameWithFields.replace(".", "_");
          })
          console.log(tablefields)
          this.allAttributes = tablefields;
          this.selectedAttributesList = this.updateAttributeSelection(this.selectedAttributesList);
          this.setSelectedAttributesData();
         this.spinner.stop();
       }
      }
    })
  }

  onGridReadyForObjectAttributes(event){
    this.vaultObjectAttributesGridApi = event;
    this.vaultObjectAttributesGridApi.api.setRowData(null);
  }

  onRowSelectedForAttributes(event){
    this.selectedAttributes = this.vaultObjectAttributesGridApi.api.getSelectedRows();
    console.log(this.selectedAttributes);    
  }

  onPushDataToSelectedFieldsGrid(){
    this.vaultObjectAttributesGridApi.api.deselectAll();
    for (let attributeIndex = 0; attributeIndex < this.selectedAttributes.length; attributeIndex++) {
      const attribute = this.selectedAttributes[attributeIndex];
      let tempSelectedAttribute = {"object" : "", "attribute": "", "attributeAlias":"", "label": ""};
      tempSelectedAttribute.object = this.selectedObjectName;
      tempSelectedAttribute.attribute = attribute.name;
      tempSelectedAttribute.attributeAlias =attribute.attributeNameWithFields;
      tempSelectedAttribute.label = attribute.label;
        this.selectedAttributesList.push(tempSelectedAttribute);
    }
    console.log(this.selectedAttributesList);
    this.selectedAttributesList = this.updateAttributeSelection(this.selectedAttributesList)
    this.setSelectedAttributesData();
  }

  onPushAllDataToSelectedFieldsGrid(){
    this.vaultObjectAttributesGridApi.api.deselectAll();
    for (let attributeIndex = 0; attributeIndex < this.allAttributes.length; attributeIndex++) {
      const attribute = this.allAttributes[attributeIndex];
      let tempSelectedAttribute = {"object" : "", "attribute": "", "attributeAlias":"", "label": ""};
      tempSelectedAttribute.object = this.selectedObjectName;
      tempSelectedAttribute.attribute = attribute.name;
      tempSelectedAttribute.attributeAlias =attribute.attributeNameWithFields;
      tempSelectedAttribute.label = attribute.label;
      this.selectedAttributesList.push(tempSelectedAttribute);
    }
    console.log(this.selectedAttributesList);
    this.selectedAttributesList = this.updateAttributeSelection(this.selectedAttributesList)
    this.setSelectedAttributesData();

  }

  onPopAllDataFromSelectedFieldsGrid(){
    if(this.allAttributesSelectionApi != undefined){
      this.onCellClickedVaultObjects(this.allAttributesSelectionApi);
    }
    this.selectedAttributesList = [];
    this.selectedAttributesList = this.updateAttributeSelection(this.selectedAttributesList)
    this.setSelectedAttributesData();
  }

  onPopDataToSelectedFieldsGrid(){
    console.log(this.selectedAttributesToRemoveList);
    console.log(this.selectedAttributesList);
    for (let attributeRemoveIndex = 0; attributeRemoveIndex < this.selectedAttributesToRemoveList.length; attributeRemoveIndex++) {
      const attributeRemove = this.selectedAttributesToRemoveList[attributeRemoveIndex];
      for (let selectedAttributesIndex = 0; selectedAttributesIndex < this.selectedAttributesList.length; selectedAttributesIndex++) {
        const attributeSelected = this.selectedAttributesList[selectedAttributesIndex];
        /**
        * Modified by Ashwin M
        * 27/06/2023
        * modified to refer the attributeAlias name to remove selected fields from list
        */
        if (attributeSelected.attributeAlias === attributeRemove.attributeAlias){
          this.selectedAttributesList.splice(selectedAttributesIndex, 1);
        }
      }
    }
    console.log(this.selectedAttributesToRemoveList);
    console.log(this.selectedAttributesList);
    /**
     * Modified by Ashwin M
     * 27/06/2023
     * modified to refresh attribute grid after removing attributes fom selected grid
     */
    if(this.allAttributesSelectionApi != undefined){
      this.onCellClickedVaultObjects(this.allAttributesSelectionApi);
    }
    this.setSelectedAttributesData();
  }

  onGridReadyForSelectedAttributeFields(event){
    this.selectedAttributesGridApi = event;
    this.selectedAttributesGridApi.api.setRowData(null);
  }

  onRowSelectedToRemove(event){
    this.selectedAttributesToRemoveList = this.selectedAttributesGridApi.api.getSelectedRows();
  }

  createFacets(){
    if (this.selectedAttributesList.length == 0){
      this.snackbar.open('Select atleast one Attribute', 'OK', {duration:5000});
      return;
    }
    this.spinner.start();
    this.facets.facets = this.selectedAttributesList;
    this.facets.facetId = this.editFacetId;
    this.facets.autoRefreshDataset = this.facetCreation;
    console.log(this.facets);
    this.service.createFacets(this.facets).subscribe(result=>{
        this.snackbar.open(result.statusMessage, "OK", { duration: 5000 });
    });
    this.onGridReadyForFacets(this.facetsGripApi);
    /**
     * Modified by Ashwin M
     * 27/06/2023
     * modified to refresh grid after processing request
     */
    this.refreshRun();
    this.cancel();
    this.spinner.stop();
  }

  onGridReadyForFacets(event){
    this.spinner.start();
    this.facetsGripApi = event;
    this.facetsGripApi.api.setRowData(null);
    console.log(this.localStorageSettings.getSettings("dnsName"));
    this.service.retrieveAllFacets(this.localStorageSettings.getSettings("dnsName")).subscribe(result=>{
      console.log(result);
      
      this.facetsList = result;
      this.facetsGripApi.api.setRowData(this.facetsList);
    })
    this.spinner.stop();
  }

  refreshRun() {
    this.spinner.start();
    this.onGridReadyForFacets(this.facetsGripApi);
    this.spinner.stop();
  }

  onBtExport() {
    this.facetsGripApi.api.exportDataAsCsv();
  }

  updateAttributeSelection(attributesList){
    this.spinner.start();
    for (let attributeIndex = 0; attributeIndex < attributesList.length; attributeIndex++) {
      const attribute = attributesList[attributeIndex];
      let objectName = "";
      this.vaultObjects.filter(object=>{
        if(object.objectname === attribute.object){
          objectName = object.object;
        }
      })
      for (let allAttributeIndex = 0; allAttributeIndex < this.allAttributes.length; allAttributeIndex++) {
        const allAttribute = this.allAttributes[allAttributeIndex];      
        if (attribute.attributeAlias === allAttribute.attributeNameWithFields){
          this.allAttributes.splice(allAttributeIndex, 1);
        }
      }
    }
    this.selectedAttributes = attributesList;
    this.vaultObjectAttributesGridApi.api.setRowData(null);
    this.vaultObjectAttributesGridApi.api.setRowData(this.allAttributes);
    this.spinner.stop();
    return this.selectedAttributesList;
  }

  setSelectedAttributesData(){
    this.selectedAttributesGridApi.api.setRowData(this.selectedAttributesList);
  }

  edit(event){
    this.cancel();
    this.spinner.start();
    this.editFacetId = event.rowData.facetId;
    console.log(event.rowData);
    if(event.rowData.autoRefresh == 'Yes'){
      this.facetCreation = true;
    } else {
      this.facetCreation = false;
    }
    this.validations_form.controls['facetName'].setValue(event.rowData.facetName);
    let groupId = '';
    this.vaultGroup.forEach((group:any)=>{
      if(group.objectGroupName == event.rowData.objectGroupId){
        groupId = group.objectGroupId;
      }
    })
    this.valutGroupcheck(groupId);
    this.validations_form.controls['objectGroupId'].setValue(groupId);
    this.butttonname = "Update Facets";
    let facetData = JSON.parse(event.rowData.facet);
    for (let facetIndex = 0; facetIndex < facetData.length; facetIndex++) {
      const element = facetData[facetIndex];
      let dataJson = {
        "attributeAlias": element.attributeAlias,
        "attributeNameWithFields":element.attributeAlias,
        "attribute": element.attribute,
        "label": element.label,
        "object": element.object}
      console.log(element);
      this.selectedAttributesList.push(dataJson);
    }
    console.log(this.selectedAttributesList);
    this.onGridReadyForSelectedAttributeFields(this.selectedAttributesGridApi);
    this.setSelectedAttributesData();
    this.spinner.stop();
  }

  cancel(){
    this.spinner.start();
    this.facetCreation = true;
    /**
     * Modified by Ashwin M
     * 27/06/2023
     * modified to rename button name after processing request
     */
    this.butttonname = "Create Facets";
    this.selectedAttributesList = [];
    this.vaultGroupId = null;
    this.editFacetId = null;
    this.onGridReadyForVaultObjects(this.vaultObjectGridApi);
    this.onGridReadyForSelectedAttributeFields(this.selectedAttributesGridApi);
    this.onGridReadyForObjectAttributes(this.vaultObjectAttributesGridApi);
    this.validations_form.reset();
    this.spinner.stop();
  }

  refreshFacets(selectedFacet:any) {
    console.log(selectedFacet);
    if (selectedFacet.rowData.status === "No") {
      this.snackbar.open("Cannot refresh deleted Facet", "OK", { duration: 5000 });
      return;
    }

    if(selectedFacet.rowData.creationStatus == "In Progress"){
      this.snackbar.open("Facet Generation is \"In Progress\" try again later", "OK", { duration: 5000 });
      return;
    }

    if (confirm("Are you sure you want to refresh Facets?")) {
      // console.log(selectedFacet.rowData);
      
      this.spinner.start();
      this.service.refreshFacets(selectedFacet.rowData.facetId).subscribe(result => {
        if (result.error) {
          this.snackbar.open(result.statusMessage + result.data, "OK", { duration: 5000 });
        } else {
          this.snackbar.open(result.statusMessage, "OK", { duration: 5000 });
        }
        this.refreshRun();
        this.spinner.stop();
      });
    }
  }

}

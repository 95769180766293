import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from "@angular/forms";
import { CommonService } from "src/app/shared/services/common.service";
import { TrnxCommonService } from "src/app/shared/services/trnx-common.service";
import * as moment from "moment";
import { FormFieldsTrimAndClean } from "src/app/shared/common/trim-clean-Data";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ImageviewPopupComponent } from 'src/app/user/imageview-popup/imageview-popup.component';
import { ScreenCreatorTransactionService } from "src/app/shared/services/screen-creator-transaction.service";
import { validationmethod } from "src/app/shared/common/validationmethod";
import { CommonTs } from './CommonTs';

@Injectable({
    providedIn: 'root'
})

export class  TrnxCommonTs {
    childObject: any = []

    constructor(
        private trnxService: TrnxCommonService,
        private service: CommonService,
        private trimCleanData: FormFieldsTrimAndClean,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private trnxScreenCreatorService: ScreenCreatorTransactionService,
        private validation: validationmethod,
        private commonTs:CommonTs
    ) { }

    async ngModelTrnxObjectCreation(tableName) {

        console.log(tableName);

        let trnxDynamicData = [];

        let ngTrnxModelObject = [];

        let trnxColSpanValue = 0;

        let ngModelTrnxObjectDivisionLocal = [];

        let ngModelTrnxObjectDivision = [];

        let trnxBootstrapColumn = 0;

        let trnxTableID = "";

        let dropdownindex = 0;

        let trnxScreenId = "";

        let trnxRetrieveTableData: any = [];

        let trnxRadio: any = []

        trnxRetrieveTableData = await this.trnxScreenCreatorService.retrieveTrnxScreenByTableNameService(tableName);

        console.log(trnxRetrieveTableData);

        let screenTypeTOrM = trnxRetrieveTableData.screenType;

        console.log(screenTypeTOrM);

        console.log(trnxRetrieveTableData.tableDetails);

        trnxScreenId = trnxRetrieveTableData.screenId;

        console.log(trnxScreenId)

        trnxDynamicData = JSON.parse(trnxRetrieveTableData.tableDetails).tableData;

        console.log(trnxDynamicData);

        trnxColSpanValue = JSON.parse(trnxRetrieveTableData.tableDetails).colSpan;

        console.log(trnxColSpanValue);

        trnxTableID = JSON.parse(trnxRetrieveTableData.tableDetails).tableID;

        console.log(trnxTableID);

        console.log(trnxDynamicData);

        trnxDynamicData.forEach(dynamicDataObject => {

            dynamicDataObject["imageShow"] = false;

            dynamicDataObject["imageShow1"] = false;

            dynamicDataObject["fileShow"] = false;

            dynamicDataObject["imageNameDisplay"] = null;

            dynamicDataObject["startDateValue"] = moment(null);

            dynamicDataObject["endDateValue"] = moment(null);

            dynamicDataObject["binaryFormat"] = false;

            dynamicDataObject["downloadIconShow"] = false;

            dynamicDataObject["startDateTimeValue"] = moment(null);

            dynamicDataObject["endDateTimeValue"] = moment(null);

            if (dynamicDataObject.fieldType == "Linked Fields" || dynamicDataObject.fieldType == "Select List - Static") {
                dynamicDataObject["dropDownIndex"] = dropdownindex;
                dropdownindex++;
            }

        });

        console.log(trnxDynamicData);
        console.log(trnxDynamicData)

        //creating ngTrnxModelObject
        let i = 0;

        trnxDynamicData.forEach(e => {

            let jsonObject = {};

            jsonObject["keyName"] = e.fieldName;

            jsonObject["keyValue"] = "";

            jsonObject["formName"] = e.formName;

            jsonObject["agGridColumnName"] = e.agGridColumnName;

            jsonObject["maxLength"] = e.maxLength;

            jsonObject["fieldName"] = e.fieldName;

            jsonObject["validationMessages"] = [];

            if (e.fieldType == "valueText" || e.fieldType == "Attachment File" || e.fieldType == "valuePassword" || e.fieldType == "valueEmail" || e.fieldType == "valueTextArea" || e.fieldType == "Email" || e.fieldType == "Password" || e.fieldType == "Free Text Single line" || e.fieldType == "Free Text Multi line" || e.fieldType == "JSON") {

                jsonObject["validationMessages"] = [{ type: 'maxlength', message: e.formName + " is exceed maximum length " + e.maxLength + "." }];

                jsonObject["validationMessages"].push({ type: 'minlength', message: e.formName + " is less than minimum length " + e.minLength + "." });

            }

            if (e.columnType == "Color") {

                jsonObject["validationMessages"] = [{ type: 'maxlength', message: e.displayFormColumnName + " is exceed maximum length " + e.maximumLength + "." }];

            }

            if (e.fieldType == "valueText" || e.fieldType == "valueTextArea" || e.fieldType == "Free Text Single line" || e.fieldType == "Free Text Multi line" || e.fieldType == "Attachment File" || e.fieldType == "JSON") {

                if (e.mandatory == true) {

                    console.log(e.mandatory);

                    jsonObject["validationMessages"].push({ type: 'whitespace', message: e.formName + " should not contain any white spaces." });

                }
            }

            if (e.columnType == "Color") {

                if (e.mandatory == true) {

                    console.log(e.mandatory);

                    jsonObject["validationMessages"].push({ type: 'whitespace', message: e.displayFormColumnName + " should not contain any white spaces." });

                }

            }

            if (e.fieldType == "valueInteger" || e.fieldType == "Integer Number") {

                jsonObject["validationMessages"].push({ type: 'max', message: e.formName + " is exceed maximum value." });

                jsonObject["validationMessages"].push({ type: 'min', message: e.formName + " is less than minimum value." });

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.formName + " allows only integers." });

            }

            if (e.fieldType == "valueDecimal" || e.fieldType == "Decimal Number") {

                jsonObject["validationMessages"].push({ type: 'max', message: e.formName + " is exceed maximum value." });

                jsonObject["validationMessages"].push({ type: 'min', message: e.formName + " is less than minimum value." });

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.formName + " allows only numbers." });

            }

            if (e.fieldType == "valuePassword" || e.fieldType == "Password") {

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.formName + " must contain at least one uppercase, one lowercase, one special character and one number." });

            }

            if (e.fieldType == "valueEmail" || e.fieldType == "Email") {

                jsonObject["validationMessages"].push({ type: 'email', message: "Enter valid " + e.formName + "." });

            }

            jsonObject["validationMessages"].push({ type: 'required', message: e.formName + " is required." });

            jsonObject["showForm"] = e.showForm;

            jsonObject["fieldType"] = e.fieldType;

            jsonObject["dropDownIndex"] = e.dropDownIndex;

            jsonObject["dropDownChildFieldName"] = e.dropDownChildFieldName;

            jsonObject["columnNameDropDown"] = e.columnNameDropDown;

            //jsonObject["agGridDecode"] = e.agGridDecode;

            jsonObject["combinationColumn"] = e.combinationColumn;

            // jsonObject["imageName"] = e.fieldName;
            if (e.fieldType == "Attachment File") {
                jsonObject["readOnlyValue"] = true;
                console.log(jsonObject["readOnlyValue"]);

            }
            else {

                jsonObject["readOnlyValue"] = e.readOnlyValue;

            }
            if (e.fieldType == "Attachment") {

                console.log(e);

                jsonObject["imageName"] = e.filePath;

            }

            jsonObject["imageShow"] = e.imageShow;

            jsonObject["imageShow1"] = e.imageShow1;

            jsonObject["fileShow"] = e.fileShow;

            jsonObject["imageNameDisplay"] = e.imageNameDisplay;

            jsonObject["childDropDownKey"] = e.childDropDownKey;

            jsonObject["tableName"] = e.tableName;

            jsonObject["endDateName"] = e.endDateName;

            jsonObject["mandatory"] = e.mandatory;

            jsonObject["startDateName"] = e.startDateName;

            jsonObject["binaryFormat"] = e.binaryFormat;

            jsonObject["startDateTimeName"] = e.startDateTimeName;

            jsonObject["endDateTimeName"] = e.endDateTimeName;

            jsonObject["maximumValue"] = e.maximumValue;

            jsonObject["maximumDecimalValue"] = e.maximumDecimalValue;

            jsonObject["maximumDate"] = e.maximumDate;

            jsonObject["maximumDateTime"] = e.maximumDateTime;

            if (e.fieldType == "Radio") {
                let tempRadio = [];

                if (e.radioValue1 != "" || e.radioValue1 != "undefined") {
                    tempRadio.push(e.radioValue1)
                    if (e.radioValue2 != "" || e.radioValue2 != "undefined") {
                        tempRadio.push(e.radioValue2)
                        if (e.radioValue3 != "" || e.radioValue3 != "undefined") {
                            tempRadio.push(e.radioValue3)
                        }
                    } else if (e.radioValue3 != "" || e.radioValue3 != "undefined") {
                        tempRadio.push(e.radioValue3)
                    }
                } else if (e.radioValue2 != "" || e.radioValue2 != "undefined") {
                    tempRadio.push(e.radioValue2)
                    if (e.radioValue3 != "" || e.radioValue3 != "undefined") {
                        tempRadio.push(e.radioValue3)
                    }
                } else if (e.radioValue3 != "" || e.radioValue3 != "undefined") {
                    tempRadio.push(e.radioValue3)

                }
                console.log(tempRadio.length)
                for (let i = 0; i < tempRadio.length; i++) {
                    console.log(tempRadio[i])
                    if (tempRadio[i] == undefined) {
                        console.log(tempRadio[i] + "  sdfsdfgds")
                        tempRadio.splice(i, 1);
                    }
                }
                console.log(tempRadio)
                jsonObject["radio"] = tempRadio
                console.log(jsonObject["radio"])
            }

            if (trnxColSpanValue == 1) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-10";

                trnxBootstrapColumn = 1;

            }

            else if (trnxColSpanValue == 2) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-4";

                trnxBootstrapColumn = 2;

            }

            else if (trnxColSpanValue == 3) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-4";

            }

            else {

                jsonObject["bootstrapColumnGrid"] = "col-lg-3";

            }

            if (e.fieldType == "DatePicker" || e.fieldType == "Start & End Date") {

                jsonObject["endDateValue"] = e.maxValuee;

                console.log(jsonObject["endDateValue"]);

            }

            if (e.fieldType == "DateTimePicker" || e.fieldType == "Start & End Date Time") {

                jsonObject["endDateTimeValue"] = e.maxValuee;

                console.log(jsonObject["endDateTimeValue"]);

            }

            if (e.fieldType == "DatePicker" || e.fieldType == "Start & End Date") {

                jsonObject["endDateValueMin"] = e.minValue;

                console.log(jsonObject["endDateValueMin"]);

            }

            if (e.fieldType == "DateTimePicker" || e.fieldType == "Start & End Date Time") {

                jsonObject["endDateTimeValueMin"] = e.minValue;

                console.log(jsonObject["endDateTimeValueMin"]);

            }

            console.log(typeof e.imageShow);

            ngTrnxModelObject.push(jsonObject);

        });

        //Based on the colspan value storing ngTrnxModelObject jsons into ngModelTrnxObjectDivision
        let j = 0;

        console.log(ngTrnxModelObject);

        for (let i = 0; i < ngTrnxModelObject.length; i++) {

            console.log(ngTrnxModelObject);

            if (ngTrnxModelObject[i].showForm) {

                if (j <= trnxColSpanValue) {

                    ngModelTrnxObjectDivisionLocal.push(ngTrnxModelObject[i]);

                    console.log(ngModelTrnxObjectDivisionLocal);

                    j = j + 1;

                }

                if (j == trnxColSpanValue) {

                    ngModelTrnxObjectDivision.push(ngModelTrnxObjectDivisionLocal);

                    console.log(ngModelTrnxObjectDivision);

                    ngModelTrnxObjectDivisionLocal = [];

                    j = 0;

                }

            }

            if ((i + 1) == (ngTrnxModelObject.length)) {

                ngModelTrnxObjectDivision.push(ngModelTrnxObjectDivisionLocal);

                console.log(ngModelTrnxObjectDivision);

                ngModelTrnxObjectDivisionLocal = [];

                j = 0;

            }

        }

        console.log(trnxDynamicData);

        console.log(ngTrnxModelObject);

        console.log(ngModelTrnxObjectDivision);

        console.log(trnxBootstrapColumn);

        console.log(trnxTableID);

        return await [trnxDynamicData, ngTrnxModelObject, ngModelTrnxObjectDivision, trnxBootstrapColumn, trnxTableID, true, trnxScreenId, screenTypeTOrM];

    }

    async retrieveChildTableData(parentTableId) {

        let trnxChildDynamicData = [];

        let ngTrnxChildModelObject = [];

        let trnxChildColSpanValue = 0;

        let dropdownindex = 0;

        let ngModelTrnxChildObjectDivisionLocal = [];

        let ngModelTrnxChildObjectDivision = [];

        let trnxChildBootstrapColumn = 0;

        let trnxChildTableID = "";

        let trnxChildScreenId = "";

        let trnxChildRetrieveTableData: any = [];

        let trnxChildRadio: any = []

        trnxChildRetrieveTableData = await this.trnxScreenCreatorService.retrieveTrnxChildScreenByTableNameService(parentTableId);

        console.log(trnxChildRetrieveTableData);
        console.log(trnxChildRetrieveTableData.tableDetails);

        trnxChildScreenId = trnxChildRetrieveTableData.screenId;

        console.log(trnxChildScreenId)

        trnxChildDynamicData = JSON.parse(trnxChildRetrieveTableData.tableDetails).tableData;

        console.log(trnxChildDynamicData);

        trnxChildColSpanValue = JSON.parse(trnxChildRetrieveTableData.tableDetails).colSpan;

        console.log(trnxChildColSpanValue);

        trnxChildTableID = JSON.parse(trnxChildRetrieveTableData.tableDetails).tableID;

        console.log(trnxChildTableID);

        console.log(trnxChildDynamicData);

        trnxChildDynamicData.forEach(dynamicDataObject => {

            dynamicDataObject["imageShow"] = false;

            dynamicDataObject["imageShow1"] = false;

            dynamicDataObject["fileShow"] = false;

            dynamicDataObject["imageNameDisplay"] = null;

            dynamicDataObject["startDateValue"] = moment(null);

            dynamicDataObject["endDateValue"] = moment(null);

            dynamicDataObject["binaryFormat"] = false;

            dynamicDataObject["downloadIconShow"] = false;

            dynamicDataObject["startDateTimeValue"] = moment(null);

            dynamicDataObject["endDateTimeValue"] = moment(null);

            if (dynamicDataObject.fieldType == "Linked Fields" || dynamicDataObject.fieldType == "Select List - Static") {
                dynamicDataObject["dropDownIndex"] = dropdownindex;
                dropdownindex++;
            }

        });

        console.log(trnxChildDynamicData);
        console.log(trnxChildDynamicData)

        //creating ngTrnxModelObject
        let i = 0;

        trnxChildDynamicData.forEach(e => {

            let jsonObject = {};

            jsonObject["keyName"] = e.fieldName;

            jsonObject["keyValue"] = "";

            jsonObject["formName"] = e.formName;

            jsonObject["agGridColumnName"] = e.agGridColumnName;

            jsonObject["maxLength"] = e.maxLength;

            jsonObject["fieldName"] = e.fieldName;

            jsonObject["validationMessages"] = [];

            if (e.fieldType == "valueText" || e.fieldType == "Attachment File" || e.fieldType == "valuePassword" || e.fieldType == "valueEmail" || e.fieldType == "valueTextArea" || e.fieldType == "Email" || e.fieldType == "Password" || e.fieldType == "Free Text Single line" || e.fieldType == "Free Text Multi line" || e.fieldType == "JSON") {

                jsonObject["validationMessages"] = [{ type: 'maxlength', message: e.formName + " is exceed maximum length " + e.maxLength + "." }];

                jsonObject["validationMessages"].push({ type: 'minlength', message: e.formName + " is less than minimum length " + e.minLength + "." });

            }

            if (e.fieldType == "valueText" || e.fieldType == "valueTextArea" || e.fieldType == "Free Text Single line" || e.fieldType == "Free Text Multi line" || e.fieldType == "Attachment File" || e.fieldType == "JSON") {

                if (e.mandatory == true) {

                    console.log(e.mandatory);

                    jsonObject["validationMessages"].push({ type: 'whitespace', message: e.formName + " should not contain any white spaces." });

                }


            }


            if (e.fieldType == "valueInteger" || e.fieldType == "Integer Number") {

                jsonObject["validationMessages"].push({ type: 'max', message: e.formName + " is exceed maximum value." });

                jsonObject["validationMessages"].push({ type: 'min', message: e.formName + " is less than minimum length." });

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.formName + " allows only integers." });

            }

            if (e.fieldType == "valueDecimal" || e.fieldType == "Decimal Number") {

                jsonObject["validationMessages"].push({ type: 'max', message: e.formName + " is exceed maximum value." });

                jsonObject["validationMessages"].push({ type: 'min', message: e.formName + " is less than minimum length." });

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.formName + " allows only numbers." });

            }

            if (e.fieldType == "valuePassword" || e.fieldType == "Password") {

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.formName + " must contain at least one uppercase, one lowercase, one special character and one number." });

            }

            if (e.fieldType == "valueEmail" || e.fieldType == "Email") {

                jsonObject["validationMessages"].push({ type: 'email', message: "Enter valid " + e.formName + "." });

            }

            jsonObject["validationMessages"].push({ type: 'required', message: e.formName + " is required." });

            jsonObject["showForm"] = e.showForm;

            jsonObject["fieldType"] = e.fieldType;

            jsonObject["dropDownIndex"] = e.dropDownIndex;

            jsonObject["dropDownChildFieldName"] = e.dropDownChildFieldName;

            jsonObject["columnNameDropDown"] = e.columnNameDropDown;

            //jsonObject["agGridDecode"] = e.agGridDecode;

            jsonObject["combinationColumn"] = e.combinationColumn;

            if (e.fieldType == "Attachment File") {
                jsonObject["readOnlyValue"] = true;
                console.log(jsonObject["readOnlyValue"]);

            }
            else {

                jsonObject["readOnlyValue"] = e.readOnlyValue;

            }

            if (e.fieldType == "Attachment") {
                jsonObject["imageName"] = e.fieldName + "path";

            }

            jsonObject["imageShow"] = e.imageShow;

            jsonObject["imageShow1"] = e.imageShow1;

            jsonObject["fileShow"] = e.fileShow;

            jsonObject["imageNameDisplay"] = e.imageNameDisplay;

            jsonObject["childDropDownKey"] = e.childDropDownKey;

            jsonObject["tableName"] = e.tableName;

            jsonObject["endDateName"] = e.endDateName;

            jsonObject["mandatory"] = e.mandatory;

            jsonObject["startDateName"] = e.startDateName;

            jsonObject["binaryFormat"] = e.binaryFormat;

            jsonObject["startDateTimeName"] = e.startDateTimeName;

            jsonObject["endDateTimeName"] = e.endDateTimeName;

            jsonObject["maximumValue"] = e.maximumValue;

            jsonObject["maximumDecimalValue"] = e.maximumDecimalValue;

            jsonObject["maximumDate"] = e.maximumDate;

            jsonObject["maximumDateTime"] = e.maximumDateTime;

            if (e.fieldType == "Radio") {
                let tempRadio = [];

                if (e.radioValue1 != "" || e.radioValue1 != "undefined") {
                    console.log(e.radioValue1)
                    tempRadio.push(e.radioValue1)
                    if (e.radioValue2 != "" || e.radioValue2 != "undefined") {
                        console.log(e.radioValue2)
                        tempRadio.push(e.radioValue2)
                        if (e.radioValue3 != "" || e.radioValue3 != "undefined") {
                            console.log(e.radioValue3)
                            tempRadio.push(e.radioValue3)
                        }
                    } else if (e.radioValue3 != "" || e.radioValue3 != "undefined") {
                        console.log(e.radioValue3)
                        tempRadio.push(e.radioValue3)
                    }
                } else if (e.radioValue2 != "" || e.radioValue2 != "undefined") {
                    console.log(e.radioValue2)
                    tempRadio.push(e.radioValue2)
                    if (e.radioValue3 != "" || e.radioValue3 != "undefined") {
                        console.log(e.radioValue3)
                        tempRadio.push(e.radioValue3)
                    }
                } else if (e.radioValue3 != "" || e.radioValue3 != "undefined") {
                    console.log(e.radioValue3)
                    tempRadio.push(e.radioValue3)

                }
                console.log(tempRadio.length)
                for (let i = 0; i < tempRadio.length; i++) {
                    console.log(tempRadio[i])
                    if (tempRadio[i] == undefined) {
                        console.log(tempRadio[i] + "  sdfsdfgds")
                        tempRadio.splice(i, 1);
                    }
                }
                console.log(tempRadio)
                jsonObject["radio"] = tempRadio
                console.log(jsonObject["radio"])
            }

            if (trnxChildColSpanValue == 1) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-10";

                trnxChildBootstrapColumn = 1;

            }

            else if (trnxChildColSpanValue == 2) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-4";

                trnxChildBootstrapColumn = 2;

            }

            else if (trnxChildColSpanValue == 3) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-4";

            }

            else {

                jsonObject["bootstrapColumnGrid"] = "col-lg-3";

            }

            if (e.fieldType == "DatePicker" || e.fieldType == "Start & End Date") {

                jsonObject["endDateValue"] = e.maxValuee;

                console.log(jsonObject["endDateValue"]);

            }

            if (e.fieldType == "DatePicker" || e.fieldType == "Start & End Date") {

                jsonObject["endDateValueMin"] = e.minValue;

                console.log(jsonObject["endDateValueMin"]);

            }

            if (e.fieldType == "DateTimePicker" || e.fieldType == "Start & End Date Time") {

                jsonObject["endDateTimeValue"] = e.maxValuee;

                console.log(jsonObject["endDateTimeValue"]);

            }

            if (e.fieldType == "DateTimePicker" || e.fieldType == "Start & End Date Time") {

                jsonObject["endDateTimeValueMin"] = e.minValue;

                console.log(jsonObject["endDateTimeValueMin"]);

            }

            console.log(typeof e.imageShow);

            ngTrnxChildModelObject.push(jsonObject);

        });

        //Based on the colspan value storing ngTrnxChildModelObject jsons into ngModelTrnxObjectDivision
        let j = 0;

        console.log(ngTrnxChildModelObject);

        for (let i = 0; i < ngTrnxChildModelObject.length; i++) {

            console.log(ngTrnxChildModelObject);

            if (ngTrnxChildModelObject[i].showForm) {

                if (j <= trnxChildColSpanValue) {

                    ngModelTrnxChildObjectDivisionLocal.push(ngTrnxChildModelObject[i]);

                    console.log(ngModelTrnxChildObjectDivisionLocal);

                    j = j + 1;

                }

                if (j == trnxChildColSpanValue) {

                    ngModelTrnxChildObjectDivision.push(ngModelTrnxChildObjectDivisionLocal);

                    console.log(ngModelTrnxChildObjectDivision);

                    ngModelTrnxChildObjectDivisionLocal = [];

                    j = 0;

                }

            }

            if ((i + 1) == (ngTrnxChildModelObject.length)) {

                ngModelTrnxChildObjectDivision.push(ngModelTrnxChildObjectDivisionLocal);

                console.log(ngModelTrnxChildObjectDivision);

                ngModelTrnxChildObjectDivisionLocal = [];

                j = 0;

            }

        }

        console.log(trnxChildDynamicData);

        console.log(ngTrnxChildModelObject);

        console.log(ngModelTrnxChildObjectDivision);

        console.log(trnxChildBootstrapColumn);

        console.log(trnxChildTableID);

        return await [trnxChildDynamicData, ngTrnxChildModelObject, ngModelTrnxChildObjectDivision, trnxChildBootstrapColumn, trnxChildTableID, true, trnxChildScreenId];
    }

    //Form Validations
    formValidations(trnxDynamicData) {

        let numberPattern = '^[0-9]+$';

        // let PASSWORDPATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#\$%\^&\*()_+=[\]{}|\\,.?:"';<>/ -])(?=.{8,})(?=[a-zA-Z0-9~!@#$%^&*()_+=[\]{}|\\,.?:"';<>/ -]*$)(?!.*[ ])/;

        // let PASSWORDPATTERN2 = /^\S*$/;

        let formGroupJsonObject = {};

        trnxDynamicData.forEach(e => {

            console.log(e.fieldType);

            if (e.fieldType == "valueDate" || e.fieldType == "valueStartDate" || e.fieldType == "valueEndDate" || e.fieldType == "valueDropDown" || e.fieldType == "Linked Fields" || e.fieldType == "Select List - Static" || e.fieldType == "valueParentDropDown" || e.fieldType == "valueDateTime" || e.fieldType == "valueStartDateTime" || e.fieldType == "valueEndDateTime" || e.fieldType == "DatePicker" || e.fieldType == "DateTimePicker" || e.fieldType == "Start & End Date Time" || e.fieldType == "Start & End Date" || e.fieldType == "Attachment File") {

                console.log(e.fieldType);

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("");

                }

            }

            if (e.fieldType == "valueText" || e.fieldType == "Free Text Single line" || e.fieldType == "Attachment File") {

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.required, this.validation.noWhitespaceValidator]);

                }

                else {


                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength)]);


                }

            }

            if (e.fieldType == "Color") {

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.required, this.validation.noWhitespaceValidator]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength)]);

                }

            }

            if (e.fieldType == "Attachment") {
                formGroupJsonObject[e.fieldName] = new UntypedFormControl("");
            }

            if (e.fieldType == "valueRadio" || e.fieldType == "Radio") {

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("");

                }

            }

            if (e.fieldType == "valueFilePath") {

                formGroupJsonObject[e.fieldName] = new UntypedFormControl("");

            }

            if (e.fieldType == "valuePassword" || e.fieldType == "Password") {

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.required]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength)]);

                }

            }

            if (e.fieldType == "valueEmail" || e.fieldType == "Email") {

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.required, Validators.email]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.email]);

                }

            }

            if (e.fieldType == "valueInteger" || e.fieldType == "Integer Number") {

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$'), Validators.required]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$')]);

                }

            }

            if (e.fieldType == "valueDecimal" || e.fieldType == "Decimal Number") {

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern(/^[0-9]+(?:\.[0-9]+)?$/), Validators.required]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern(/^[0-9]+(?:\.[0-9]+)?$/)]);

                }

            }

            if (e.fieldType == "valueCheckbox" || e.fieldType == "Checkbox") {

                console.log(e.fieldType);

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("");

                }

            }

            if (e.fieldType == "valueTextArea" || e.fieldType == "Free Text Multi line" || e.fieldType == "Attachment File" || e.fieldType == "JSON") {

                if (e.mandatory == true) {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.required, this.validation.noWhitespaceValidator]);

                }

                else {

                    formGroupJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength)]);

                }

            }

        });

        let formValid = new UntypedFormGroup(formGroupJsonObject);

        console.log(formValid);

        return formValid;

    }

    childFormValidations(trnxChildData) {
        let numberPattern = '^[0-9]+$';

        // let PASSWORDPATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#\$%\^&\*()_+=[\]{}|\\,.?:"';<>/ -])(?=.{8,})(?=[a-zA-Z0-9~!@#$%^&*()_+=[\]{}|\\,.?:"';<>/ -]*$)(?!.*[ ])/;

        // let PASSWORDPATTERN2 = /^\S*$/;

        let formGroupChildJsonObject = {};

        trnxChildData.forEach(e => {

            if (e.fieldType == "valueDate" || e.fieldType == "valueStartDate" || e.fieldType == "valueEndDate" || e.fieldType == "valueDropDown" || e.fieldType == "Linked Fields" || e.fieldType == "Select List - Static" || e.fieldType == "valueParentDropDown" || e.fieldType == "valueDateTime" || e.fieldType == "valueStartDateTime" || e.fieldType == "valueEndDateTime" || e.fieldType == "DatePicker" || e.fieldType == "DateTimePicker" || e.fieldType == "Start & End Date Time" || e.fieldType == "Start & End Date" || e.fieldType == "Attachment File") {

                console.log(e.fieldType);

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("");

                }

            }

            if (e.fieldType == "valueText" || e.fieldType == "Free Text Single line" || e.fieldType == "Attachment File") {

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.required, this.validation.noWhitespaceValidator]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength)]);


                }

            }

            if (e.fieldType == "Attachment") {
                formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("");
            }

            if (e.fieldType == "valueRadio" || e.fieldType == "Radio") {

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("");

                }

            }

            if (e.fieldType == "valueFilePath") {

                formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("");

            }

            if (e.fieldType == "valuePassword" || e.fieldType == "Password") {

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.required]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minValue)]);

                }

            }

            if (e.fieldType == "valueEmail" || e.fieldType == "Email") {

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.required, Validators.email]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.email]);

                }

            }

            if (e.fieldType == "valueInteger" || e.fieldType == "Integer Number") {

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minLength), Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$'), Validators.required]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$')]);

                }

            }

            if (e.fieldType == "valueDecimal" || e.fieldType == "Decimal Number") {

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern(/^[0-9]+(?:\.[0-9]+)?$/), Validators.required]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern(/^[0-9]+(?:\.[0-9]+)?$/)]);

                }

            }

            if (e.fieldType == "valueCheckbox" || e.fieldType == "Checkbox") {

                console.log(e.fieldType);

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("");

                }

            }

            if (e.fieldType == "valueTextArea" || e.fieldType == "Free Text Multi line" || e.fieldType == "Attachment File" || e.fieldType == "JSON") {

                if (e.mandatory == true) {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength), Validators.minLength(e.minLength), Validators.required, this.validation.noWhitespaceValidator]);

                }

                else {

                    formGroupChildJsonObject[e.fieldName] = new UntypedFormControl("", [Validators.maxLength(e.maxLength)]);

                }

            }

        });

        let formChildValid = new UntypedFormGroup(formGroupChildJsonObject);

        console.log(formChildValid);

        return formChildValid;
    }

    async retrieveTrnxDropDown(tableName, /*dropDownIdNameFilter, dropDownIdValueFilter,*/ dropDownNames, dropDownValuesDisplay, lookupTableName) {

        console.log(tableName);

        let responseArray: any = [];
        if (lookupTableName != undefined) {

            responseArray = await this.service.retrieveSingleRecordService("lookupvalue", "lookuptypeid", lookupTableName);

            console.log(responseArray);

        } else {

            console.log(tableName);

            responseArray = await this.trnxService.retrieveTrnxDropdownService(tableName, 0, dropDownValuesDisplay);
            responseArray = responseArray.filter(item => item.status !== "No");
            console.log(responseArray)



        }


        if (responseArray != null || responseArray != undefined) {
            for (let i = 0; i < responseArray.length; i++) {

                //console.log(i);

                //console.log(responseArray[i][dropDownNames]);

                //console.log(responseArray[i][dropDownValuesDisplay]);

                responseArray[i]["keyJson"] = [responseArray[i][dropDownNames]];

                responseArray[i]["dropDownValueDisplay"] = responseArray[i][dropDownValuesDisplay];

            }
        }

        console.log(responseArray);

        return responseArray;

    }

    trnxEditIcon(ngTrnxModelObject, rowData) {

        console.log(ngTrnxModelObject);

        console.log(rowData);

        ngTrnxModelObject.forEach(trnxInputObject => {

            trnxInputObject.imageShow = false;

            trnxInputObject.imageShow1 = false;

            trnxInputObject.fileShow = false;

            trnxInputObject.downloadIconShow = false;

        });

        ngTrnxModelObject.forEach(trnxInputObject => {

            for (let keyName in rowData) {
                console.log(keyName)
                if (keyName == trnxInputObject.keyName) {

                    console.log(rowData[keyName]);

                    trnxInputObject.keyValue = rowData[keyName];
                    // trnxInputObject.keyValue = (trnxInputObject.keyValue).replace(/\"/g,"");
                    console.log(trnxInputObject.keyValue)

                }

            }

        });

        ngTrnxModelObject.forEach(trnxInputObject => {

            console.log(trnxInputObject);

            if (trnxInputObject.imageName != null && trnxInputObject.keyValue != null) {

                if ((trnxInputObject.keyValue).length > 0) {

                    console.log(trnxInputObject.keyValue);

                    let extension: any;

                    if ((trnxInputObject.keyValue).includes(".")) {

                        extension = (trnxInputObject.keyValue).substring((trnxInputObject.keyValue).lastIndexOf(".") + 1);

                        console.log(extension);

                    }

                    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {



                    }

                    else {

                        trnxInputObject.imageShow = true;

                        trnxInputObject.fileShow = true;

                        trnxInputObject.downloadIconShow = true;

                    }

                }

            }

        });

        for (let i = 0; i < ngTrnxModelObject.length; i++) {

            if (ngTrnxModelObject[i].imageName != null) {

                console.log(ngTrnxModelObject[i].imageName);

                for (let j = 0; j < ngTrnxModelObject.length; j++) {

                    if (ngTrnxModelObject[i].imageName == ngTrnxModelObject[j].keyName) {

                        console.log(ngTrnxModelObject[j].keyName);

                        ngTrnxModelObject[i].imageNameDisplay = ngTrnxModelObject[j].keyValue;

                    }

                }

            }

        }

        ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary.fieldType == "valueDate" || ngModelObjectTemporary.fieldType == "valueStartDate" || ngModelObjectTemporary.fieldType == "valueEndDate" || ngModelObjectTemporary.fieldType == "DatePicker" || ngModelObjectTemporary.fieldType == "Start & End Date") {

                this.changeStartEndDateValue(ngModelObjectTemporary, ngTrnxModelObject);

            }

        });

        ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary.fieldType == "valueDateTime" || ngModelObjectTemporary.fieldType == "valueStartDateTime" || ngModelObjectTemporary.fieldType == "valueEndDateTime" || ngModelObjectTemporary.fieldType == "DateTimePicker" || ngModelObjectTemporary.fieldType == "Start & End Date Time") {

                this.changeStartEndDateTimeValue(ngModelObjectTemporary, ngTrnxModelObject);

            }

        });

        // ngTrnxModelObject.forEach(ngModelObjectTemporary => {

        //     if (ngModelObjectTemporary.fieldType == "valueCheckbox" || ngModelObjectTemporary.fieldType == "Checkbox") {

        //         if (ngModelObjectTemporary.keyValue == "false") {

        //             ngModelObjectTemporary.keyValue = false;

        //         }

        //         else {

        //             ngModelObjectTemporary.keyValue = true;

        //         }

        //     }
        // });

        let approvedid: any;
        for (let i = 0; i < ngTrnxModelObject.length; i++) {
            if (ngTrnxModelObject[i].fieldType == "valueCheckbox" || ngTrnxModelObject[i].fieldType == "Checkbox") {
                approvedid = ngTrnxModelObject[i]
                if (ngTrnxModelObject[i].keyValue == "false") {

                    ngTrnxModelObject[i].keyValue = false;

                }

                else {

                    ngTrnxModelObject[i].keyValue = true;

                }

            }
            if (ngTrnxModelObject[i].fieldType == "Linked Fields") {

                if (ngTrnxModelObject[i].keyName == "approvedby") {

                    if (ngTrnxModelObject[i].keyValue == "0") {

                        approvedid.keyValue = false;

                    }

                    else {

                        approvedid.keyValue = true;

                    }
                }

            }
        }

        return ngTrnxModelObject;

    }

    async trnxDeleteIcon(e, tableName, ngTrnxModelObject, trnxTableID, screenTypeTOrM) {

        console.log(ngTrnxModelObject);

        console.log(screenTypeTOrM);

        let idValue = "";

        let idName = "";

        idName = trnxTableID

        console.log(idName);

        console.log(e.rowData);

        idValue = e.rowData[idName];

        console.log(idValue);

        await this.trnxService.deleteTrnxService(tableName, idName, idValue, screenTypeTOrM,ngTrnxModelObject);
        window.location.reload();

    }

    async retrieveTrnx(tableName, listObjectDropDown, /*dropDowns,*/ ngTrnxModelObject, recordsStart) {

        let agGridObject: any = [];

        agGridObject = await this.trnxService.retrieveTrnxService(tableName, recordsStart);

        console.log(agGridObject);

        console.log(listObjectDropDown);

        //console.log(dropDowns);

        //agGridObject is table data
        /*for (let i = 0; i < agGridObject.length; i++) {

            //dropsDowns length is equal to number of dropDowns in form
            for (let j = 0; j < dropDowns.length; j++) {

                console.log(dropDowns[j][0]);

                console.log(dropDowns[j][1]);

                console.log(listObjectDropDown[dropDowns[j][1]]);

                //listObjectDropDowns length is equal to number of dropDowns in form
                for (let l = 0; l < listObjectDropDown[dropDowns[j][1]].length; l++) {

                    console.log(l);

                    //Ids of dropDowns is equal to agGridObject ids, example here ids are codelistid, clcode
                    if (listObjectDropDown[dropDowns[j][1]][l][dropDowns[j][3]] == agGridObject[i][dropDowns[j][0]]) {

                        //Here example codelistid related codelistname is stored in agGridObject, clcode related cldecoded is stored in agGridObject
                        agGridObject[i][dropDowns[j][2]] = listObjectDropDown[dropDowns[j][1]][l][dropDowns[j][4]];

                    }

                }

            }

        }*/

        console.log(agGridObject);
        console.log(ngTrnxModelObject)

        ngTrnxModelObject.forEach(trnxInputObject => {

            if (trnxInputObject.fieldType == "valueDateTime" || trnxInputObject.fieldType == "valueStartDateTime" || trnxInputObject.fieldType == "valueEndDateTime" || trnxInputObject.fieldType == "DateTimePicker" || trnxInputObject.fieldType == "Start & End Date Time") {

                let keyName = trnxInputObject.keyName;

                agGridObject.forEach(agGridObjectTemp => {

                    if (agGridObjectTemp[keyName] != null) {

                        agGridObjectTemp[keyName] = moment(agGridObjectTemp[keyName]).format('YYYY-MM-DD HH:mm:ss');

                    }

                });

            }

        });

        console.log(agGridObject);

        return agGridObject;

    }

    async retrieveTrnxPaginated(tableName, listObjectDropDown,  ngTrnxModelObject, recordsStart,pageNo,pageSize,totalRecords) {

        let agGridObject: any = [];

        agGridObject = await this.trnxService.retrieveTrnxServicePaginated(tableName, recordsStart,pageNo,pageSize,totalRecords);

        console.log(agGridObject);

        console.log(listObjectDropDown);


        console.log(agGridObject);
        console.log(ngTrnxModelObject)

        ngTrnxModelObject.forEach(trnxInputObject => {

            if (trnxInputObject.fieldType == "valueDateTime" || trnxInputObject.fieldType == "valueStartDateTime" || trnxInputObject.fieldType == "valueEndDateTime" || trnxInputObject.fieldType == "DateTimePicker" || trnxInputObject.fieldType == "Start & End Date Time") {

                let keyName = trnxInputObject.keyName;

                agGridObject.forEach(agGridObjectTemp => {

                    if (agGridObjectTemp[keyName] != null) {

                        agGridObjectTemp[keyName] = moment(agGridObjectTemp[keyName]).format('YYYY-MM-DD HH:mm:ss');

                    }

                });

            }

        });

        console.log(agGridObject);

        return agGridObject;

    }

    async retrieveChildTrnx(tableName, listObjectDropDown, ngTrnxModelObject, recordsStart, childKey, tableId, screenId) {

        let agGridObject: any = [];

        agGridObject = await this.trnxService.retrieveChildTrnxService(tableName, recordsStart, childKey, tableId, screenId);

        console.log(agGridObject);

        console.log(listObjectDropDown);

        //console.log(dropDowns);

        //agGridObject is table data
        /*for (let i = 0; i < agGridObject.length; i++) {

            //dropsDowns length is equal to number of dropDowns in form
            for (let j = 0; j < dropDowns.length; j++) {

                console.log(dropDowns[j][0]);

                console.log(dropDowns[j][1]);

                console.log(listObjectDropDown[dropDowns[j][1]]);

                //listObjectDropDowns length is equal to number of dropDowns in form
                for (let l = 0; l < listObjectDropDown[dropDowns[j][1]].length; l++) {

                    console.log(l);

                    //Ids of dropDowns is equal to agGridObject ids, example here ids are codelistid, clcode
                    if (listObjectDropDown[dropDowns[j][1]][l][dropDowns[j][3]] == agGridObject[i][dropDowns[j][0]]) {

                        //Here example codelistid related codelistname is stored in agGridObject, clcode related cldecoded is stored in agGridObject
                        agGridObject[i][dropDowns[j][2]] = listObjectDropDown[dropDowns[j][1]][l][dropDowns[j][4]];

                    }

                }

            }

        }*/

        console.log(agGridObject);
        console.log(ngTrnxModelObject);

        ngTrnxModelObject.forEach(trnxInputObject => {

            if (trnxInputObject.fieldType == "valueDateTime" || trnxInputObject.fieldType == "valueStartDateTime" || trnxInputObject.fieldType == "valueEndDateTime" || trnxInputObject.fieldType == "DateTimePicker" || trnxInputObject.fieldType == "Start & End Date Time") {

                let keyName = trnxInputObject.keyName;

                agGridObject.forEach(agGridObjectTemp => {

                    if (agGridObjectTemp[keyName] != null) {

                        agGridObjectTemp[keyName] = moment(agGridObjectTemp[keyName]).format('YYYY-MM-DD HH:mm:ss');

                    }

                });

            }

        });

        console.log(agGridObject);

        return agGridObject;
    }

    //Combination of columns as unique in table
    checkingCombinationColumnsUnique(ngTrnxModelObject, retrieveObject, isExecute) {

        ngTrnxModelObject.forEach(tempObject => {

            console.log(tempObject);

            console.log(tempObject["combinationColumn"]);

            //In combinationColumn it has keyNames, if combiantionColumn is not null
            if (tempObject["combinationColumn"] != null) {

                //keyNames of combination of columns is stored in combinationKeys
                let combinationKeys = tempObject["combinationColumn"].split(",");

                console.log(combinationKeys);

                let combinationValues = [];

                //For getting keyValues of combination of columns
                for (let i = 0; i < combinationKeys.length; i++) {

                    ngTrnxModelObject.forEach(tempNgModelObject => {

                        console.log(tempNgModelObject["keyName"]);

                        console.log(combinationKeys[i]);

                        //Comparing keyName of tempNgModelObject is equal to combination key
                        if (tempNgModelObject["keyName"] == combinationKeys[i]) {

                            //The values of combinationKeys is stored in combiantionValues
                            combinationValues.push(tempNgModelObject["keyValue"]);

                            console.log(tempNgModelObject["keyValue"]);

                            console.log(combinationValues);

                        }

                    });

                }

                console.log(ngTrnxModelObject);

                let idValue = 0;

                ngTrnxModelObject.forEach(ngModelObjectTemporary => {

                    console.log(ngModelObjectTemporary["keyName"]);

                    //Comparing keyName of ngModelObjectTemporary is equalt to id
                    if (ngModelObjectTemporary["keyName"] == "id") {

                        //Storing id value into idValue
                        idValue = ngModelObjectTemporary["keyValue"];

                    }

                });

                //If idValue is not equal to zero, this is for update record
                if (idValue != 0) {

                    retrieveObject.forEach(retrieveObjectTemporary => {

                        //Comparing id of retrieveObjectTemporary is equal to idValue
                        if (idValue == retrieveObjectTemporary["id"]) {

                            console.log(idValue);

                            ngTrnxModelObject.forEach(ngModelObjectTemporary => {

                                console.log(retrieveObjectTemporary.hasOwnProperty(ngModelObjectTemporary.keyName))

                                //Checking keyNamge of ngModelObjectTemporary is present in retrieveObjectTemporary
                                if (retrieveObjectTemporary.hasOwnProperty(ngModelObjectTemporary.keyName) == true) {

                                    retrieveObjectTemporary[ngModelObjectTemporary.keyName] = ngModelObjectTemporary["keyValue"];

                                }

                            });

                        }

                    });

                }

                console.log(retrieveObject);

                //Checking combination of columns is unique or not with each record of the retrieveObject
                retrieveObject.forEach(temporaryRetrieveObject => {

                    let checkIsExecute = [];

                    console.log(tempObject["keyValue"]);

                    console.log(temporaryRetrieveObject[tempObject["keyName"]]);

                    console.log(temporaryRetrieveObject[tempObject["combinationColumn"]]);

                    console.log(temporaryRetrieveObject);

                    for (let i = 0; i < combinationKeys.length; i++) {

                        console.log(combinationValues[i]);

                        console.log(combinationKeys[i]);

                        if (combinationValues[i] == temporaryRetrieveObject[combinationKeys[i]]) {

                            checkIsExecute.push(true);

                            console.log(i);

                            console.log(combinationKeys.length);

                            if ((i + 1) == (combinationKeys.length)) {

                                console.log(i + 1);

                                console.log(combinationKeys.length);

                                //It will execute, if unique combination of values is present in record
                                if (checkIsExecute.length == combinationKeys.length) {

                                    console.log(checkIsExecute);

                                    console.log(checkIsExecute.length);

                                    isExecute++;

                                }

                            }

                        }

                    }

                });

            }

        });

        return isExecute;

    }

    ngModelData(ngTrnxModelObject) {

        console.log(ngTrnxModelObject);

        //In aggrid for each dropdown each json, those jsons are removing here.
        /*for (let i = 0; i < ngTrnxModelObject.length; i++) {

            if (ngTrnxModelObject[i].agGridDecode != null) {

                console.log(ngTrnxModelObject[i].agGridDecode);

                for (let j = 0; j < ngTrnxModelObject.length; j++) {

                    if (ngTrnxModelObject[i].agGridDecode == ngTrnxModelObject[j].keyName) {

                        console.log(ngTrnxModelObject[i].agGridDecode);

                        ngTrnxModelObject[j].keyValue = "";

                    }

                }

            }

        }*/

        //In temporaryObject storing jsons with needed keys.
        let temporaryObject: any = [];

        temporaryObject = [];

        ngTrnxModelObject.forEach(e => {

            console.log(e);

            //chaitanya
            //27-07-2022
            //added passwordKey for storing the servicePassword.
            let ngModelObjectLocal = { keyName: "", keyValue: "", binaryFormat: "", tableName: "", status: "", fieldType: "", imageNameDisplay: "",passwordKey:"",dropDownValue:"" ,dropDownIdValues:[]};

            console.log(e.keyValue);

            if (e.fieldType == "valueDate" || e.fieldType == "valueStartDate" || e.fieldType == "valueEndDate" || e.fieldType == "DatePicker" || e.fieldType == "Start & End Date") {

                console.log(e.keyValue);

                if (e.keyValue == "") {

                    e.keyValue = null;

                }

                if (e.keyValue != null) {

                    console.log(e.keyValue);

                    e.keyValue = moment(e.keyValue).format("YYYY-MM-DD");

                    console.log(ngTrnxModelObject[e.keyValue]);

                }

            }

            if (e.fieldType == "valueDateTime" || e.fieldType == "valueStartDateTime" || e.fieldType == "valueEndDateTime" || e.fieldType == "DateTimePicker" || e.fieldType == "Start & End Date Time") {

                console.log(e.keyValue);

                if (e.keyValue == null) {

                    console.log(e.keyValue);

                    e.keyValue = "";

                }

                if (e.keyValue != "") {

                    console.log(e.keyValue);

                    var dateString = e.keyValue._d;

                    if (dateString == undefined) {

                    }

                    else {

                        console.log(dateString);

                        var dateObj = new Date(dateString);

                        console.log(dateObj);

                        var momentObj = moment(dateObj);

                        console.log(momentObj);

                        e.keyValue = momentObj.format('YYYY-MM-DD HH:mm:ss');

                    }

                    console.log(e.keyValue);

                }

            }

            if (e.fieldType == "valueCheckbox" || e.fieldType == "Checkbox") {

                console.log(e.keyValue);

                if (e.keyValue == "") {

                    e.keyValue = null;

                }

                if (e.keyValue == null) {

                    console.log(e.keyValue);

                    e.keyValue = false;

                    console.log(e.keyValue);

                }

            }



            ngModelObjectLocal.keyName = e.keyName;

            ngModelObjectLocal.keyValue = e.keyValue;

            ngModelObjectLocal.binaryFormat = e.binaryFormat;

            ngModelObjectLocal.fieldType = e.fieldType;

            ngModelObjectLocal.imageNameDisplay = e.imageNameDisplay;

            ngModelObjectLocal.dropDownValue=e.value;
            // console.log( ngModelObjectLocal.dropDownValue);
            ngModelObjectLocal.dropDownIdValues=e.dropDownIdValues;

            console.log(ngModelObjectLocal);

            temporaryObject.push(ngModelObjectLocal);

        });

        console.log(temporaryObject);

        return temporaryObject;

    }

    ngModelChildData(ngTrnxChildModelObject) {
        console.log(ngTrnxChildModelObject);

        //In aggrid for each dropdown each json, those jsons are removing here.
        /*for (let i = 0; i < ngTrnxModelObject.length; i++) {

            if (ngTrnxModelObject[i].agGridDecode != null) {

                console.log(ngTrnxModelObject[i].agGridDecode);

                for (let j = 0; j < ngTrnxModelObject.length; j++) {

                    if (ngTrnxModelObject[i].agGridDecode == ngTrnxModelObject[j].keyName) {

                        console.log(ngTrnxModelObject[i].agGridDecode);

                        ngTrnxModelObject[j].keyValue = "";

                    }

                }

            }

        }*/

        //In temporaryObject storing jsons with needed keys.
        let temporaryObject: any = [];

        temporaryObject = [];

        ngTrnxChildModelObject.forEach(e => {

            console.log(e);

            let ngModelObjectLocal = { keyName: "", keyValue: "", binaryFormat: "", tableName: "", status: "", fieldType: "", imageNameDisplay: "", formName: "", showForm: "" };

            console.log(e.keyValue);

            if (e.fieldType == "valueDate" || e.fieldType == "valueStartDate" || e.fieldType == "valueEndDate" || e.fieldType == "DatePicker" || e.fieldType == "Start & End Date") {

                console.log(e.keyValue);

                if (e.keyValue == "") {

                    e.keyValue = null;

                }

                if (e.keyValue != null) {

                    console.log(e.keyValue);

                    e.keyValue = moment(e.keyValue).format("YYYY-MM-DD");

                    console.log(ngTrnxChildModelObject[e.keyValue]);

                }

            }

            if (e.fieldType == "valueDateTime" || e.fieldType == "valueStartDateTime" || e.fieldType == "valueEndDateTime" || e.fieldType == "DateTimePicker" || e.fieldType == "Start & End Date Time") {

                console.log(e.keyValue);

                if (e.keyValue == null) {

                    console.log(e.keyValue);

                    e.keyValue = "";

                }

                if (e.keyValue != "") {

                    console.log(e.keyValue);

                    var dateString = e.keyValue._d;

                    if (dateString == undefined) {

                    }

                    else {

                        console.log(dateString);

                        var dateObj = new Date(dateString);

                        console.log(dateObj);

                        var momentObj = moment(dateObj);

                        console.log(momentObj);

                        e.keyValue = momentObj.format('YYYY-MM-DD HH:mm:ss');

                    }

                    console.log(e.keyValue);

                }

            }

            if (e.fieldType == "valueCheckbox" || e.fieldType == "Checkbox") {

                console.log(e.keyValue);

                if (e.keyValue == "") {

                    e.keyValue = null;

                }

                if (e.keyValue == null) {

                    console.log(e.keyValue);

                    e.keyValue = false;

                    console.log(e.keyValue);

                }

            }

            ngModelObjectLocal.keyName = e.keyName;

            ngModelObjectLocal.keyValue = e.keyValue;

            ngModelObjectLocal.formName = e.formName;

            ngModelObjectLocal.showForm = e.showForm;

            ngModelObjectLocal.binaryFormat = e.binaryFormat;

            ngModelObjectLocal.fieldType = e.fieldType;

            ngModelObjectLocal.imageNameDisplay = e.imageNameDisplay;

            console.log(ngModelObjectLocal);

            temporaryObject.push(ngModelObjectLocal);

        });

        console.log(temporaryObject);

        return temporaryObject;
    }

    async insertTrnxData(temporaryObject, tableName, isExecute, ngTrnxModelObject, trnxTableID, temporaryChildObject, screenTypeTOrM, childKey, masterTableId) {
        console.log(trnxTableID)
        console.log(temporaryObject);

        console.log(temporaryChildObject)

        console.log(tableName);
        let tableNameindex = 0;
        tableNameindex = tableName.lastIndexOf("mstr");
        console.log(tableNameindex)
        let temporarytableName = tableName.slice(0, tableNameindex);
        let childTableName = temporarytableName + "trxn";
        console.log(childTableName)

        console.log(isExecute);

        console.log(ngTrnxModelObject);

        console.log(screenTypeTOrM);

        console.log(childKey);

        console.log(masterTableId);

        if (isExecute == 0) {
            temporaryObject.forEach(tempObject => {

                if (tempObject.binaryFormat == true) {

                    tempObject.keyValue = tempObject.keyValue.split(",", 2)[1];

                    console.log(tempObject.keyValue);

                }

            });

            if (screenTypeTOrM == "Transaction") {

                temporaryChildObject.forEach(tempChildObject => {

                    tempChildObject.forEach(eachChildObject => {
                        if (eachChildObject.binaryFormat == true) {

                            eachChildObject.keyValue = eachChildObject.keyValue.split(",", 2)[1];

                            console.log(eachChildObject.keyValue);

                        }
                    });

                });

            }

            for (var i = 0; i < temporaryObject.length; i++) {

                if (temporaryObject.keyValue != null) {

                    temporaryObject.keyValue = temporaryObject.keyValue.toString().trim();

                }

            }

            if (screenTypeTOrM == "Transaction") {

                for (var i = 0; i < temporaryChildObject.length; i++) {

                    if (temporaryChildObject.keyValue != null) {

                        temporaryChildObject.keyValue = temporaryChildObject.keyValue.toString().trim();

                    }

                }

            }

            let idName = "";
            let childidName = "";

            /*let agGridDecodeNames = [];

            console.log(agGridDecodeNames);*/

            idName = trnxTableID;
            let index = 0;
            index = trnxTableID.lastIndexOf("mstrid");
            console.log(index)
            childidName = trnxTableID.slice(0, index);
            childidName = childidName + "trxnid";

            /*ngTrnxModelObject.forEach(ngModelObjectTemporary => {

                if (ngModelObjectTemporary["agGridDecode"] != null) {

                    agGridDecodeNames.push(ngModelObjectTemporary["agGridDecode"]);

                }

            });*/

            console.log(idName);
            console.log(childidName)

            //console.log(agGridDecodeNames);
            let countapprove = 1;
            for (let i = 0; i < temporaryObject.length; i++) {

                if (temporaryObject[i].keyName == idName) {

                    temporaryObject.splice(i, 1);

                }
                else if (temporaryObject[i].fieldType == "Linked Fields") {
                    if (temporaryObject[i].keyName == "approvedby") {
                        console.log(temporaryObject[i].keyName)
                        temporaryObject.splice(i, 1);
                    }
                }

            }
            console.log("sfdfhdff")

            if (screenTypeTOrM == "Transaction") {

                for (let i = 0; i < temporaryChildObject.length; i++) {
                    console.log(temporaryChildObject[i])
                    for (let j = 0; j < temporaryChildObject[i].length; j++) {
                        if (temporaryChildObject[i][j].keyName == childidName) {

                            temporaryChildObject[i].splice(j, 1);

                        }
                        else if (temporaryChildObject[i][j].fieldType == "Decimal Number") {
                            if (temporaryChildObject[i][j].keyValue == "") {
                                temporaryChildObject[i][j].keyValue = null;
                            }
                        }
                        temporaryChildObject[i][0].tableName = childTableName;

                        temporaryChildObject[i][0].status = 'Yes';
                    }

                }

            }

            console.log(temporaryObject);
            console.log(temporaryChildObject);

            /*agGridDecodeNames.forEach(agGridDecodeNamesTemporary => {

                console.log(agGridDecodeNamesTemporary);

                console.log(temporaryObject);

                for (let i = 0; i < temporaryObject.length; i++) {

                    console.log(temporaryObject[i].keyName);

                    if (temporaryObject[i].keyName == agGridDecodeNamesTemporary) {

                        console.log(temporaryObject[i]);

                        temporaryObject.splice(i, 1);

                    }

                }

            });*/

            temporaryObject.forEach(tempObject => {

                if (tempObject.keyValue == "" || tempObject.keyValue == undefined) {

                    tempObject.keyValue = null;

                }

                //If the column type is valueCheckbox then the keyValue will be change to false.
                if (tempObject.fieldType == "valueCheckbox" || tempObject.fieldType == "Checkbox") {

                    if (tempObject.keyValue == null) {

                        tempObject.keyValue = false;

                        console.log(tempObject.keyValue);

                    }

                }

            });

            if (screenTypeTOrM == "Transaction") {

                temporaryChildObject.forEach(tempChildObject => {

                    if (tempChildObject.keyValue == "" || tempChildObject.keyValue == undefined) {

                        tempChildObject.keyValue = null;

                    }

                    //If the column type is valueCheckbox then the keyValue will be change to false.
                    if (tempChildObject.fieldType == "valueCheckbox" || tempChildObject.fieldType == "Checkbox") {

                        if (tempChildObject.keyValue == null) {

                            tempChildObject.keyValue = false;

                            console.log(tempChildObject.keyValue);

                        }

                    }

                });

            }

            temporaryObject[0].tableName = tableName;

            temporaryObject[0].status = 'Yes';

            console.log(temporaryObject);
            console.log(temporaryChildObject);

            //It is execute for storing master table id value into key value.
            temporaryObject.forEach(temporaryObjectTemp => {

                console.log(temporaryObjectTemp.keyName);
                console.log(temporaryObjectTemp)

                console.log(masterTableId);

                console.log(temporaryObjectTemp.keyName == masterTableId);

                if (temporaryObjectTemp.keyName == masterTableId) {

                    console.log(temporaryObjectTemp);

                    temporaryObjectTemp.keyValue = childKey;

                }

            });

            console.log(temporaryObject);

            //Api call to insert data. It will generate response, it has value true or false.
            let response = await this.trnxService.insertTrnxService(temporaryObject, temporaryChildObject, idName, temporarytableName, screenTypeTOrM);

            return response;

        }

        else {

            this.snackBar.open("It will not allow duplicate records.", "OK", { duration: 5000 });

        }

    }

    async updateTrnxData(temporaryObject, tableName, isExecute, ngTrnxModelObject, trnxTableID,idValue) {

        console.log(temporaryObject);

        console.log(tableName);

        console.log(isExecute);

        console.log(ngTrnxModelObject);

        console.log(trnxTableID);

        temporaryObject.forEach(tempObject => {

            if (tempObject.binaryFormat == true) {

                tempObject.keyValue = tempObject.keyValue.split(",", 2)[1];

                console.log(tempObject.keyValue);

            }

        });

        for (var i = 0; i < temporaryObject.length; i++) {

            if (temporaryObject.keyValue != null) {

                temporaryObject.keyValue = temporaryObject.keyValue.toString().trim();

            }
        }

        let idName = "";

        /*let agGridDecodeNames = [];

        console.log(agGridDecodeNames);*/

        idName = trnxTableID;

        console.log(idName);

        /*ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary["agGridDecode"] != null) {

                agGridDecodeNames.push(ngModelObjectTemporary["agGridDecode"]);

            }

        });*/

        console.log(idName);

        //console.log(agGridDecodeNames);

        if (isExecute <= 1) {
            /*
            Author:D.Chaitanya
            Date:27-04-2022
            No Need to assing value after clicking on the update button,There may be a chance of changing the value and then clicking on update button.
            */
           // let idValue: any;

            for (let i = 0; i < temporaryObject.length; i++) {

                if (temporaryObject[i].keyName == idName) {

                    idValue = temporaryObject[i].keyValue;

                    console.log(idValue);

                    temporaryObject.splice(i, 1);

                }
                //else
                 if (temporaryObject[i].fieldType == "Linked Fields") {
                    if (temporaryObject[i].keyName == "approvedby") {
                        console.log(temporaryObject[i].keyName)
                        temporaryObject.splice(i, 1);
                    }
                }

            }

            console.log(temporaryObject);

            /*agGridDecodeNames.forEach(agGridDecodeNamesTemporary => {

                console.log(agGridDecodeNamesTemporary);

                console.log(temporaryObject);

                for (let i = 0; i < temporaryObject.length; i++) {

                    console.log(temporaryObject[i].keyName);

                    if (temporaryObject[i].keyName == agGridDecodeNamesTemporary) {

                        console.log(temporaryObject[i]);

                        temporaryObject.splice(i, 1);

                    }

                }

            });*/

            temporaryObject.forEach(tempObject => {

                if (tempObject.keyValue == "" || tempObject.keyValue == undefined) {

                    tempObject.keyValue = null;

                }

                //If the column type is valueCheckbox then the keyValue will be change to false.
                if (tempObject.fieldType == "valueCheckbox" || tempObject.fieldType == "Checkbox") {

                    if (tempObject.keyValue == null) {

                        tempObject.keyValue = false;

                        console.log(tempObject.keyValue);

                    }

                }

            });

            console.log(temporaryObject);

            console.log(tableName);

            console.log(idName);

            console.log(idValue);

            //Api call to update data. It will generate response, it has value true or false.
            let response = await this.trnxService.updateTrnxService(temporaryObject, tableName, idName, idValue);

            return response;

        }

        else {

            this.snackBar.open("It will not allow duplicate records.", "OK", { duration: 5000 });

        }

    }

    // event on input type file
    imageFunction($event, ngModelTemporary, ngModelObject, formValid): void {

        this.readThisImage($event.target, ngModelTemporary, ngModelObject, formValid);

    }
    // to encode the selected photo
    readThisImage(inputValue: any, ngModelTemporary, ngModelObject, formValid): void {

        console.log(inputValue);

        console.log(ngModelTemporary);

        var file: File = inputValue.files[0];

        console.log(file);

        var FileS: File = inputValue.files[0];

        console.log(FileS);

        console.log(file.type);
        let flag:boolean= this.commonTs.hasMultipleExtensions(file);


        if (!flag&& (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === "text/csv" ||file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'||file.type=='')) {

            console.log(file.size / 1000)
            var size = file.size / 1000
            if (size <= 32000) {
                var fileName = FileS.name;
                //var filename = (<HTMLInputElement>document.getElementById('file-id')).value;

                var photo: FileReader = new FileReader();

                console.log(typeof photo);

                photo.onloadend = e => {
                    var img = photo.result;
                    console.log(photo.result);
                    var photobase64result = photo.result.toString().split(",", 2)[1];

                    console.log(photobase64result);

                    // this is to check the file is empty or not
                    if (photobase64result == null) {
                        this.snackBar.open("Please Upload valid attachment", "OK", {
                            duration: 5000
                        });
                    } else {

                        ngModelTemporary.imageShow = true;

                        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {

                            ngModelTemporary.imageShow1 = true;

                            ngModelTemporary.binaryFormat = true;

                          ngModelTemporary.fileShow = false;

                            ngModelTemporary.keyValue = photo.result.toString();

                        }

                        if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||  file.type === "text/csv"||file.type=='') {

                            ngModelTemporary.imageShow1 = false;

                            ngModelTemporary.fileShow = true;

                            ngModelTemporary.keyValue = photobase64result;

                        }

                        ngModelTemporary.downloadIconShow = false;

                        console.log(ngModelTemporary);

                        ngModelObject.forEach(ngModelObjectTemporary => {

                            console.log(ngModelObjectTemporary.keyName);

                            console.log(ngModelTemporary.imageName);

                            if (ngModelObjectTemporary.keyName == ngModelTemporary.imageName) {

                                ngModelObjectTemporary.keyValue = file.name;

                                ngModelTemporary.imageNameDisplay = file.name;

                            }

                        });

                    }
                };
                photo.readAsDataURL(file);
            } else {
                this.snackBar.open("Selected image " + file.name + ", size should be less than 3 Mb", "OK", {
                    duration: 5000,
                });

                ngModelTemporary.imageShow = false;

                ngModelTemporary.keyValue = "";

                formValid.get(ngModelTemporary.fieldName).setValue(null);

                ngModelObject.forEach(ngModelObjectTemporary => {

                    console.log(ngModelObjectTemporary.keyName);

                    console.log(ngModelTemporary.imageName);

                    if (ngModelObjectTemporary.keyName == ngModelTemporary.imageName) {

                        ngModelObjectTemporary.keyValue = "";

                        formValid.get(ngModelObjectTemporary.fieldName).setValue(null);

                    }

                });

            }
        } else {
            this.snackBar.open(file.name + " is a invalid image type ", "OK", {
                duration: 5000,
            });

            ngModelTemporary.imageShow = false;

            ngModelTemporary.keyValue = "";

            formValid.get(ngModelTemporary.fieldName).setValue(null);

            ngModelObject.forEach(ngModelObjectTemporary => {

                console.log(ngModelObjectTemporary.keyName);

                console.log(ngModelTemporary.imageName);

                if (ngModelObjectTemporary.keyName == ngModelTemporary.imageName) {

                    ngModelObjectTemporary.keyValue = "";

                    formValid.get(ngModelObjectTemporary.fieldName).setValue(null);

                }

            });

        }

    }


    readThisChildImage(inputValue: any, ngModelChildTemporary, ngTrnxChildModelObject, formChildValid): void {

        console.log(inputValue);

        console.log(ngModelChildTemporary);

        var file: File = inputValue.files[0];

        console.log(file);

        var FileS: File = inputValue.files[0];

        console.log(FileS);

        console.log(file.type);

        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

            console.log(file.size / 1000)
            var size = file.size / 1000
            if (size <= 800) {
                var fileName = FileS.name;
                //var filename = (<HTMLInputElement>document.getElementById('file-id')).value;

                var photo: FileReader = new FileReader();

                console.log(typeof photo);

                photo.onloadend = e => {
                    var img = photo.result;
                    console.log(photo.result);
                    var photobase64result = photo.result.toString().split(",", 2)[1];

                    console.log(photobase64result);

                    // this is to check the file is empty or not
                    if (photobase64result == null) {
                        this.snackBar.open("Please Upload valid attachment", "OK", {
                            duration: 5000
                        });
                    } else {

                        ngModelChildTemporary.imageShow = true;

                        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {

                            ngModelChildTemporary.imageShow1 = true;

                            ngModelChildTemporary.binaryFormat = true;

                            ngModelChildTemporary.keyValue = photo.result.toString();

                        }

                        if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

                            ngModelChildTemporary.imageShow1 = false;

                            ngModelChildTemporary.fileShow = true;

                            ngModelChildTemporary.keyValue = photobase64result;

                        }

                        ngModelChildTemporary.downloadIconShow = false;

                        console.log(ngModelChildTemporary);

                        ngTrnxChildModelObject.forEach(ngModelObjectTemporary => {

                            console.log(ngModelObjectTemporary.keyName);

                            console.log(ngModelChildTemporary.imageName);

                            if (ngModelObjectTemporary.keyName == ngModelChildTemporary.imageName) {

                                ngModelObjectTemporary.keyValue = file.name;

                                ngModelChildTemporary.imageNameDisplay = file.name;

                            }

                        });

                    }
                };
                photo.readAsDataURL(file);
            } else {
                this.snackBar.open("Selected image " + file.name + ", size should be less than 800kb", "OK", {
                    duration: 5000,
                });

                ngModelChildTemporary.imageShow = false;

                ngModelChildTemporary.keyValue = "";

                formChildValid.get(ngModelChildTemporary.fieldName).setValue(null);

                ngTrnxChildModelObject.forEach(ngModelObjectTemporary => {

                    console.log(ngModelObjectTemporary.keyName);

                    console.log(ngModelChildTemporary.imageName);

                    if (ngModelObjectTemporary.keyName == ngModelChildTemporary.imageName) {

                        ngModelObjectTemporary.keyValue = "";

                        formChildValid.get(ngModelObjectTemporary.fieldName).setValue(null);

                    }

                });

            }
        } else {
            this.snackBar.open(file.name + " is a invalid image type ", "OK", {
                duration: 5000,
            });

            ngModelChildTemporary.imageShow = false;

            ngModelChildTemporary.keyValue = "";

            formChildValid.get(ngModelChildTemporary.fieldName).setValue(null);

            ngTrnxChildModelObject.forEach(ngModelObjectTemporary => {

                console.log(ngModelObjectTemporary.keyName);

                console.log(ngModelChildTemporary.imageName);

                if (ngModelObjectTemporary.keyName == ngModelChildTemporary.imageName) {

                    ngModelObjectTemporary.keyValue = "";

                    formChildValid.get(ngModelObjectTemporary.fieldName).setValue(null);

                }

            });

        }

    }

    openImage(ngModelTemporary) {

        console.log(ngModelTemporary)

        this.dialog.open(ImageviewPopupComponent, {
            width: "55%",
            height: "555px",
            data: { "ngModelTemporary": ngModelTemporary }
        });

    }

    //delete attachements
    trnxDeleteImage(ngModelTemporary, formValid, ngTrnxModelObject) {

        ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            console.log(ngModelObjectTemporary.keyName);

            console.log(ngModelTemporary.imageName);

            if (ngModelObjectTemporary.keyName == ngModelTemporary.imageName) {

                ngModelTemporary.imageShow = false;

                ngModelTemporary.imageShow1 = false;

                ngModelTemporary.fileShow = false;

                ngModelTemporary.keyValue = "";

                ngModelTemporary.binaryFormat = false;

                ngModelTemporary.downloadIconShow = false;

                ngModelObjectTemporary.keyValue = "";

                formValid.get(ngModelObjectTemporary.fieldName).setValue(null);

            }

        });

    }

    clearChildDropDown(ngTrnxModelObject, listObjectDropDown) {

        ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary.dropDownIndex != null) {

                if (ngModelObjectTemporary.dropDownIndex != null) {

                    listObjectDropDown[ngModelObjectTemporary.childDropDownKey] = [];

                }

            }

        });

        return listObjectDropDown;

    }

    /*async filterFunction(event, listObjectDropDown, ngTrnxModelObject, dropDowns) {

        console.log(event);

        if (event.childDropDownKey != null) {

            if (event.keyValue != undefined) {

                let keyName = event.keyName;

                let keyValue = event.keyValue;

                let childDropDownKey = event.childDropDownKey;

                let tableName = "";

                console.log(childDropDownKey);

                ngTrnxModelObject.forEach(ngModelObjectTemporary => {

                    if (ngModelObjectTemporary.dropDownIndex == childDropDownKey) {

                        console.log(ngModelObjectTemporary);

                        tableName = ngModelObjectTemporary.tableName;

                        console.log(tableName);

                    }

                });

                console.log(keyName);

                console.log(keyValue);

                listObjectDropDown[childDropDownKey] = [];

                let response: any = [];

                response = await this.service.retrieveService(tableName);

                let responseArray: any = [];

                response.forEach(responseTemporary => {

                    if (event.keyValue == responseTemporary[keyName]) {

                        responseArray.push(responseTemporary);

                    }

                });

                console.log(dropDowns);

                let dropDown = dropDowns[childDropDownKey];

                console.log(dropDown);

                for (let i = 0; i < responseArray.length; i++) {

                    console.log(i);

                    console.log(responseArray[i][dropDown[3]]);

                    console.log(responseArray[i][dropDown[4]]);

                    responseArray[i]["keyJson"] = [responseArray[i][dropDown[3]]];

                    responseArray[i]["dropDownValueDisplay"] = responseArray[i][dropDown[4]];

                }

                listObjectDropDown[childDropDownKey] = response;

                console.log(listObjectDropDown[childDropDownKey]);

            }

        }

        console.log(listObjectDropDown);

        return await listObjectDropDown;

    }*/

    changeStartEndDateValue(ngModelTemporary, ngTrnxModelObject) {

        console.log(ngModelTemporary);

        ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelTemporary.keyName == ngModelObjectTemporary.startDateName) {

                ngModelObjectTemporary.startDateValue = ngModelTemporary.keyValue;

            }

            if (ngModelTemporary.keyName == ngModelObjectTemporary.endDateName) {

                ngModelObjectTemporary.endDateValue = ngModelTemporary.keyValue;

            }

        });

        console.log(ngTrnxModelObject);

    }

    changeStartEndDateTimeValue(ngModelTemporary, ngTrnxModelObject) {

        console.log(ngModelTemporary);

        ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelTemporary.keyName == ngModelObjectTemporary.startDateTimeName) {

                ngModelObjectTemporary.startDateTimeValue = ngModelTemporary.keyValue;

            }

            if (ngModelTemporary.keyName == ngModelObjectTemporary.endDateTimeName) {

                ngModelObjectTemporary.endDateTimeValue = ngModelTemporary.keyValue;

            }

        });

        console.log(ngTrnxModelObject);

    }

    cleanStartEndDateValue(ngTrnxModelObject) {

        ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            ngModelObjectTemporary.startDateValue = moment(null);

            ngModelObjectTemporary.endDateValue = moment(null);

        });

    }

    cleanStartEndDateTimeValue(ngTrnxModelObject) {

        ngTrnxModelObject.forEach(ngModelObjectTemporary => {

            ngModelObjectTemporary.startDateTimeValue = moment(null);

            ngModelObjectTemporary.endDateTimeValue = moment(null);

        });

    }

    checkingStatusSnackBar() {

        this.snackBar.open('This record has been already deleted', "OK", { duration: 5000 });

    }



   

}




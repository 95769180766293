import { Component, OnInit } from '@angular/core';
import { ElasticserviceService } from "../../shared/services/elasticservice.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LocalStorageSettingsService } from "../../shared/services/local-storage-settings.service";
import { constants } from "../../shared/common/contants";
import { RemoveButton } from "../../shared/common/removebutton";
import { ActionCheckBox } from "../../shared/common/ActionCheckBox";
import { CustomCheckBoxButton } from "../../shared/common/checkBoxButton";
import { AttachmentButton } from "../../shared/common/attachmentButton";
import { DownloadTestResult } from "../../shared/common/testResultDownloadButton";
import {
  AssignUserRolePopupComponent
} from "../assign-user-role/assign-user-role-popup/assign-user-role-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { CreateCyclePopupComponent } from "./create-cycle-popup/create-cycle-popup.component";
import { Actionbutton } from '../../shared/common/Actionbutton';
import { TrnxBasePopupScreenComponent } from '../trnx-base-popup-screen/trnx-base-popup-screen.component';
import { TestExecutionService } from '../../shared/services/test-execution-service';
import { CommonService } from '../../shared/services/common.service';
import { TestExecutionControllerPopupComponent } from './test-execution-controller-popup/test-execution-controller-popup.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TrnxBaseScreenComponent } from '../trnx-base-screen/trnx-base-screen.component';
import { RowSelectedEvent } from 'ag-grid-community';
import { Deletebutton } from '../../shared/common/Deletebutton';
import { VaultBinderReferenceComponent } from 'src/app/vault-binder-reference/vault-binder-reference.component';
import { DropdownComponent } from 'src/app/shared/common/dropdown/dropdown.component';
import { FileUploadButton } from 'src/app/shared/common/fileUploadbutton';
import { Script } from 'vm';
import { log } from 'console';



@Component({
  selector: 'app-test-execution-controller',
  templateUrl: './test-execution-controller.component.html',
  styleUrls: ['./test-execution-controller.component.scss']
})
export class TestExecutionControllerComponent implements OnInit {


  search;
  elastciresponse: any;
  elasticFilterResponse: any;
  converted: any = [];
  filterConverted: any = [];
  eachconverted: any;
  filtereachconverted: any;
  studyCondition = false;
  filterCondition = false;
  productCondition = false;
  seriousnessCondition = false;
  from: number = 0;
  size: number = 100;
  sizeCount;
  totalCount;
  totalSizeCount;
  studyFilter: any = [];
  reportFilter: any = [];
  productFilter: any = [];
  seriousnessFilter: any = [];
  totalFIlters: any = [];
  finaltotalFilters: String;
  getdata: any = []
  object2: any = null;
  columnDefs
  rowData
  gridApi
  testScriptGridApi
  rowClassRules
  defaultColDef
  rowSelection
  paginationNumberFormatter;
  private gridColumnApi;
  private testScriptGridColumnApi
  grid
  testScriptgrid
  totalobjectfilters: any = []
  elasticObject = { studycheck: [], productcheck: [], reportTypecheck: [], seriousnesscheck: [] }
  frameworkComponents
  frameworkComponents1
  add;
  minus;
  filters;
  backgroundColorScreen = "";
  backgroundColorButton = "";
  lastPage;

  currentPage;

  nextPage;

  nextPagePlusOne;

  totalPages;

  gridColumnAPI;

  paginationPageSize;

  pageSize;

  value;

  overlayNoRowsTemplate;

  productname: any

  items: any

  testScriptColumn

  defaultColDefTestScript

  testCyclesData

  cycleId

  cycleNameDropDown

  FormValid
  testCycleMstrId
  temprunHistory
  documentStorageVault: String;


  constructor(private dialog: MatDialog, private spinner: NgxUiLoaderService, private snackbar: MatSnackBar, private localStorageSettings: LocalStorageSettingsService,
    private constants: constants,
    private testExecutionService: TestExecutionService,
    private service: CommonService,
    private router: Router,
  ) {
    this.frameworkComponents = {
      attachmentBttn: AttachmentButton,
      testResult: DownloadTestResult,
      delete: Deletebutton,
      uploadExcelFile: FileUploadButton,
      vaultReference: VaultBinderReferenceComponent,
      dropdown: DropdownComponent
    }



  }

  formvalidations() {
    this.FormValid = new UntypedFormGroup({

      'cycleName': new UntypedFormControl('', [Validators.required]),
    });
  }

  async ngOnInit() {
   
    // array to bind data to ag grid this.validation.equalto("password")
    this.formvalidations()
    let dnsName = this.localStorageSettings.getSettings('dnsName');
    let dnsId = Number(dnsName)
    this.cycleNameDropDown = await this.service.retrieveTestCycleMstr(dnsId)
    //  this.cycleNameDropDown = await this.service.retrieveSingleRecordService("testcyclemstr", "status", "Yes")
    console.log(this.cycleNameDropDown);
    this.FormValid.get('cycleName').valueChanges.subscribe(obj => {
      console.log(obj);
      // this.localStorageSettings.getSettings('dnsName');
      this.testExecutionService.retrieveTestExecutionByCycleMstrId(obj).subscribe(response => {
        if (response.error == true) {

          this.spinner.stop();

          this.snackbar.open(response.statusMessage, "OK", { duration: 5000 });


        } else {
          this.spinner.stop();
          console.log(response);
          console.log(this.object2)



          let gridData: any = []
          gridData = response
          if (gridData.length != 0) {

            this.testCycleMstrId = gridData[0].testCycleMstrId

          }
          this.onGridReady(this.grid)
          this.testScriptGridApi.setRowData(gridData)

        }
      })
    })
    let tenatId = 0;
    let chekingTenantid = this.localStorageSettings.getSettings('dnsName');
    if (chekingTenantid != null) {
      tenatId = Number(chekingTenantid);
      console.log(tenatId)
     
    }
    await this.returnJsuiteProperty(tenatId);
    this.columnDefs = [
      {
        headerName: 'Action',
        filter: false,
        cellRenderer: 'delete',
        cellRendererParams: {
          delete: this.delete.bind(this)
        },
        pinned: 'left',
        lockPinned: true,
        width: 80,
      },
      {
        headerName: 'Script Name',
        headerTooltip: 'Script Name',
        tooltipValueGetter: (params) => 'Script Name : ' + params.value,
        field: 'scriptName',
        filter: 'agTextColumnFilter',
        width: 180
      },
      {
        headerName: 'Script Count',
        headerTooltip: 'Script Count',
        tooltipValueGetter: (params) => 'Script Count : ' + params.value,
        field: 'scriptcount',
        filter: 'agTextColumnFilter',
        width: 114
      },
      {
        headerName: 'Status',
        headerTooltip: 'Status',
        tooltipValueGetter: (params) => 'Status : ' + params.value,
        field: 'execStatus',
        filter: 'agTextColumnFilter',
        width: 105
      },
      {
        headerName: 'Test Result',
        headerTooltip: 'Test Result',
        tooltipValueGetter: (params) => 'Test Result',
        cellRenderer: 'testResult',
        cellRendererParams: {
          downloadtestResult: this.testResult.bind(this)
          //delete: this.deleteIcon.bind(this),
        },
        width: 170
      },
      {
        headerName: 'Total Steps',
        headerTooltip: 'Total Steps',
        tooltipValueGetter: (params) => 'Total Steps : ' + params.value,
        field: 'totalTestSteps',
        filter: 'agTextColumnFilter',
        width: 114
      },
      {
        headerName: 'Passed',
        headerTooltip: 'Passed',
        tooltipValueGetter: (params) => 'Passed : ' + params.value,
        field: 'stepsPassed',
        filter: 'agTextColumnFilter',
        width: 87
      },
      {
        headerName: 'Failed',
        headerTooltip: 'Failed',
        tooltipValueGetter: (params) => 'Failed : ' + params.value,
        field: 'stepsFailed',
        filter: 'agTextColumnFilter',
        width: 80
      },
      {
        headerName: 'Start Date & Time',
        headerTooltip: 'Start Date & Time',
        tooltipValueGetter: (params) => 'Start DT : ' + params.value,
        field: 'startDate',
        filter: 'agTextColumnFilter',
        width: 180
      },
      {
        headerName: 'End Date & Time',
        headerTooltip: 'End Date & Time',
        tooltipValueGetter: (params) => 'End DT : ' + params.value,
        field: 'endDate',
        filter: 'agTextColumnFilter',
        width: 180
      },
      {
        headerName: 'Created By',
        headerTooltip: 'Created By',
        tooltipValueGetter: (params) => 'Created By : ' + params.value,
        field: 'testExecutionTrxnCreatedBy',
        filter: 'agTextColumnFilter',
        width: 180
      },
      {
        headerName: 'Created Date',
        headerTooltip: 'Created Date',
        tooltipValueGetter: (params) => 'Created Date : ' + params.value,
        field: 'testExecutionTrxnCreatedDate',
        filter: 'agTextColumnFilter',
        width: 180
      },
      {
        headerName: 'Machine ID',
        headerTooltip: 'Machine ID',
        tooltipValueGetter: (params) => 'Machine ID : ' + params.value,
        field: 'machineid',
        filter: 'agTextColumnFilter',
        width: 180
      },
      {
        headerName: 'ID',
        headerTooltip: 'ID',
        tooltipValueGetter: (params) => 'ID : ' + params.value,
        field: 'testExecutionTrxnId',
        filter: 'agTextColumnFilter',
        width: 60
      },
    ]
    if (this.documentStorageVault.toLowerCase() === 'true') {
      this.columnDefs = [
        {
          headerName: 'Action',
          filter: false,
          cellRenderer: 'delete',
          cellRendererParams: {
            delete: this.delete.bind(this)
          },
          pinned: 'left',
          lockPinned: true,
          width: 80,
        },
        {
          headerName: 'Script Name',
          headerTooltip: 'Script Name',
          tooltipValueGetter: (params) => 'Script Name : ' + params.value,
          field: 'scriptName',
          filter: 'agTextColumnFilter',
          width: 180
        },
        {
          headerName: 'Script Count',
          headerTooltip: 'Script Count',
          tooltipValueGetter: (params) => 'Script Count : ' + params.value,
          field: 'scriptcount',
          filter: 'agTextColumnFilter',
          width: 114
        },
        {
          headerName: 'Version',
          headerTooltip: 'Version',
          tooltipValueGetter: (params) => 'Version: ' + params.value,
          field: 'fileversion',
          filter: 'agTextColumnFilter',
          width: 95
        },
        {
          headerName: 'Status',
          headerTooltip: 'Status',
          tooltipValueGetter: (params) => 'Status : ' + params.value,
          field: 'execStatus',
          filter: 'agTextColumnFilter',
          width: 105
        },
        {
          headerName: 'Test Result',
          headerTooltip: 'Test Result',
          tooltipValueGetter: (params) => 'Test Result',
          cellRenderer: 'testResult',
          cellRendererParams: {
            downloadtestResult: this.testResult.bind(this)
            //delete: this.deleteIcon.bind(this),
          },
          width: 170
        },
        {
          headerName: 'Total Steps',
          headerTooltip: 'Total Steps',
          tooltipValueGetter: (params) => 'Total Steps : ' + params.value,
          field: 'totalTestSteps',
          filter: 'agTextColumnFilter',
          width: 114
        },
        {
          headerName: 'Passed',
          headerTooltip: 'Passed',
          tooltipValueGetter: (params) => 'Passed : ' + params.value,
          field: 'stepsPassed',
          filter: 'agTextColumnFilter',
          width: 87
        },
        {
          headerName: 'Failed',
          headerTooltip: 'Failed',
          tooltipValueGetter: (params) => 'Failed : ' + params.value,
          field: 'stepsFailed',
          filter: 'agTextColumnFilter',
          width: 80
        },
        {
          headerName: 'Vault Reference',
          headerTooltip: 'Vault Reference',
          cellRenderer: "vaultReference",
          field: 'execStatus',
          filter: 'agTextColumnFilter',
          width: 200,

        },
        {
          headerName: 'Start Date & Time',
          headerTooltip: 'Start Date & Time',
          tooltipValueGetter: (params) => 'Start DT : ' + params.value,
          field: 'startDate',
          filter: 'agTextColumnFilter',
          width: 180
        },
        {
          headerName: 'End Date & Time',
          headerTooltip: 'End Date & Time',
          tooltipValueGetter: (params) => 'End DT : ' + params.value,
          field: 'endDate',
          filter: 'agTextColumnFilter',
          width: 180
        },
        {
          headerName: 'Created By',
          headerTooltip: 'Created By',
          tooltipValueGetter: (params) => 'Created By : ' + params.value,
          field: 'testExecutionTrxnCreatedBy',
          filter: 'agTextColumnFilter',
          width: 180
        },
        {
          headerName: 'Created Date',
          headerTooltip: 'Created Date',
          tooltipValueGetter: (params) => 'Created Date : ' + params.value,
          field: 'testExecutionTrxnCreatedDate',
          filter: 'agTextColumnFilter',
          width: 180
        },
        {
          headerName: 'Machine ID',
          headerTooltip: 'Machine ID',
          tooltipValueGetter: (params) => 'Machine ID : ' + params.value,
          field: 'machineid',
          filter: 'agTextColumnFilter',
          width: 180
        },
        {
          headerName: 'ID',
          headerTooltip: 'ID',
          tooltipValueGetter: (params) => 'ID : ' + params.value,
          field: 'testExecutionTrxnId',
          filter: 'agTextColumnFilter',
          width: 60
        },
      ]

    }
    this.testScriptColumn = [
      {
        headerName: 'Select Script', filter: false,
        headerTooltip: 'Select Script',
        tooltipValueGetter: (params) => 'Select Script',
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        // cellRenderer: 'buttonRenderer',
        field: 'scriptName',
        width: 150
      },
      {
        headerName: 'Test Data',
        headerTooltip: 'Test Data',
        filter: false,
        cellRenderer: 'uploadExcelFile',
        cellRendererParams: {
          uploadExcelFile: this.uploadexcelfile.bind(this)
        },
        width: 80,
      },
    ]
    //
    // {
    //   headerName: 'Select Script', filter: false, cellRenderer: 'buttonRenderer',
    //   headerTooltip: 'Select Script',
    //   tooltipValueGetter: (params) => 'Select Script',
    //   cellRendererParams: {
    //     remove: this.updateCheckbox.bind(this),
    //   }, width: 113
    //
    // },
    // {
    //   headerName: 'Type',
    //   headerTooltip: 'Type',
    //   tooltipValueGetter: (params) => 'Type : ' + params.value,
    //   field: 'scriptType',
    //   filter: 'agTextColumnFilter',
    //   width: 110,
    // },
    // {
    //   headerName: 'Test Data', filter: false, cellRenderer: 'attachmentBttn',
    //   headerTooltip: 'Test Data',
    //   tooltipValueGetter: (params) => 'Test Data Sheet',
    //   cellRendererParams: {
    //     attachmentBttn: this.attachment.bind(this),
    //   }, width: 100
    // },
    if (this.documentStorageVault.toLowerCase() === 'true') {
      this.testScriptColumn = [
        {
          headerName: 'Select Script', filter: false,
          headerTooltip: 'Select Script',
          tooltipValueGetter: (params) => 'Select Script',
          // headerCheckboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          // cellRenderer: 'buttonRenderer',
          field: 'scriptName',
          width: 150
        },
        {
          headerName: 'Test Data',
          headerTooltip: 'Test Data',
          filter: false,
          cellRenderer: 'uploadExcelFile',
          cellRendererParams: {
            uploadExcelFile: this.uploadexcelfile.bind(this)
          },
          width: 80,
        },
        {
          headerName: 'Versions',
          filter: false,
          cellRendererFramework: DropdownComponent,
          cellRendererParams: (params) => {
            return {
              scriptName: params.data.scriptName,
              dropdown: this.versionDropdown.bind(this),
              onValueChange: (selectedValue) => this.onDropdownValueChange(params.data, selectedValue)
            };
          },
          width: 130,
        },

      ]
    }
    // if the record has status==no , row color will change
    this.rowClassRules = {
      "sick": function (params) {
        var numSickDays = params.node.data.status;
        return numSickDays === "No" || numSickDays === null;
      },

    };



    // below object is resizable, sorting and filter for ag-grid
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: function (params) {
        console.log(params)

        if (params.data.status == 'Failed') {
          //Here you can check the value and based on that you can change the color
          //mark police cells as red
          return { color: 'red' };
        } else {
          return { color: 'green' };
        }
      }
    };

    this.rowSelection = 'multiple'
    this.defaultColDefTestScript = {
      sortable: true,
      resizable: true,
      filter: true,
    };
    this.paginationPageSize = 10;
    this.paginationNumberFormatter = function (params) {
      return params.value.toLocaleString();
    };


    this.backgroundColorScreen = this.localStorageSettings.getSettings("ColorD");
    this.backgroundColorButton = this.localStorageSettings.getSettings("ColorA");
  }

  async returnJsuiteProperty(tenantId: number): Promise<void> {
    this.spinner.start();
    try {
      const response = await this.service.retrieveJsuiteRecord(tenantId);
      for (const obj of response) {
        if (obj.propertyname === 'Jsuite_JTest_Document_Storage_Vault') {
          this.documentStorageVault = obj.propertyvalue;
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }

    this.spinner.stop();
  }

  //excel export function
  onBtExport() {

    this.gridApi.exportDataAsCsv();

  }

  //pagination code
  onPaginationChanged() {

    if (this.gridApi) {

      this.lastPage = this.gridApi.paginationIsLastPageFound();

      this.currentPage = this.gridApi.paginationGetCurrentPage() + 1;

      this.nextPage = this.gridApi.paginationGetCurrentPage() + 1 + 1;

      this.nextPagePlusOne = this.gridApi.paginationGetCurrentPage() + 1 + 1 + 1;

      this.totalPages = this.gridApi.paginationGetTotalPages();

    }

  }

  //this function is for going to the given page number
  onSearchChange(searchValue: number) {

    this.gridApi.paginationGoToPage(searchValue - 1);

  }

  // below function is for changing the no of rows per page
  onPageSizeChanged(newPageSize) {

    var value = this.pageSize;

    this.gridApi.paginationSetPageSize(Number(value));

  }

  // below function is for going to next page
  async onBtNext() {

    /*this.recordsStart = this.recordsStart + 100;

    this.retrieveObject = await this.trnxCommonTs.retrieveTrnx(this.tableName, this.listObjectDropDown, this.ngModelObject, this.recordsStart);

    console.log(this.retrieveObject);

    if (this.retrieveObject.length == 0) {

      this.recordsStart = this.recordsStart - 100;

    }

    else {

      this.gridApi.setRowData(this.retrieveObject);

    }*/

    this.gridApi.paginationGoToNextPage();

  }

  // below function for going to previous page
  async onBtPrevious() {

    /*if (this.recordsStart > 0) {

      this.recordsStart = this.recordsStart - 100;

      this.retrieveObject = await this.trnxCommonTs.retrieveTrnx(this.tableName, this.listObjectDropDown, this.ngModelObject, this.recordsStart);

      console.log(this.retrieveObject);

      this.gridApi.setRowData(this.retrieveObject);

    }*/

    this.gridApi.paginationGoToPreviousPage();

  }

  // below function for going to first page
  onBtFirst() {

    this.gridApi.paginationGoToFirstPage();

  }

  // below function for going to last page
  onBtLast() {

    this.gridApi.paginationGoToLastPage();

  }

  // below function is to load the ag grid
  onGridReady(params) {
    this.temprunHistory = params
    console.log(params)
    console.log("service")
    let res: any
    this.grid = params;
    this.gridApi = params.api;
    if (this.testCycleMstrId) {

      this.testExecutionService.retrieveTestExecutions(this.testCycleMstrId).subscribe(response => {
        if (response.error == true) {

          this.spinner.stop();

          this.snackbar.open(response.statusMessage, "OK", { duration: 5000 });


        } else {
          this.spinner.stop();
          this.gridApi.setRowData(response)
          console.log(response)
        }
      })
    } else {
      this.gridApi.setRowData([])
    }




  }


  remove(e) {
  }
  onGridReadyForTools(params) {
    console.log(params)
    console.log("service")
    let res: any
    this.testScriptgrid = params;
    this.testScriptGridApi = params.api;
    this.testScriptGridColumnApi = params.columnApi;
    this.testScriptGridApi.setRowData([])
  }

  updateCheckbox(event) {

  }
  attachment() {

  }

  testResultPath
  testResult(e) {
    console.log(e.rowData)
    this.testResultPath = e.rowData.testResult
    if (this.testResultPath === undefined || this.testResultPath === "" || this.testResultPath === null) {
      this.snackbar.open(this.constants.fileNotFound, "OK", { duration: 5000 });

    } else {
      this.spinner.start();
      let fileName = this.testResultPath.split('/').pop()
      let data = {
        s3Path: this.testResultPath,
      };
      this.service.downloadFileFromS3(data).subscribe(x => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should

        console.log(x)
        const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
        const responseData = isJsonBlob(x)
        if (responseData) {
          this.snackbar.open(this.constants.fileNotFound, "OK", { duration: 5000 });

        } else {
          console.log(x)
          var newBlob = new Blob([x]);
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //   window.navigator.msSaveOrOpenBlob(newBlob);
          //   return;
          // }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          console.log(data)

          var link = document.createElement('a');
          link.href = data;
          link.download = fileName;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);

        }




      })

      this.spinner.stop();
    }

  }
  createCycle() {
    this.router.navigateByUrl('/user/Menubar', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/user/Menubar/trnxBaseComponent"], { queryParams: { screenType: 'Dynamic%2FTransactions', tableName: "testcyclemstr", headerNameHtml: 'Test Cycle' } }));


  }
  selectCycle(value) {

  }
  edit(e) {
    let width = "80%";

    let dialogRef = this.dialog.open(TrnxBasePopupScreenComponent, {
      maxWidth: '100vw',
      height: "95%",
      width: width,
      data: { rowData: e.rowData },
      disableClose: true
    });
  }
  uploadexcelfile(inputValue) {
    console.log(inputValue);
    console.log(inputValue.event.target)
    const file: File = inputValue.event.target.files[0];
    // var FileS: File = inputValue.event.target.files[0];
    if (file && file.name) {
      const extension = file.name.split('.').pop().toLowerCase();
      if (extension === 'xls' || extension === 'xlsx') {
        console.log(this.getSelectedRows)
        var scriptId = this.getSelectedRows[0].manageTestScriptsMstrId
        console.log(file.type);
        const formData = new FormData();
        formData.append('file', file)
        formData.append('scriptId', scriptId)
        // const scriptId = new DropdownComponent();

        console.log(scriptId)
        console.log(formData)
        this.uploadexcelfileService(formData);
      } else {
        // Show error message for invalid file format
        this.snackbar.open("Please upload a valid Excel file", "OK", { duration: 5000 });
      }
    } else {
      // Show error message if file is not found or doesn't have a name
      this.snackbar.open("File not found or invalid", "OK", { duration: 5000 });
    }


  }
  async uploadexcelfileService(formData) {

    this.service.uploadingExcelFile(formData, this.localStorageSettings.getSettings("dnsName")).subscribe(
      () => {
        // Handle success case here if needed
        this.spinner.stop();
        this.snackbar.open("File uploaded successfully", "OK", { duration: 5000 });
      },
      (error) => {
        // Extract response body from error object
        const responseBody = error.error?.text;

        // Check if response body contains "Successfully"
        if (responseBody && responseBody.includes("Succesfully file uploded")) {
          this.spinner.stop();
          this.snackbar.open("File uploaded successfully", "OK", { duration: 5000 });
        } else {
          this.spinner.stop();
          this.snackbar.open("Error uploading file", "OK", { duration: 5000 });
        }
      }
    );


  }

  async delete(e) {


    if (confirm("Are you sure you want to delete the record ?") == true) {

      (await this.service.deleteRecordPermanent("testexecutiontrxn", "testexecutiontrxnid", e.rowData.testExecutionTrxnId)).subscribe(response => {
        if (response.error == true) {

          this.spinner.stop();

          this.snackbar.open(response.statusMessage, "OK", { duration: 5000 });

        } else {

          this.spinner.stop();
          this.onGridReady(this.grid)
          this.snackbar.open(response.statusMessage, "OK", { duration: 50000 });
        }
      })
    }
  }


  runScript(e) {

    if (this.testExecutionData.length == 0) {
      this.snackbar.open('Please Select Test Scripts.', "OK", { duration: 5000 });

    } else if (this.testExecutionData.some(obj => obj.fileversion == undefined || obj.fileversion == "Select Version")) {
      if (this.documentStorageVault.toLowerCase() === 'true') {
        this.snackbar.open('Please select version', "OK", { duration: 5000 });
      }
      else {
        this.spinner.start()
        this.testExecutionService.insertUpdateTestExecution(this.testExecutionData).subscribe(response => {
          console.log(response);
          if (response.error == true) {

            this.spinner.stop();

            this.snackbar.open(response.statusMessage, "OK", { duration: 5000 });


          } else {
            this.spinner.stop();
            this.snackbar.open(response.statusMessage, "OK", { duration: 5000 });
            this.onGridReady(this.grid)

          }
        })
      }
    }

    else {
      this.spinner.start()
      this.testExecutionService.insertUpdateTestExecution(this.testExecutionData).subscribe(response => {
        console.log(response);
        if (response.error == true) {

          this.spinner.stop();

          this.snackbar.open(response.statusMessage, "OK", { duration: 5000 });


        } else {
          this.spinner.stop();
          this.snackbar.open(response.statusMessage, "OK", { duration: 5000 });
          this.onGridReady(this.grid)

        }
      })
    }

  }

  testExecutionData = []
  getSelectedRows
  temp
  onRowSelected($event: RowSelectedEvent<any>) {
    let dnsId = localStorage.getItem('dnsName');
    this.testExecutionData = []
    this.temp = [];
    this.getSelectedRows = this.testScriptGridApi.getSelectedRows();
    console.log(this.getSelectedRows);
    // For single/multiple object re-extraction
    for (let i = 0; i < this.getSelectedRows.length; i++) {
      console.log(this.getSelectedRows[i].objectName);
      console.log(this.getSelectedRows[i])
      let obj = {
        testExecutionTrxnId: this.getSelectedRows[i].testExecutionTrxnId,
        testManagmentScriptId: this.getSelectedRows[i].manageTestScriptsMstrId,
        testExecutionMstrId: this.getSelectedRows[i].testExecutionMstrId,
        testCycleMstrId: this.getSelectedRows[i].testCycleMstrId,
        execStatus: "Not Started",
        scriptName: this.getSelectedRows[i].scriptName,
        scriptcount: this.getSelectedRows[i].scriptcount,
        totalTestSteps: this.getSelectedRows[i].stepCount,
        stepsPassed: this.getSelectedRows[i].stepsPassed,
        stepsFailed: this.getSelectedRows[i].stepsFailed,
        pass: this.getSelectedRows[i].pass,
        dnsId: dnsId,
        testResult: this.getSelectedRows[i].testResult,
        startDate: this.getSelectedRows[i].startDate,
        endDate: this.getSelectedRows[i].endDate,
        fileversion: this.getSelectedRows[i].version
      }

      this.testExecutionData.push(obj);
      // this.selectedVaultObjects = this.libraryobjectData[i].objectName;
      console.log(this.testExecutionData);
    }

  }

  refreshRun() {
    this.spinner.start();
    this.onGridReady(this.temprunHistory);
    this.spinner.stop();
  }

  versionDropdown() {
    console.log("inside this.versionDropdown.bind(this)")
  }

  onDropdownValueChange(rowData: any, selectedValue: any) {
    // Update your data source (rowData) with the selected value
    rowData.selectedVersion = selectedValue;
    console.log(rowData)
    console.log(selectedValue)
    this.spinner.start();
    for (let i = 0; i < this.testExecutionData.length; i++) {
      if (rowData.manageTestScriptsMstrId == this.testExecutionData[i].testManagmentScriptId) {
        this.testExecutionData[i].fileversion = selectedValue;
        this.spinner.stop();
        console.log(this.testExecutionData[i]);
        break;
      }
    }
    console.log(this.testExecutionData)
  }

}

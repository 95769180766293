import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-build',
  template: `
  <button title="Edit" (click)="edit($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem" [disabled]="!hasPermission(128)">
    <i class="fas fa-pencil-alt"></i>
  </button>
  <button title="Delete" (click)="delete($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light"  style="padding: .3rem .8rem;margin-top:0rem;background:#d71920" [disabled]="!hasPermission(129)">
    <i class="far fa-trash-alt"></i>
  </button>
  <button title="Build" (click)="build($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem; margin-top: 0rem;" [disabled]="!hasPermission(126)">
  <i class="fas fa-tools"></i>
</button>`
})

export class BuildButton implements ICellRendererAngularComp {

  params;
  label: string;
  permissions: any[] = [];

  agInit(params): void {
    console.log(params);
    this.params = params;
    console.log(this.params);
    this.label = this.params.label || null;
    this.permissions = this.params.values || [];
  }

  refresh(params?: any): boolean {
    return true;
  }

  hasPermission(buttonId: number): boolean {
    // Check if 'screen' exists in the permissions array
    const hasScreen = this.permissions.some(permission =>
        typeof permission === 'string' && permission.includes('screen')
    );

    // If 'screen' is not present, enable all buttons
    if (!hasScreen) {
        return true;
    }

    // Otherwise, check if the specific buttonId is in the permissions list
    return this.permissions.includes(buttonId);
   }

  edit($event) {
    if (this.params.edit instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.edit(params);

    }
  }
  delete($event) {
    if (this.params.delete instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.delete(params);

    }
  }
  build($event) {
    if (this.params.build instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.build(params);

    }
  }
}
import { Component, OnInit, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { validationmethod } from "src/app/shared/common/validationmethod";
import { TrnxCommonTs } from "src/app/shared/common/trnxCommonTs";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { constants } from "src/app/shared/common/contants";
import { CommonService } from "src/app/shared/services/common.service";
import * as moment from "moment";
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageSettingsService } from 'src/app/shared/services/local-storage-settings.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { CronJobIntervalComponent } from '../cron-job-interval/cron-job-interval.component';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { TrnxCommonService } from 'src/app/shared/services/trnx-common.service';
import * as XLSX from 'xlsx';



export interface FormData {
  ngModelObjectDivision: any;
  ngModelObject: any; 
  tableName: any;
  dynamicData: any;
  retrieveObject: any;
  buttonName: any;
  listObjectDropDown: any;
  bootstrapColumn: any;
  tableID: any;
  headerNameHtml: any;
  trnxScreenId: any;
  update: any;
  screenTypeTOrM: any;
  childKey: any;
  masterTableId: any;
  refreshHistory:any;
 
}
@Component({
  selector: 'app-trnx-base-popup-screen',
  templateUrl: './trnx-base-popup-screen.component.html',
  styleUrls: ['./trnx-base-popup-screen.component.scss']
})
export class TrnxBasePopupScreenComponent implements OnInit {

  formValid: UntypedFormGroup;

  formChildValid: UntypedFormGroup;

  ngModelObjectDivision = [];

  temporaryChildObject = [];

  ngModelObject = [];

  ngModelChildObject = [];

  eachDetailsData: any = [];

  bindingDetailsData: any = [];

  tableName = "";

  headerNameHtml = "";

  trnxScreenId;

  dynamicData = [];

  childDynamicData = [];

  retrieveObject: any = [];

  buttonName;

  buttonNameClose;

  hide = false;

  nextButton = true;

  updateButton = false;

  parentUpdate;
  childUpdate;

  eyeIcon = true;

  listObjectDropDown: any = [];

  listChildObjectDropDown: any = [];

  listObjectDropDownDuplicate: any = [];

  bootstrapColumn = 0;

  detailsDataCount = 0;

  allocateButtonsBoolean = false;

  allocateButtons1Boolean = false;

  closeIconButtonBoolean = false;

  closeIconButton1Boolean = false;

  ngModelChildObjectDivision = []

  tableID = "";
  keyValues="";

  detailValue = "";

  numberOfChildrenDropDowns = 0;

  childrenDropDownsIncrement = 0;

  parentScreen = true;
  childScreen = false;

  screenTypeTOrM = "";

  nextSaveTOrM = "";

  childKey;

  masterTableId = "";

  formValidCheckbox: UntypedFormGroup;

  isCheckboxSelected: boolean = false;

  backgroundColorButton = "";
  refreshHistory:any;
  systemName ="";
  companyId:any = "";
  externalObjectFile :any;
  externalObjectFileName=false;
  attributeJsonFromFile=[];
  readonlyObjectName=false;
 



  constructor(private dialogref: MatDialogRef<TrnxBasePopupScreenComponent>,

    @Inject(MAT_DIALOG_DATA) public data: FormData,
    
    private validation: validationmethod,
    private trnxCommonTs: TrnxCommonTs,
    private localStorageSettings: LocalStorageSettingsService,
    private constants: constants,
    private service: CommonService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private auth: LoginService,
    private dialog: MatDialog,
    private spinner: NgxUiLoaderService,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private loginService: LoginService,
    private trnxCommonservice: TrnxCommonService,
    private ngZone: NgZone) {

  }

  ngOnInit() {
   

    this.formValidationsCheckbox();

    console.log(this.data);
    

    if (this.data.headerNameHtml == "Objects" && this.data.buttonName == "Update") {
      for(let a=0;a<this.data.ngModelObject.length;a++){
        console.log(this.data.ngModelObject[a])
        if (this.data.ngModelObject[a].formName === "Object Type") {
          console.log(this.data.ngModelObject[a]);
          console.log(this.data.listObjectDropDown[0][this.data.ngModelObject[a].dropDownIndex].lookupvalue)
          if ((this.data.listObjectDropDown[0][this.data.ngModelObject[a].dropDownIndex].lookupvalue) == "External Objects") {
            this.readonlyObjectName = true;
          }
        //  this.externalObjectFileName=true;
          console.log(this.externalObjectFileName);
          this.listObjectDropDown = this.data.listObjectDropDown;
          console.log(this.data.ngModelObjectDivision[2][0].value)
         // this.data.ngModelObjectDivision[2][0].value="External Objects";
         // console.log(this.data.ngModelObjectDivision[2][0].value)
          console.log(this.data)


        }
          
       }

       for(let j=0;j<this.data.ngModelObjectDivision[2].length;j++){

       }

     }
  
    this.tableName = this.data.tableName;

    this.ngModelObjectDivision = this.data.ngModelObjectDivision;
    for (let i = 0; i < this.ngModelObjectDivision.length; i++) {
      console.log(this.ngModelObjectDivision[i])
      

      for(let a=0;a<this.ngModelObjectDivision[i].length;a++){
        
        if(this.ngModelObjectDivision[i][a].value == "External Objects" ){
          if(this.data.buttonName == "Update"){
            this.ngModelObjectDivision[i][a].keyValue = "External Objects";
            console.log(this.ngModelObjectDivision[i][a])
          }else{
            this.ngModelObjectDivision[i][a].value="";
            console.log(this.ngModelObjectDivision[i])
          }
       
      }

      }
      if(this.data.buttonName ==="Update"){
        console.log(this.ngModelObjectDivision[i])
        if(this.ngModelObjectDivision[i].length>=1){
          let fieldName = this.ngModelObjectDivision[i][0].fieldName;
          if(this.tableName==="vaultconfigmstr" && fieldName === "systemname" ){
           let lookupid = this.ngModelObjectDivision[i][0].keyValue
          this.loginService.getSystemNamebyid(lookupid).subscribe(resp=>{
            console.log(resp);  
            if(resp['data'].toLowerCase()==="vault"){
              this.systemName = "Vault";
              this.cdr.detectChanges();
            }
          })
        
          }
        }

      
        
    


       
      }
      if(this.tableName==="vaultconfigmstr" && this.ngModelObjectDivision[i].fieldName === "sessionexpirationtime" ){
        this.ngModelObjectDivision[i].keyValue = 0;    
      }

    }

    this.ngModelObject = this.data.ngModelObject;
    console.log(this.data);

   
    console.log(this.tableName)

    this.trnxScreenId = this.data.trnxScreenId;

    console.log(this.trnxScreenId)

    this.headerNameHtml = this.data.headerNameHtml;

    this.dynamicData = this.data.dynamicData;

    console.log(this.dynamicData)

    this.retrieveObject = this.data.retrieveObject;

    this.buttonName = this.data.buttonName;

    console.log(this.buttonName);
    console.log(this.data.listObjectDropDown)

    this.listObjectDropDown = this.data.listObjectDropDown;

    this.data.listObjectDropDown.forEach(listObjectDropDownTemp => {

      let listTemporary: any = [];

      listObjectDropDownTemp.forEach(listObjectDropDownTemporary => {

        listTemporary.push(Object.assign({}, listObjectDropDownTemporary));

      }
      );
     console.log(listTemporary);

      this.listObjectDropDownDuplicate.push(listTemporary);

    });

    console.log(this.listObjectDropDown);

    let dropDownChildFieldNameArray = [];

    let keyNames = [];


    let indexArray = [];

    this.tableID = this.data.tableID;
    



    this.ngModelObject.forEach(ngModelObjectTemp => {

      if (ngModelObjectTemp.dropDownChildFieldName != undefined) {

        //Getting children dropdown names
        console.log(ngModelObjectTemp.dropDownChildFieldName);
        // console.log("+++++++++++++++++++++++++++++++++++++++++")
        dropDownChildFieldNameArray.push(ngModelObjectTemp.dropDownChildFieldName);

      }

      //Storing keyNames
      keyNames.push(ngModelObjectTemp.keyName);
      if(this.tableID === ngModelObjectTemp.keyName){
        this.keyValues = ngModelObjectTemp.keyValue;
        console.log(this.keyValues)
      }

      //Storing dropdown index
      indexArray.push(ngModelObjectTemp.dropDownIndex);

    });

    console.log(dropDownChildFieldNameArray);

    this.numberOfChildrenDropDowns = dropDownChildFieldNameArray.length;

    console.log(keyNames);

    console.log(indexArray);

    dropDownChildFieldNameArray.forEach(dropDownChildFieldNameArrayTemp => {

      //Getting index of dropDownChildFieldNameArrayTemp
      let index = keyNames.indexOf(dropDownChildFieldNameArrayTemp);

      //Getting child dropDown index
      let childDropDownIndex = indexArray[index];

      console.log(childDropDownIndex);

      //Doing empty array to listObjectDropDown at childDropDownIndex
      this.listObjectDropDown[childDropDownIndex] = [];

    });

    if (this.buttonName == "Save") {

      this.buttonNameClose = "Save";

    }

    else {

      this.buttonNameClose = "Update";

      this.hide = false;

      this.eyeIcon = false;
      if (this.data.update == "parentTrue") {
        this.nextButton = false;
        this.updateButton = true;
        this.parentUpdate = "true";
      } else {
        this.nextButton = false;
        this.updateButton = true;
        this.parentUpdate = "false";
      }


    }

    this.childKey = this.data.childKey;

    //It is storing transaction master primary key name.
    this.masterTableId = this.data.masterTableId;

    this.bootstrapColumn = this.data.bootstrapColumn;

    console.log(this.data.screenTypeTOrM);

    this.screenTypeTOrM = this.data.screenTypeTOrM;

    if (this.bootstrapColumn == 1) {

      this.allocateButtons1Boolean = true;

      this.closeIconButton1Boolean = true;

    }

    else {

      this.allocateButtonsBoolean = true;

      this.closeIconButtonBoolean = true;

    }

    console.log(this.screenTypeTOrM);

    //To different master and transaction screens.
    if (this.screenTypeTOrM == "Transaction") {

      this.nextSaveTOrM = "Next";

    }

    else {

      this.nextSaveTOrM = "Save";

    }

    if(this.tableName==="vaultconfigmstr"){
      this.dynamicData.forEach(e =>{
        if(e.fieldName ==="sessionexpirationtime"){
          e.mandatory = false;
         }
         else if(e.fieldName ==="vaultversion") {
          e.mandatory = false;
         }
         else if(e.fieldName ==="burstlimitthreshold") {
          e.mandatory = false;
         }
         else if(e.fieldName ==="burstlimitsleeptime") {
          e.mandatory = false;
         }
      })
    }
    // form validations
    this.formValidations();

    this.backgroundColorButton = this.localStorageSettings.getSettings("ColorA");

    console.log(this.backgroundColorButton);


    console.log(this.ngModelObject);

console.log(this.listObjectDropDown);

   

  }

  formValidations() {

    this.formValid = this.trnxCommonTs.formValidations(this.dynamicData);
    console.log("+++++++++++++++++++++++++++++++++++++++++++++");

    console.log(this.formValid);

  }

  childFormValidations() {
    this.formChildValid = this.trnxCommonTs.childFormValidations(this.childDynamicData);
  }

  radiochange(e) {
    console.log(e.value)

  }

  childRadiochange(e) {
    console.log(e.value)
  }

  //Insert or update data to the server.
  /*async sendData() {

    let isExecute: any = 0;

    console.log(this.ngModelObject);

    isExecute = this.trnxCommonTs.checkingCombinationColumnsUnique(this.ngModelObject, this.retrieveObject, isExecute);

    console.log(isExecute);

    let temporaryObject: any = [];

    temporaryObject = this.trnxCommonTs.ngModelData(this.ngModelObject);

    if (this.buttonName == this.constants.btnname) {

      // checking the form is valid or not while insert
      if (!this.formValid.valid) {

        // displaying validation messages

        this.validation.validateAllFormFields(this.formValid);

        this.validation.getvalidationmessage();

      }

      else {

        await this.trnxCommonTs.insertTrnxData(temporaryObject, this.tableName, isExecute, this.ngModelObject, this.tableID, this.temporaryChildObject, this.screenTypeTOrM, this.childKey, this.masterTableId);

        this.reset();

      }

    }

    else {

      // checking the form is valid or not while update
      if (!this.formValid.valid) {

        // displaying validation messages
        this.validation.validateAllFormFields(this.formValid);

        this.validation.getvalidationmessage();

      }

      else {

        await this.trnxCommonTs.updateTrnxData(temporaryObject, this.tableName, isExecute, this.ngModelObject, this.tableID);

        this.reset();

      }

    }

  }*/

  reset() {

    this.ngModelObject.forEach(inputObject => {

      inputObject.keyValue = "";

      inputObject.imageShow = false;

      inputObject.imageshow1 = false;

      inputObject.fileShow = false;

      inputObject.startDateValue = moment(null);

      inputObject.endDateValue = moment(null);

      inputObject.startDateTimeValue = moment(null);

      inputObject.endDateTimeValue = moment(null);

      inputObject.binaryFormat = false;

      inputObject.downloadIconShow = false;

    });



    console.log(this.ngModelObject);

    this.formValid.reset();

    this.formValidations();

    this.ngModelObject.forEach(inputObject => {

      if (inputObject.fieldType == "DatePicker" && inputObject.maximumDate != undefined) {

        inputObject.endDateValue = inputObject.maximumDate;

        console.log(inputObject.endDateValue);

      }

      if (inputObject.fieldType == "DateTimePicker" && inputObject.maximumDateTime != undefined) {

        inputObject.endDateTimeValue = inputObject.maximumDateTime;

        console.log(inputObject.endDateTimeValue);

      }

    });

    this.buttonName = this.constants.btnname;

    this.buttonNameClose = "Save";

    this.eyeIcon = true;

  }

  resetChildData() {

    this.formChildValid.reset();
    this.ngModelChildObject.forEach(inputChildObject => {

      inputChildObject.keyValue = "";

      inputChildObject.imageShow = false;

      inputChildObject.imageshow1 = false;

      inputChildObject.fileShow = false;

      inputChildObject.startDateValue = moment(null);

      inputChildObject.endDateValue = moment(null);

      inputChildObject.startDateTimeValue = moment(null);

      inputChildObject.endDateTimeValue = moment(null);

      inputChildObject.binaryFormat = false;

      inputChildObject.downloadIconShow = false;

    });
    this.ngModelChildObject.forEach(inputObject => {

      if (inputObject.fieldType == "DatePicker" && inputObject.maximumDate != undefined) {

        inputObject.endDateValue = inputObject.maximumDate;

        console.log(inputObject.endDateValue);

      }

      if (inputObject.fieldType == "DateTimePicker" && inputObject.maximumDateTime != undefined) {

        inputObject.endDateTimeValue = inputObject.maximumDateTime;

        console.log(inputObject.endDateTimeValue);

      }

    });
  }

  resetChild() {
    this.childFormValidations();
    this.formChildValid.reset();
    this.ngModelChildObject.forEach(inputChildObject => {

      inputChildObject.keyValue = "";

      inputChildObject.imageShow = false;

      inputChildObject.imageshow1 = false;

      inputChildObject.fileShow = false;

      inputChildObject.startDateValue = moment(null);

      inputChildObject.endDateValue = moment(null);

      inputChildObject.startDateTimeValue = moment(null);

      inputChildObject.endDateTimeValue = moment(null);

      inputChildObject.binaryFormat = false;

      inputChildObject.downloadIconShow = false;

    });
    this.ngModelChildObject.forEach(inputObject => {

      if (inputObject.fieldType == "DatePicker" && inputObject.maximumDate != undefined) {

        inputObject.endDateValue = inputObject.maximumDate;

        console.log(inputObject.endDateValue);

      }

      if (inputObject.fieldType == "DateTimePicker" && inputObject.maximumDateTime != undefined) {

        inputObject.endDateTimeValue = inputObject.maximumDateTime;

        console.log(inputObject.endDateTimeValue);

      }

    });

  }

  // event on input type file
  imageFunction($event, ngModelTemporary): void {

    console.log($event);

    console.log(ngModelTemporary);

    this.readThisimage($event.target, ngModelTemporary);

  }
  imageChildFunction($event, ngModelChildTemporary): void {

    console.log($event);

    console.log(ngModelChildTemporary);

    this.readThisChildimage($event.target, ngModelChildTemporary);

  }

  // to encode the selected photo
  readThisimage(inputValue: any, ngModelTemporary): void {

    console.log(inputValue);

    console.log(ngModelTemporary);

    this.trnxCommonTs.readThisImage(inputValue, ngModelTemporary, this.ngModelObject, this.formValid);

  }

  // to encode the selected photo
  readThisChildimage(inputValue: any, ngModelChildTemporary): void {

    console.log(inputValue);

    console.log(ngModelChildTemporary);

    this.trnxCommonTs.readThisChildImage(inputValue, ngModelChildTemporary, this.ngModelChildObject, this.formChildValid);

  }

  openImage(image) {

    this.trnxCommonTs.openImage(image);

  }

  //delete attachements
  deleteImage(ngModelTemporary) {

    console.log(ngModelTemporary);

    this.trnxCommonTs.trnxDeleteImage(ngModelTemporary, this.formValid, this.ngModelObject);

  }

  deleteChildImage(ngModelTemporary) {

    this.trnxCommonTs.trnxDeleteImage(ngModelTemporary, this.formChildValid, this.ngModelChildObject);

  }

  close() {
    if (this.parentUpdate == "true") {
      this.reset();
    } else if (this.parentUpdate == "false") {
      this.resetChild();
    } else {
      this.reset();
      this.resetChild();
    }

    console.log(this.isCheckboxSelected);

    console.log(typeof this.isCheckboxSelected);

    //It will execute if checkbox is not selected.
    if (this.isCheckboxSelected == false) {

      //It will reset checkbox form.
      this.formValidCheckbox.reset();

      //It will call checkbox formValidationCheckbox method.
      this.formValidationsCheckbox();

      this.data.listObjectDropDown = [];

      this.temporaryChildObject = [];

      this.listObjectDropDownDuplicate.forEach(listObjectTemp => {

        this.data.listObjectDropDown.push(listObjectTemp);

      });

      console.log(this.data.listObjectDropDown);

      console.log(this.listObjectDropDownDuplicate);

      this.dialogref.close(this.data.listObjectDropDown);

    }

  }


 

  //Insert or update data to the server.
  async sendDataAndClose() {
    

    //Getting the username from localStorage.
    let userName = this.localStorageSettings.getSettings("AuthUsername");
  
    console.log(this.listObjectDropDown);
    
    console.log(this.ngModelObject);
   
    console.log(this.headerNameHtml)
    var keyvalueTenantName;
    var keyvalueSourceId;
    var keyvalueTargetId;
    if (this.headerNameHtml === "Tenant Management") {
      for (let i=0; i<this.ngModelObject.length; i++) {
          if (this.ngModelObject[i].keyName === "tenantname") {
            keyvalueTenantName = this.ngModelObject[i].keyValue;
            console.log(keyvalueTenantName)
            if (/[^a-z0-9\s]|[^\w\s]|[\s]/.test(keyvalueTenantName)) {
              this.snackBar.open("Tenant Name should not contain any special characters", "OK", { duration: 5000 });
              return
            }
          } else if (this.ngModelObject[i].keyName === "bucketname") {
            keyvalueTenantName = this.ngModelObject[i].keyValue;
            console.log(keyvalueTenantName)
            if (/[^a-z0-9\s-]|[\s]/.test(keyvalueTenantName)) {
              this.snackBar.open("Bucket Name should not contain any special characters", "OK", { duration: 5000 });
              return
            }
          } else if (this.ngModelObject[i].keyName === "vaultconfigmstridsource") {
              keyvalueSourceId = this.ngModelObject[i].keyValue;
              console.log(keyvalueSourceId)
          } else if (this.ngModelObject[i].keyName === "vaultconfigmstridtarget") {
              keyvalueTargetId = this.ngModelObject[i].keyValue;
              console.log(keyvalueTargetId)
              if (keyvalueSourceId === keyvalueTargetId) {
                this.snackBar.open("Source and Target value should not be same", "OK", { duration: 5000 });
                return
              }
          }
        }
    }

    //Getting the user record from users table using userName.
    let userObject: any = await this.service.retrieveSingleRecordService("users", "userName", userName);

    //console.log(userObject);

    let isExecute: any = 0;

    isExecute = this.trnxCommonTs.checkingCombinationColumnsUnique(this.ngModelObject, this.retrieveObject, isExecute);

    //console.log(isExecute);

    let approvedindex: any;

    let mmapprovalindex: any;

    let useridtemp: any;

    //console.log(this.ngModelObject)

    //Iterating ngModelObject object for storing value in tenantid.
    for (let i = 0; i < this.ngModelObject.length; i++) {
      console.log(this.ngModelObject)
         if(this.ngModelObject[i].fieldName==="sessionexpirationtime" && this.systemName!=="Vault"){
          this.ngModelObject[i].keyValue = 0;
         }
        //  else if(this.ngModelObject[i].fieldName==="burstlimitsleeptime" && this.systemName!=="vault"){
        //   this.ngModelObject[i].keyValue = 60;
        //  }else if(this.ngModelObject[i].fieldName==="vaultversion" && this.systemName!=="vault"){
        //   this.ngModelObject[i].keyValue = 20.1;
        //  }else if(this.ngModelObject[i].fieldName==="burstlimitsleeptime" && this.systemName!=="vault"){
        //   this.ngModelObject[i].keyValue = 60;
        //  }
      if (this.ngModelObject[i].keyName == "approvedby") {
        if (this.ngModelObject[i].fieldType == "Checkbox") {
          //console.log(this.ngModelObject[i].keyValue);
          useridtemp = i;
          if (this.ngModelObject[i].keyValue == true) {
            //Getting the tenantid from first json.
            //console.log(useridtemp)
            let userIdValue = userObject[0].userid;

            //console.log(userIdValue);

            //Storing the tenantid value in keyValue where keyName is tenantid.
            this.ngModelObject[i].keyValue = userIdValue;

            console.log(this.ngModelObject[i].keyValue);
          } else if (this.ngModelObject[i].keyValue == false) {
            this.ngModelObject[i].keyValue = "0";
          }
        }
      }
      if (this.ngModelObject[i].keyName == "approveddatetime") {

        //console.log(this.ngModelObject[i].keyValue);

        approvedindex = i;

      }
      if (this.ngModelObject[i].keyName == "mmapproval") {

        mmapprovalindex = i;

      }

    }

    if (useridtemp != undefined) {
      //console.log(useridtemp)
      if (this.ngModelObject[useridtemp].keyValue != 0) {
        const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
        let datetimevalue = moment(new Date(), DATE_TIME_FORMAT);

        //Storing the tenantid value in keyValue where keyName is tenantid.
        this.ngModelObject[approvedindex].keyValue = datetimevalue;
        this.ngModelObject[mmapprovalindex].keyValue = "Yes";
      } else {
        this.ngModelObject[approvedindex].keyValue = null;
        this.ngModelObject[mmapprovalindex].keyValue = "No";
      }

    }

    let isexist = "";
    let temporaryObject: any = [];

    temporaryObject = this.trnxCommonTs.ngModelData(this.ngModelObject);

    console.log(temporaryObject);

    if (this.buttonNameClose == "Save") {

      // checking the form is valid or not while insert
      if (!this.formValid.valid) {

        // displaying validation messages

        this.validation.validateAllFormFields(this.formValid);

        this.validation.getvalidationmessage();

      }

      else {
        console.log(temporaryObject)
        for (let i = 0; i < temporaryObject.length; i++) {
          console.log(temporaryObject[i].keyName)
          if (temporaryObject[i].keyName == "materialid") {
            console.log(temporaryObject[i].keyValue)
            console.log(this.retrieveObject)
            for (let j = 0; j < this.retrieveObject.length; j++) {
              console.log(this.retrieveObject[j].materialid)
              if (this.retrieveObject[j].materialid === temporaryObject[i].keyValue) {
                console.log("entered")
                isexist = "true";
                this.snackBar.open("Material already inserted", "OK", { duration: 5000 });
              }
            }
          }
        }
        console.log(temporaryObject);

        console.log(this.tableName);

        console.log(isExecute);

        console.log(this.ngModelObject);

        console.log(this.tableID);

        console.log(this.temporaryChildObject);

        console.log(this.screenTypeTOrM);

        console.log(this.masterTableId);

        this.passwordEncrpt(temporaryObject);

        let response;

        response = await this.checkingIsExistInsert(isexist, temporaryObject, isExecute);

        await this.getResponse(response);

      }

    }

    else {
      // checking the form is valid or not while update
      if (!this.formValid.valid) {
        console.log("djhdfhsf")
        console.log("Form Validity in IF: ", this.formValid.valid);
        console.log("Form Controls in IF: ", this.formValid.controls);
        // displaying validation messages
        console.log(this.formValid)
        
        this.validation.validateAllFormFields(this.formValid);
        

        this.validation.getvalidationmessage();
        this.spinner.stop()
      }

      else {
        console.log("Form Validity in else: ", this.formValid.valid);
        console.log("Form Controls in else: ", this.formValid.controls);
        console.log(temporaryObject)

        this.passwordEncrpt(temporaryObject);

        let response: boolean;

        response = await this.checkingIsExistUpdate(isexist, temporaryObject, isExecute);

        await this.getResponse(response);

        console.log(response)

        if(!response && this.externalObjectFileName){
          const formData = new FormData();
          console.log(this.externalObjectFile)
          formData.append('file', this.externalObjectFile)
          let tenantId=localStorage.getItem('dnsName');
     
          //formData.append('s3Path', "Migration/Vault_Objects")
      console.log(formData)
      console.log(formData.get)
  
       
         this.service.uploadExternalFileToS3(tenantId, formData).subscribe(res => {
          console.log(res);
          if (res.error === true) {
            //this.spinner.stop();
            this.snackbar.open(res.statusMessage, "OK", { duration: 5000 });
          } else {
            
          //  this.snackbar.open(res.statusMessage, "OK", { duration: 5000 });
          }
        })
  
  
        }


      }

    }

  }

  changeStartEndDateValue(ngModelTemporary) {

    this.trnxCommonTs.changeStartEndDateValue(ngModelTemporary, this.ngModelObject);

  }

  changeStartEndDateTimeValue(ngModelTemporary) {

    this.trnxCommonTs.changeStartEndDateTimeValue(ngModelTemporary, this.ngModelObject);

  }

  async next(value) {

let flag=true;

    if(this.externalObjectFileName){
      for(let a=0;a<this.ngModelObject.length;a++){
       if(this.ngModelObject[a].keyName ==="objectname"){
          console.log(this.ngModelObject[a].keyValue+".csv")
          console.log(this.externalObjectFile.name)
          if((this.ngModelObject[a].keyValue+".csv") != this.externalObjectFile.name ){
            flag=false;
            this.snackbar.open("File name should be same as object name", "OK", { duration: 5000 });
          }
          console.log(this.ngModelObject[a]);
        }
       }
    } 
if(flag){ 
    // checking the form is valid or not while insert
    if (!this.formValid.valid) {

      // displaying validation messages

      this.validation.validateAllFormFields(this.formValid);

      this.validation.getvalidationmessage();

    }

    else {

      console.log(this.screenTypeTOrM);

      if (this.screenTypeTOrM == "Transaction") {

        console.log(this.screenTypeTOrM);

        let retrieveChildNgModelCreation: any = [];
        retrieveChildNgModelCreation = await this.trnxCommonTs.retrieveChildTableData(value);
        this.parentScreen = false;
        this.childScreen = true;
        this.ngModelChildObjectDivision = retrieveChildNgModelCreation[2];
        this.ngModelChildObject = retrieveChildNgModelCreation[1];
        this.childDynamicData = retrieveChildNgModelCreation[0];
        console.log(this.ngModelChildObject)
        console.log(this.childDynamicData)
        console.log(this.ngModelChildObjectDivision);
        this.childFormValidations()
        for (let i = 0; i < this.childDynamicData.length; i++) {

          if (this.childDynamicData[i].fieldType == "valueDropDown" || this.childDynamicData[i].fieldType == "valueParentDropDown" || this.childDynamicData[i].fieldType == "Linked Fields") {

            console.log(this.childDynamicData[i].fieldName);

            console.log(this.childDynamicData[i]);

            /*this.dropDowns.push([this.dynamicData[i].fieldName, this.dynamicData[i].dropDownIndex, this.dynamicData[i].agGridDecode, this.dynamicData[i].dropDownIDs, this.dynamicData[i].dropDownValues]);

            console.log(this.dropDowns);*/

            this.listChildObjectDropDown[this.childDynamicData[i].dropDownIndex] = await this.trnxCommonTs.retrieveTrnxDropDown(this.childDynamicData[i].dropDownTableName, /*this.dynamicData[i].dropDownIdNameFilter, this.dynamicData[i].dropDownIdValueFilter,*/ this.childDynamicData[i].dropDownIDs, this.childDynamicData[i].dropDownValues, this.childDynamicData[i].lookupTableName);

            console.log(this.listChildObjectDropDown);

          }
          if (this.childDynamicData[i].fieldType == "Select List - Static") {

            console.log(this.childDynamicData[i].fieldName);

            console.log(this.childDynamicData[i]);

            /*this.dropDowns.push([this.dynamicData[i].fieldName, this.dynamicData[i].dropDownIndex, this.dynamicData[i].agGridDecode, this.dynamicData[i].dropDownIDs, this.dynamicData[i].dropDownValues]);

            console.log(this.dropDowns);*/

            this.listChildObjectDropDown[this.childDynamicData[i].dropDownIndex] = await this.trnxCommonTs.retrieveTrnxDropDown(this.childDynamicData[i].dropDownTableName, /*this.dynamicData[i].dropDownIdNameFilter, this.dynamicData[i].dropDownIdValueFilter,*/ this.childDynamicData[i].dropDownIDs, this.childDynamicData[i].dropDownValues, this.childDynamicData[i].lookupTableName);

            console.log(this.listChildObjectDropDown);

          }

        }

      }

      else {

        this.sendDataAndClose();

      }

    }
  }
  }


  async addData() {
    console.log("Inside add data function")

    if (!this.formChildValid.valid) {

      // displaying validation messages

      this.validation.validateAllFormFields(this.formChildValid);

      this.validation.getvalidationmessage();

    } else {
      // this.eachDetailsData.push(this.ngModelChildObject)
      // console.log(this.eachDetailsData)
      this.bindingDetailsData = [];
      let count = 1;
      this.temporaryChildObject.push(this.trnxCommonTs.ngModelChildData(this.ngModelChildObject));
      console.log(this.temporaryChildObject)
      for (let i = 0; i < this.temporaryChildObject.length; i++) {
        this.eachDetailsData = [];
        for (let j = 0; j < this.temporaryChildObject[i].length; j++) {
          console.log(this.temporaryChildObject[i][j])
          if (this.temporaryChildObject[i][j].fieldType == "Linked Fields" || this.temporaryChildObject[i][j].fieldType == "Select List - Static") {
            if (this.temporaryChildObject[i][j].showForm == true) {
              for (let m = 0; m < this.listChildObjectDropDown.length; m++) {
                // for(let n=0;n<this.listChildObjectDropDown[m].length;n++){
                console.log(this.temporaryChildObject[i][j].keyName)
                console.log(Object.keys(this.listChildObjectDropDown[m][0]))
                let keys = Object.keys(this.listChildObjectDropDown[m][0]);
                if (this.temporaryChildObject[i][j].keyName == keys[0]) {
                  console.log(this.temporaryChildObject[i][j])
                  for (let n = 0; n < this.listChildObjectDropDown[m].length; n++) {
                    let values = Object.values(this.listChildObjectDropDown[m][n]);
                    for (let k = 0; k < values.length; k++) {
                      console.log(count)
                      if (this.temporaryChildObject[i][j].keyValue == values[k]) {
                        if (count == 1) {
                          console.log(this.listChildObjectDropDown[m][n].dropDownValueDisplay)
                          this.detailValue = this.listChildObjectDropDown[m][n].dropDownValueDisplay;
                          this.eachDetailsData.push({ parentName: this.temporaryChildObject[i][j].fieldType, childValue: this.detailValue })
                          count++
                        }
                      }
                    }

                  }
                }

              }
            }
          }
          else {
            if (this.temporaryChildObject[i][j].showForm == true) {
              this.eachDetailsData.push({ parentName: this.temporaryChildObject[i][j].fieldType, childValue: this.temporaryChildObject[i][j].keyValue })
            }
          }
        }

        this.bindingDetailsData.push(this.eachDetailsData)
        count = 1;
      }
      console.log(this.eachDetailsData)

      console.log(this.bindingDetailsData)
      this.resetChild();
    }

  }

  removeData(removeId) {
    console.log(removeId)
    this.temporaryChildObject.splice(removeId, 1)
    this.bindingDetailsData.splice(removeId, 1)
  }

  docdownload(ngModelTemporary) {

    console.log(ngModelTemporary);

    let imageName = "";

    this.ngModelObject.forEach(inputObject => {

      if (inputObject.keyName == ngModelTemporary.imageName) {

        imageName = inputObject.keyValue;

      }

    });

    console.log(imageName);
    let s3FilePath =ngModelTemporary.keyValue

    let keyName = ngModelTemporary.keyName

    if (s3FilePath !='' && s3FilePath != null && s3FilePath !=undefined) {
      // let fileName =  e.rowData[0]

      let fileName =  s3FilePath.substring(s3FilePath.indexOf(keyName+"_") + keyName.length + 1);

      let data = {
        's3Path':s3FilePath,
      };
      this.spinner.start();
      this.service.downloadFileFromS3(data).subscribe(x => {
        this.spinner.stop()
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should

        console.log(x)
        const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
        const responseData = isJsonBlob(x)
        if (responseData){
          this.snackbar.open(this.constants.fileNotFound, "OK", {duration: 5000});

        }
        else{
          console.log(x)
          var newBlob = new Blob([x]);
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //   window.navigator.msSaveOrOpenBlob(newBlob);
          //   return;
          // }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          console.log(data)

          var link = document.createElement('a');
          link.href = data;
          link.download = fileName;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);

        }




      })
    }else{

      this.snackbar.open(this.constants.fileNotFound, "OK", {duration: 5000});

    }
  }

  cancel() {

    this.reset();

    //It will reset checkbox form.
    this.formValidCheckbox.reset();

    //It will call checkbox formValidationCheckbox method.
    this.formValidationsCheckbox();

    this.externalObjectFileName=false;
    this.externalObjectFile=false;

    this.data.listObjectDropDown = [];

    this.listObjectDropDownDuplicate.forEach(listObjectTemp => {

      this.data.listObjectDropDown.push(listObjectTemp);

    });

    console.log(this.data.listObjectDropDown);

    console.log(this.listObjectDropDownDuplicate);

    this.dialogref.close(this.data.listObjectDropDown);
    console.log(this.ngModelObject)

    for(let a=0;a<this.ngModelObject.length;a++){
      if(this.ngModelObject[a].keyName ==="objecttype"){
        this.ngModelObject[a].value ="";
        console.log(this.ngModelObject[a]);
      }
     }

  }

  // cancelChild(){
  //   this.resetChildData();

  //   this.data.listObjectDropDown = [];

  //   this.listObjectDropDownDuplicate.forEach(listObjectTemp => {

  //     this.data.listObjectDropDown.push(listObjectTemp);

  //   });

  //   console.log(this.data.listObjectDropDown);

  //   console.log(this.listObjectDropDownDuplicate);

  //   this.dialogref.close(this.data.listObjectDropDown);


  // }

  checkingDuplicateInNgModel(keyName,ngModelObject){
   let arr=[]
   console.log(this.ngModelObject);
    for(let i=0; i<this.ngModelObject.length; i++){
     
      if(this.ngModelObject[i].keyName==keyName){
           console.log(keyName);
        this.ngModelObject.splice(i,1);
        
        
      }
      else{
        arr.push(ngModelObject[i]);
      }
    }
    //console.log(this.ngModelObject);
    //this.ngModelObject=arr;
     console.log(arr);
    console.log(this.ngModelObject);
  }
addingAdditionalParameters(ngModelTemporary,droupDownBindedName,droupDownBindedId,agGridColumnNameOfDynamicData){
console.log(ngModelTemporary,droupDownBindedName,droupDownBindedId,agGridColumnNameOfDynamicData+"++++++++++++++++++++++")
  
  let arr=[];
  
  
  for(let i=0; i<this.listObjectDropDown.length; i++){
  for(let j=0; j<this.listObjectDropDown[i].length; j++){
   
    let obj={}
    
    obj['dropDownValue']=this.listObjectDropDown[i][j][droupDownBindedName];
    obj['dropDownId']=this.listObjectDropDown[i][j][droupDownBindedId];
   if(this.listObjectDropDown[i][j][droupDownBindedName]!=undefined){
    arr.push(obj);
   }

   
   console.log(droupDownBindedName);
    if(this.listObjectDropDown[i][j][droupDownBindedId]==ngModelTemporary.keyValue&&ngModelTemporary.agGridColumnName==agGridColumnNameOfDynamicData){
      
      this.checkingDuplicateInNgModel(ngModelTemporary.keyName,this.ngModelObject);
      
      ngModelTemporary['value']=this.listObjectDropDown[i][j][droupDownBindedName];


     
      
    }
    
  }
  
  }
  ngModelTemporary['dropDownIdValues']=arr;
  console.log(this.ngModelObject)
  this.checkingDuplicateInNgModel(ngModelTemporary.keyName,this.ngModelObject);
  this.ngModelObject.push(ngModelTemporary);
   console.log(this.ngModelObject);
  console.log(arr);
}

  
 async childDropDown(ngModelTemporary) {
console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");

  console.log(ngModelTemporary);
  ngModelTemporary.keyValue = parseInt(ngModelTemporary.keyValue);
  if (this.headerNameHtml === "Tenant Management") {
    if (ngModelTemporary.agGridColumnName === "Company") {
      this.companyId = ngModelTemporary.keyValue;
      console.log(ngModelTemporary.keyValue)
      console.log(this.companyId);
    }
  }

  

  let system: string | null = "";
    let agGridColumnNameOfDynamicData;
   let droupDownBindedName
   let droupDownBindedId
   console.log(ngModelTemporary);
    
   let lookupid = ngModelTemporary.keyValue;
   let keyName = ngModelTemporary.keyName
   console.log(lookupid);
    if(lookupid != ""){
      const res = await (await this.loginService.getsystemName(lookupid)).toPromise();
      console.log(res);
      
      if (res && res.data) {
        system = res.data.toLowerCase();
      } else {
        system = ''; // or any default value you want to use
      }
  console.log(system);    
 if(system !== "external objects" && this.data.buttonName !="Update"){
  console.log(system); 
 // this.clearFileSelection();
 this.externalObjectFileName=false;
 this.externalObjectFile=undefined;
  console.log(ngModelTemporary.keyValue,ngModelTemporary.value)
 // ngModelTemporary.keyValue=ngModelTemporary.value;
 }

        }
        const targetValue = '20.1';
        let lookupvalueid: number | undefined;

      if (this.listObjectDropDown.length >= 5) {
        const fifthArray = this.listObjectDropDown[4]; 
        for (const obj of fifthArray) {
          if (obj.dropDownValueDisplay === targetValue) {
              lookupvalueid = obj.lookupvalueid;
              break; 
            }
          }
      }


        console.log(`Found lookupvalueid: ${lookupvalueid}`);

    for (let i = 0; i < this.dynamicData.length; i++) {
             console.log(system);
         
          if(this.dynamicData[i].fieldName==="sessionexpirationtime" && system =='vault'&& keyName=="systemname"){
            this.systemName = "Vault"
           console.log(system)
            this.dynamicData.forEach(e =>{
              if(e.fieldName ==="sessionexpirationtime"){
               e.mandatory = true
               e.keyValue = null;
               this.cdr.detectChanges();
              }
           })
            
          }
          else if(this.dynamicData[i].fieldName==="sessionexpirationtime" && system!= 'vault'&& keyName=="systemname"){
            this.systemName= "";
            console.log(system)
            this.dynamicData.forEach(e =>{
              if(e.fieldName ==="sessionexpirationtime"){
                console.log(this.dynamicData[i]);
               e.mandatory = false
               
               this.ngModelObject.forEach(e=>{
                if(e.fieldName==="sessionexpirationtime"){
                  e.keyValue = 0
                  this.cdr.detectChanges();
                }
               })
              //  this.cdr.detectChanges();
            
              }
           })
           
          }
          /*
          Author: Raghottam Joshi
          Date:14-07-2024
          changes in base screen according to vault and NON-vault in data Source Configuration
          */
            else if(this.headerNameHtml === "Data Source Configuration" && 
            ngModelTemporary.agGridColumnName==="System Name" && system!= 'vault'&& keyName=="systemname"){
              console.log( this.dynamicData)
              
              this.dynamicData.forEach(e =>{
              if(e.fieldName ==="sessionexpirationtime"){
                console.log(this.dynamicData[i]);
               e.mandatory = false
               e.showForm=false
              //  this.formValid.controls.sessionexpirationtime = new UntypedFormControl("");
              // this.formValid.setControl('sessionexpirationtime', new UntypedFormControl(0));

               this.ngModelObject.forEach(e=>{
                if(e.fieldName==="sessionexpirationtime"){
                  e.keyValue = 0
                  e.mandatory=false
  
                  // e.showForm=false
                  this.cdr.detectChanges();
                }
               })
               
            
              }else if(e.fieldName ==="vaultversion"){
                e.mandatory = false
                e.showForm=false
                // this.formValid.controls.vaultversion = new UntypedFormControl("",[Validators.required]);
                // this.formValid.setControl('vaultversion', new UntypedFormControl('',[Validators.nullValidator]));//Chages Made by 

                console.log(e)
               this.ngModelObject.forEach(e=>{
                if(e.fieldName==="vaultversion"){
                  console.log(e)
                  e.keyValue = lookupvalueid
                  e.mandatory=false
                  e.showForm=false
                 
                  this.cdr.detectChanges();
                }
               })
              //  this.cdr.detectChanges();
              }
              else if(e.fieldName ==="burstlimitthreshold"){
                e.mandatory = false
                // this.formValid.controls.burstlimitthreshold = new UntypedFormControl("",[Validators.required]);
                // this.formValid.setControl('burstlimitthreshold', new UntypedFormControl(0, [Validators.required]));
                // this.formValid.setControl('burstlimitthreshold', new UntypedFormControl('', [Validators.nullValidator]));

                // e.showForm=false
               this.ngModelObject.forEach(e=>{
                if(e.fieldName==="burstlimitthreshold"){
                  e.keyValue = "0"
                  e.mandatory=false
                 
                  // e.showForm=false
                  this.cdr.detectChanges();
                }
               })

              }
              else if(e.fieldName ==="burstlimitsleeptime"){
                e.mandatory = false
                // this.formValid.controls.burstlimitsleeptime = new UntypedFormControl("",[Validators.required]);
                // this.formValid.setControl('burstlimitsleeptime', new UntypedFormControl('', [Validators.nullValidator]));

                // e.showForm=false
               this.ngModelObject.forEach(e=>{
                if(e.fieldName==="burstlimitsleeptime"){
                  e.keyValue = 60;
                  e.mandatory=false
                 
                  // e.showForm=false
                  this.cdr.detectChanges();
                }
               })
               
              }
              // this.cdr.detectChanges();
           })

            }

             if(this.headerNameHtml === "Data Source Configuration" && 
              ngModelTemporary.agGridColumnName==="System Name" && system == 'vault'&& keyName=="systemname"){
                this.dynamicData.forEach(e =>{
                if(e.fieldName ==="sessionexpirationtime"){
                  console.log(this.dynamicData[i]);
                 e.mandatory = true
                //  e.showForm= true
                
                 this.ngModelObject.forEach(e=>{
                  if(e.fieldName==="sessionexpirationtime"){
                    e.keyValue = null
                    e.mandatory= true
                    // e.showForm= true
                    this.cdr.detectChanges();
                  }
                 })
                  console.log(e.fieldType)
                //   this.formValid.setControl('sessionexpirationtime', new UntypedFormControl(
                //     null, 
                //     [
                //         Validators.max(e.maxValuee), 
                //         Validators.min(e.minValue), 
                //         Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$'), 
                //         Validators.required
                //     ]
                // ));
                //  this.cdr.detectChanges();
              
                }else if(e.fieldName ==="vaultversion"){
                  // e.mandatory = true
                  // e.showForm=true
                 
                 this.ngModelObject.forEach(e=>{
                  if(e.fieldName==="vaultversion"){
                    e.keyValue = null
                    e.mandatory= true
                    e.showForm= true
                    this.cdr.detectChanges();
                  }
                 })
                 console.log(e.fieldType)
                //  this.formValid.setControl('vaultversion', new UntypedFormControl(null, [Validators.required]));
                //  this.cdr.detectChanges();
                }
                else if(e.fieldName ==="burstlimitthreshold"){
                  // e.mandatory = true
                  // e.showForm= true
                 
                 this.ngModelObject.forEach(e=>{
                  if(e.fieldName==="burstlimitthreshold"){
                    e.keyValue = null
                    e.mandatory= true
                    // e.showForm= true
                    this.cdr.detectChanges();
                  }
                 })
                 console.log(e.fieldType)
                //  this.formValid.controls.burstlimitthreshold = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$'), Validators.required]);
                // this.formValid.setControl('burstlimitthreshold', new UntypedFormControl(null, [Validators.required]));

                //  this.cdr.detectChanges();
                }
                else if(e.fieldName ==="burstlimitsleeptime"){
                  e.mandatory = true
                  // e.showForm=true
                  
                 this.ngModelObject.forEach(e=>{
                  if(e.fieldName==="burstlimitsleeptime"){
                    e.keyValue = null;
                    e.mandatory=true
                    // e.showForm=true
                    this.cdr.detectChanges();
                  }
                 })
                 console.log(e.fieldType)
                //  this.formValid.controls.burstlimitsleeptime = new UntypedFormControl("", [Validators.max(e.maxValuee), Validators.min(e.minValue), Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$'), Validators.required]);
                // this.formValid.setControl('burstlimitsleeptime', new UntypedFormControl(null, [Validators.required]));

                //  this.cdr.detectChanges();
                }
                // this.cdr.detectChanges();
             })
  
              }
        
          console.log(this.dynamicData[i]);
          console.log(this.dynamicData);
          
      if (this.dynamicData[i].fieldType == "valueDropDown" || this.dynamicData[i].fieldType == "valueParentDropDown" || this.dynamicData[i].fieldType == "Linked Fields") {
        if(this.dynamicData[i].agGridColumnName==ngModelTemporary.agGridColumnName){

       

        console.log(this.dynamicData[i].fieldName);

        console.log(this.dynamicData[i]);
       
      
       
        console.log(this.dynamicData[i].dropDownIDs);
        droupDownBindedId=this.dynamicData[i].dropDownIDs;
        droupDownBindedName=this.dynamicData[i].dropDownValues;
        agGridColumnNameOfDynamicData=this.dynamicData[i].agGridColumnName;
     

       this.addingAdditionalParameters(ngModelTemporary,droupDownBindedName,droupDownBindedId,agGridColumnNameOfDynamicData)
        }
        
      }
      if (this.dynamicData[i].fieldType == "Select List - Static") {
        if(this.dynamicData[i].agGridColumnName==ngModelTemporary.agGridColumnName){

        console.log(this.dynamicData[i].fieldName);
        console.log(this.dynamicData[i]);
        console.log(ngModelTemporary.agGridColumnName)
        console.log(this.dynamicData[i].dropDownIDs);
        console.log(this.dynamicData[i].dropDownValues)
      
       
        
        agGridColumnNameOfDynamicData=this.dynamicData[i].agGridColumnName;
        droupDownBindedId=this.dynamicData[i].dropDownIDs;
        droupDownBindedName=this.dynamicData[i].dropDownValues;
      
 //console.log(droupDownBindedId)
 // console.log("+++++++++++++++++ In Second")
//  console.log(droupDownBindedName);

        this.addingAdditionalParameters(ngModelTemporary,droupDownBindedName,droupDownBindedId,agGridColumnNameOfDynamicData)

      }
    }

    }

    console.log(this.dynamicData);
   
// console.log(ngModelTemporary);
// console.log(this.dynamicData);
// console.log(this.ngModelObject);
// console.log(this.listObjectDropDown[0])

   
// console.log(this.listObjectDropDown);
// console.log(this.formChildValid);
  

    if (ngModelTemporary.dropDownChildFieldName != undefined && ngModelTemporary.keyValue != "" && ngModelTemporary.keyValue != undefined) {

      console.log(ngModelTemporary);

      let dropDownChildFieldName = ngModelTemporary.dropDownChildFieldName;

      let keyName = ngModelTemporary.keyName;
      console.log(keyName)

      let keyValue = ngModelTemporary.keyValue;
      console.log(keyValue)
      let childDropDownIndex: any = 0;
      console.log(this.ngModelObject);
      console.log(dropDownChildFieldName);
     

      this.ngModelObject.forEach(ngModelObjectTemp => {

        console.log(ngModelObjectTemp);
        console.log(dropDownChildFieldName);
        

        if (dropDownChildFieldName == ngModelObjectTemp.keyName) {

          console.log(dropDownChildFieldName);

          console.log(ngModelObjectTemp.keyName);

          childDropDownIndex = ngModelObjectTemp.dropDownIndex;

          //When the popup is open ngmodelchange function will execute for that to stop keyValue empty
          if (this.buttonName == "Update" && this.childrenDropDownsIncrement < this.numberOfChildrenDropDowns) {

            this.childrenDropDownsIncrement++;

            console.log(this.childrenDropDownsIncrement);

          }

          else {

            ngModelObjectTemp.keyValue = "";

          }
        }

      });

      console.log(childDropDownIndex);

      console.log(this.listObjectDropDownDuplicate);

      let temporaryArray: any = [];

      this.listObjectDropDownDuplicate[childDropDownIndex].forEach(listObjectTemp => {
        console.log(keyValue)
        console.log(listObjectTemp)
        if (listObjectTemp[keyName] == keyValue) {

          temporaryArray.push(listObjectTemp);

        }

      });

     
      this.listObjectDropDown[childDropDownIndex] = temporaryArray;

      console.log(this.listObjectDropDown);

    }

    console.log(this.listObjectDropDown);

  }

  getSanitizer(keyValue) {

    return this.sanitizer.bypassSecurityTrustUrl(keyValue);

  }

  formValidationsCheckbox() {
    // form validations
    this.formValidCheckbox = new UntypedFormGroup({
      isCheckboxSelected: new UntypedFormControl("")
    });

  }

  //It will call when click on cancel button.
  closeFunction() {

    //Making isCheckboxSelected to false. To close the popup.
    this.isCheckboxSelected = false;

    this.close();

  }

  //This method is executing to checking respone value is true or false.
  getResponse(response) {

    console.log(response);

    console.log(typeof response);

    if (response == false) {

      //It will execute when response has value false. It means record is inserted successfully.
      console.log(response);

      this.close();

    }

  }

  //This method is executing to compare isexist value.
  async checkingIsExistInsert(isexist, temporaryObject, isExecute) {

    if (isexist !== "true") {

      //It will generate response, it has value true or false.
      let response = await this.trnxCommonTs.insertTrnxData(temporaryObject, this.tableName, isExecute, this.ngModelObject, this.tableID, this.temporaryChildObject, this.screenTypeTOrM, this.childKey, this.masterTableId);

      //Printing the response.
      console.log(response);

      console.log(typeof response);
      console.log(response ,this.externalObjectFileName)
      if(!response && this.externalObjectFileName){
        const formData = new FormData();
        console.log(this.externalObjectFile)
        formData.append('file', this.externalObjectFile)
        let tenantId=localStorage.getItem('dnsName');
   
        //formData.append('s3Path', "Migration/Vault_Objects")
    console.log(formData)
    console.log(formData.get)

     
       this.service.uploadExternalFileToS3(tenantId, formData).subscribe(res => {
        console.log(res);
        if (res.error === true) {
          //this.spinner.stop();
          this.snackbar.open(res.statusMessage, "OK", { duration: 5000 });
        } else {
          
        //  this.snackbar.open(res.statusMessage, "OK", { duration: 5000 });
        }
      })


      }

      return await response;

    }

  }

  async checkingIsExistUpdate(isexist, temporaryObject, isExecute) {

    if (isexist !== "true") {

      //It will generate response, it has value true or false.
      /*
      Author: D.Chaitanya
      Date:27-04-2022
      Passing primary key as a parameter
      */
      let response = await this.trnxCommonTs.updateTrnxData(temporaryObject, this.tableName, isExecute, this.ngModelObject, this.tableID,this.keyValues);

      //Printing the response.
      console.log(response);

      return await response;

    }

  }

  passwordEncrpt(temporaryObject) {

    console.log("temporaryObject");

    console.log(temporaryObject);

    temporaryObject.forEach(temporaryObjectTemp => {

      console.log()

      if (temporaryObjectTemp.fieldType == "Password") {

        if ((temporaryObjectTemp.keyValue != undefined) && (temporaryObjectTemp.keyValue != null)) {

          if (temporaryObjectTemp.keyValue.toString() != "") {

            console.log(temporaryObjectTemp.fieldType);

                        //chaitanya
            //27-07-2022
            //storing the servicepassword in the passwordKey
            temporaryObjectTemp.passwordKey = this.auth.encrpt(this.auth.encrptKey, temporaryObjectTemp.keyValue);

            //chaitanya
            //18-08-2022
            //removing the KeyValue
            temporaryObjectTemp.keyValue ="";
          }

        }

      }

    });

  }

  scheduleIntervalButton() {

    console.log("scheduleIntervalButton()");

    console.log(this.ngModelObjectDivision[4][0].keyValue);

    let dialogRef = this.dialog.open(CronJobIntervalComponent, {
      maxWidth: '100vw',
      width: "90%",
      data: {},
      disableClose: true
    });

    // subcribe the popup after close
    dialogRef.afterClosed().subscribe(result => {

      console.log(result);

      console.log(this.ngModelObjectDivision);

      if(this.ngModelObjectDivision[4][0].keyName == "scheduleinterval") {
        this.ngModelObjectDivision[4][0].keyValue = result.data;
        console.log(this.ngModelObjectDivision[4].keyValue);
      }


    });

  }

  disableFields(headerNameHtml,formName){

    console.log(headerNameHtml,formName,this.data.buttonName,this.externalObjectFileName,this.readonlyObjectName)
  //  console.log( this.externalObjectFileName)

    if(this.data.buttonName == "Update"){
      if(headerNameHtml == "Tenant Management" && (formName == "Company" || formName == "Tenant Name")){
        return true;
      }
      else if(headerNameHtml == "Objects" && ( formName =="Object Name")&& (this.externalObjectFileName ==true || this.readonlyObjectName ==true)){
           return true;

      }else{
  
        return false;
      }
    }else{
 // console.log(headerNameHtml);
 // console.log("**************************");
  
  
      if(headerNameHtml == "Objects" && (formName =="Object Name")&& this.externalObjectFileName ==true ){
        //console.log("0000000000000000000000000")
       return true;
      }else{
       return false

        
      }

     
    }




  
   }



   onFileSelected(event) {
    console.log("=========;;;;;;;=========")
    let flag =true;
    if(this.data.buttonName == "Update" && event.target.files[0].name!=undefined){
      console.log("========================")
      for(let a=0;a<this.ngModelObject.length;a++){
         if(this.ngModelObject[a].keyName ==="objectname"){
          if((this.ngModelObject[a].keyValue+".csv").toLowerCase() !== (event.target.files[0].name).toLowerCase()){
            flag =false;
            this.snackbar.open("File name should be same as Object Name", "OK", { duration: 5000 } );
          }
        }
       }
    }

    

    console.log("=========;;;;;;;=========")

    if(flag){ 
    console.log(this.externalObjectFile)
   // console.log(this.externalObjectFile.type);
//console.log(this.externalObjectFile.name);
   // console.log(fileNameArr[fileNameArr.length-1]);

    //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    // text/csv
   // console.log(this.externalObjectFile.name.endsWith(".csv"));
   // console.log(this.externalObjectFile.name);
  if(event.target.files[0].type === "text/csv" && event.target.files[0].name.endsWith(".csv") ){
    this.externalObjectFile = event.target.files[0];
   // let fileNameArr = this.externalObjectFile.name.split(".");

   console.log("inside if block perform teh operation")
   this.externalObjectFileName=true;
   console.log(this.ngModelChildObjectDivision);
   console.log(this.ngModelChildObject)
   let filename = this.externalObjectFile.name.split(".");
   this.csvToJson(event);
   for(let a=0;a<this.ngModelObject.length;a++){
    if(this.ngModelObject[a].keyName ==="objectlabel"){
      this.ngModelObject[a].keyValue =filename[0];
      console.log(this.ngModelObject[a]);
    }else if(this.ngModelObject[a].keyName ==="objectname"){
      this.ngModelObject[a].keyValue = filename[0];
      console.log(this.ngModelObject[a]);
    }
   }
  console.log(this.dynamicData)
  console.log(this.ngModelObject)
//this.csvJSON();

  }else{
    this.snackbar.open("Invalid file. Please select Csv file", "OK", { duration: 5000 });
  }
  
    }
  }
  downloadTemplate(event) {
    if(this.buttonName === "Update"){
      let tenantId=localStorage.getItem('dnsName');
      let fileNAme;
      for(let a=0;a<this.ngModelObject.length;a++){
        if(this.ngModelObject[a].keyName ==="objectname"){
         fileNAme = this.ngModelObject[a].keyValue+".csv";
          
        }console.log(this.ngModelObject[a]);
       }

       console.log(fileNAme)



      this.service.downloadLibraryObject(tenantId, fileNAme).subscribe(x => {
        console.log(x);
        if(x.size==0){
          this.snackbar.open("File does not exist", "OK", { duration: 5000 });
      return
  
        }else{ 
       
          
        const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
        const responseData = isJsonBlob(x)
        if (responseData){
          this.snackbar.open(this.constants.fileNotFound, "OK", {duration: 5000});

        }
        else{
          console.log(x)
          var newBlob = new Blob([x]);
        

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          console.log(data)

          var link = document.createElement('a');
          link.href = data;
          link.download = fileNAme;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);

        }
      }
        
      })

      
    }else{ 

   // console.log(this.externalObjectFile)
    if(this.externalObjectFile === undefined){ 
      console.log(this.externalObjectFile);
      this.snackbar.open("File does not exist", "OK", { duration: 5000 });
      return
  
  }else{
    console.log(event);
    var newBlob = new Blob([this.externalObjectFile], { type: 'text/csv' });
  
    const data = window.URL.createObjectURL(newBlob);
    console.log(data)
    var link = document.createElement('a');
    link.href = data;
   // console.log(event.rowData.fileName)
    link.download = this.externalObjectFile.name;
    console.log("ento")
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

   
  }
}



}

clearFileSelection(): void {
 // this.externalObjectFileName=false;
 // this.externalObjectFile = undefined;
 const fileInput = document.getElementById('file-upload') as HTMLInputElement;
  if (fileInput) {
    fileInput.value = '';  // Reset the input value
  }


  for(let a=0;a<this.ngModelObject.length;a++){
    if(this.ngModelObject[a].keyName ==="objectlabel"){
      this.ngModelObject[a].keyValue ="";
      console.log(this.ngModelObject[a]);
    }else if(this.ngModelObject[a].keyName ==="objectname"){
      this.ngModelObject[a].keyValue ="";
      console.log(this.ngModelObject[a]);
    }else if(this.ngModelObject[a].keyName ==="objectattributes"){
      this.ngModelObject[a].keyValue ="";
      console.log(this.ngModelObject[a]);
    }
   }
  
   this.externalObjectFileName=false;
   this.externalObjectFile = undefined;
}




// public csvJSON(fileLoadedEvent) {

//   let json = this.convertCsvToJson.getJsonFromCsv(this.externalObjectFile);
//   console.log(json)
//   for(let i=0; i<json.length;i++){
//       console.log(json[i]);
//   }

// }

csvToJson(event){
  console.log("=====================11")
  const file = event.target.files[0];
  const reader = new FileReader();

  reader.readAsBinaryString(file);
  console.log("=====================1221")
  reader.onload = (event:any) =>{
    console.log(event);
    let binarydata = event.target.result;
    let workbook = XLSX.read(binarydata,{type:'binary'})
    console.log(workbook.SheetNames[1])
    // workbook.SheetNames.forEach(sheet =>{
      //console.log(sheet);
      const jsonFromCsv = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
      //console.log(this.attributeJsonFromFile);
      let maxKeysObject;
      let maxKeysCount=0;
      jsonFromCsv.forEach(obj => {
        const keysCount = Object.keys(obj).length;
        if (keysCount > maxKeysCount) {
          maxKeysCount = keysCount;
          maxKeysObject = obj;
        }
      });

      
      console.log(maxKeysObject);
      let attributeArr =[];
      for (const key in maxKeysObject) {

        let template ={
          "name": key,
          "type": null,
          "label": key,
          "required": true,
          "type_value":null,
          "list_column": false,
          "max_type_value": null,
          "min_type_value": null,
          "isControlledVocabulary": null
        }
        attributeArr.push(template);
      }
      this.attributeJsonFromFile=attributeArr;

      for(let a=0;a<this.ngModelObject.length;a++){
        if(this.ngModelObject[a].keyName ==="objectattributes"){
          this.ngModelObject[a].keyValue =JSON.stringify(this.attributeJsonFromFile);
          console.log(this.ngModelObject[a]);
        }
       }
    // })
     
    
    console.log(workbook)
  }

  console.log("=====================1331")
}




   checkToShowSelectDropDown(ngModelTemporary,systemName,headerNameHtml){
    if(headerNameHtml == "Data Source Configuration"){
    if(ngModelTemporary.fieldName=='vaultversion' && systemName == 'Vault'){
      return true;
    }else if(ngModelTemporary.fieldName=='vaultversion' && systemName != 'Vault'){
      return false;
    }else{
      return true
    }
  }else{
    return true
  }
   }
 

      

}



import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { RestApi } from "src/app/shared/RestApi/restApi";
import { Router } from '@angular/router';
import {catchError, lastValueFrom, Observable, throwError} from 'rxjs';
import { error, table } from "console";
import { createCipheriv, createDecipheriv, randomBytes } from 'crypto';

interface commonServiceInfo {
  secretDetails: {};     
  statusMessage: any;
  error: boolean;
  data: any[];
  [Symbol.iterator](): Iterator<any>;
  secretName?: string;
  regionName?: string;
  accesstoken : any;
  userguideurl: any;

}



interface scheduleData {
  statusMessage: any;
  error: boolean;
  data: any;
}

@Injectable({
  providedIn: "root"
})
export class CommonService {

  private headers = new HttpHeaders({
    "Content-Type": "application/json"
    });

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private spinner: NgxUiLoaderService,
    private restAPI: RestApi,
    private router: Router
  ) { }

  getSchedules(){
    // console.log(this.restAPI.RETRIEVE_SCHEDULE_URL);
    return this.http.get(this.restAPI.RETRIEVE_SCHEDULE_URL);
  }
seceret="123456$#@$^@1ERF"
  async retrieveService(tableName, recordsStart, tenantid) {

    this.spinner.start();

    console.log(this.restAPI.GET_TABLE_DATA_URL + "/" + tableName + "/" + recordsStart + "/" + 1 + "/" + "a" + "/" + tenantid);

    let response = await this.http.get<commonServiceInfo>(this.restAPI.GET_TABLE_DATA_URL + "/" + tableName + "/" + recordsStart + "/" + 1 + "/" + "a" + "/" + tenantid, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async retrieveDropdownService(tableName, recordsStart, dropDownValuesDisplay, tenantid) {

    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.GET_TABLE_DATA_URL + "/" + tableName + "/" + recordsStart + "/" + 0 + "/" + dropDownValuesDisplay + "/" + tenantid, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async insertService(temporaryObject) {

    console.log(temporaryObject);

    this.spinner.start();

    let response = await this.http.post<commonServiceInfo>(this.restAPI.CREATE_RECORD_URL, temporaryObject, { headers: this.headers }).toPromise();

    //Printing the response.
    console.log(response);

    this.spinner.stop();

    this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });
    return response.error;

  }

  async updateService(temporaryObject, tableName, idName, idValue) {

    console.log(temporaryObject);

    this.spinner.start();

    let response = await this.http.put<commonServiceInfo>(this.restAPI.UPDATE_RECORD_URL + "/" + tableName + "/" + idName + "/" + idValue, temporaryObject, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

    }

    else {

      this.spinner.stop();

      console.log(response);

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

    }

    return response.error;

  }

  async deleteService(tableName, idName, idValue) {

    this.spinner.start();

    let response = await this.http.delete<commonServiceInfo>(this.restAPI.DELETE_RECORD_URL + "/" + tableName + "/" + idName + "/" + idValue, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

    }
    else {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 50000 });
    }

  }
  /*
    @Author:Ravi G
    @Date:28-06-2022
    @Decription delete record from db
*/
  async deleteRecordPermanent(tableName, idName, idValue) {

    this.spinner.start();

   return this.http.delete<commonServiceInfo>(this.restAPI.DELETE_RECORD_PERMANENT_URL + "/" + tableName + "/" + idName + "/" + idValue, { headers: this.headers });



  }
  uploadingExcelFile(sourceFile,dnsName){
    return this.http.post<commonServiceInfo>(this.restAPI.UPLOADCSVFILE+"/"+dnsName,sourceFile);
  }

  public download_test(supportingDoc): Observable<Blob> {

    return this.http.get(supportingDoc, { responseType: 'blob' });

  }

  async downloadImage(supportingDoc) {
    let data = {
      's3Path':supportingDoc,
    };
    let response = await this.http.post(this.restAPI.DOWNLOADFILEFROMS3, JSON.stringify(data),{ headers: this.headers, responseType: 'blob' });
   return  await lastValueFrom(response)



  }
   // Encryption method 
   encrypt(text: string, key: string): string {
    let encryptedText = '';
    const keyLength = key.length;
    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i);
      const keyCharCode = key.charCodeAt(i % keyLength);
      const encryptedCharCode = charCode + keyCharCode;
      encryptedText += String.fromCharCode(encryptedCharCode);
    }
    return encryptedText;
  }
  


  async retrieveSingleRecordService(tableName, fieldName, fieldValue) {
    if(tableName=="users"){
      fieldValue=this.encrypt(fieldValue,this.seceret);
    }

    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.GET_TABLE_SINGLE_RECORD_URL + "/" + tableName + "/" + fieldName + "/" + fieldValue, { headers: this.headers }).toPromise();
    console.log(response)
    
    if (response.error == true) {
        
      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {
      if(tableName!="users"){
        this.spinner.stop();
      }
      return response;

    }

  }

  async retrieveRecordServiceWithRange(tableName,fromDate,toDate) {
    

    this.spinner.start();
    let pathVariables=tableName+ "/" + fromDate+ "/" + toDate;
    let response = await this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_TABLEDATA_URL + "/" + pathVariables, { headers: this.headers }).toPromise();
    //console.log(tableName);
    console.log(this.restAPI.RETRIEVE_TABLEDATA_URL + "/" + pathVariables);
    console.log(response)

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async retrieveRecordService(tableName) {
    

    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_TABLEDATA_URL + "/" + tableName, { headers: this.headers }).toPromise();
    //console.log(tableName);
    console.log(response)

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }
      /* @added by Ravi G
         @date 18-11-2022
         @desc refresh the vault object attribute
      */
  refereshObjectMetaData(dnsName,objectName){
    console.log(this.restAPI.RETRIEVEOBJECTFIELDSURLBYDNSID + '/' + dnsName+ '/'+objectName)
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIEVEOBJECTFIELDSURLBYDNSID + '/' + dnsName+ '/'+objectName, {headers: this.headers});

  }

  /* @added by Ravi G
      @date 22-01-2023
      @desc fetch values using multiple where clause
   */
  getlistByMultiplewhereclause(tablename,colNameAndValues) {
    let obj ={
      tableName:tablename,
      colNameAndValues:colNameAndValues
    }

    return this.http.post<commonServiceInfo>(this.restAPI.GETLISTBYMULTIPLEWHERECLAUSE,obj, {headers: this.headers});

  }
  public downloadFileFromS3(s3Path): Observable<Blob> {
    return this.http.post(this.restAPI.DOWNLOADFILEFROMS3, JSON.stringify(s3Path),{ headers: this.headers, responseType: 'blob' });
  }
  /* @added by Ravi G
    @date 15-02-2023
    @desc fetch lookupvalue by lookuptype
 */
  getlookupVauleByLookUpType(lookUpType){
    return this.http.get<commonServiceInfo>(this.restAPI.GETLOOKUPVAULEBYLOOKUPTYPE + '/' + lookUpType, {headers: this.headers});

  }

  getParentReportTemplateDetails() {

    return this.http.get<commonServiceInfo>(this.restAPI.GETPARENTREPORTTEMPLATEDETAILS, {headers: this.headers});

  }

  refreshReportsAndPushToVault(instancedetailid,reruntype,instanceid) {

    return this.http.get<any>(this.restAPI.REEXECUTEGENERATEREPORTPUSHTOVAULT + "/" + instancedetailid+"/"+reruntype+"/"+instanceid, {headers: this.headers});

  }
  
  getVaultSessionId(dnsName){
    return this.http.get<any>(this.restAPI.RETRIEVE_VAULT_SESSIONID+"/"+dnsName,{headers: this.headers});
  }


  retrieveRefreshHistory(dnsId){
    return this.http.get<any>(this.restAPI.RETRIEVE_REFRESH_HISTORY+"/"+dnsId,{headers: this.headers})
  }

  getRefreshStatus(dnsId){
    return this.http.get<Object>(this.restAPI.RETRIEVE_REFRESH_STATUS+"/"+dnsId,{headers: this.headers})
  }

  retrieveJsuiteRecord(tenantId: number): Promise<any[]> {
    const url = `${this.restAPI.RETRIEVE_JSUITE_PROPERTY}/${tenantId}`;
    return this.http.get<any[]>(url).toPromise();
  }
  insertOneSignalUser(oneSignalDetails){
    return this.http.post<commonServiceInfo>(this.restAPI.INSERT_ONESIGNAL_USER, JSON.stringify(oneSignalDetails),{ headers: this.headers});
  }
  retrieveAboutRecord(): Promise<any[]> {
    const url = `${this.restAPI.RETRIEVE_About_record}`;
    return this.http.get<any[]>(url).toPromise();
  }

  retrieveDnsService(compId): Observable<commonServiceInfo> {
    this.spinner.start();
  

  
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIVE_DNS+"/"+compId, { headers: this.headers }).pipe(
      catchError((error) => {
        console.error("Error:", error);
        this.spinner.stop();
        this.snackBar.open(error.statusMessage, "OK", { duration: 5000 });
        return throwError(error);
      })
    )}

    retrieveDnsUser(dnsId): Observable<commonServiceInfo> {
      this.spinner.start();
      return this.http.get<commonServiceInfo>(this.restAPI.RETRIVE_DNS_USERS+"/"+dnsId, { headers: this.headers }).pipe(
        catchError((error) => {
          console.error("Error:", error);
          this.spinner.stop();
          this.snackBar.open(error.statusMessage, "OK", { duration: 5000 });
          return throwError(error);
        })
      )}

      saveDnsUser(obj) {
     
        return this.http.post<commonServiceInfo>(this.restAPI.SAVE_DNS_USER,obj, {headers: this.headers});
    
      }
      retrieveDnsUserAcessRecord(dnsId): Observable<commonServiceInfo> {
        this.spinner.start();
        return this.http.get<commonServiceInfo>(this.restAPI.RETRIVE_DNS_USER_RECORD+"/"+dnsId, { headers: this.headers }).pipe(
          catchError((error) => {
            console.error("Error:", error);
            this.spinner.stop();
            this.snackBar.open(error.statusMessage, "OK", { duration: 5000 });
            return throwError(error);
          })
        )}
        retrieveLogInUser(userId): Observable<commonServiceInfo> {
          this.spinner.start();
          return this.http.get<commonServiceInfo>(this.restAPI.RETRIVE_LOG_IN_USER+"/"+userId, { headers: this.headers }).pipe(
            catchError((error) => {
              console.error("Error:", error);
              this.spinner.stop();
              this.snackBar.open(error.statusMessage, "OK", { duration: 5000 });
              return throwError(error);
            })
          )}
          deleteRecordAccess(userId): Observable<commonServiceInfo> {
            this.spinner.start();
            return this.http.delete<commonServiceInfo>(this.restAPI.DELETE_RECORD +"/"+userId, { headers: this.headers }).pipe(
              catchError((error) => {
                console.error("Error:", error);
                this.spinner.stop();
                this.snackBar.open(error.statusMessage, "OK", { duration: 5000 });
                return throwError(error);
              })
            )}

          // async deleteRecordAccess(dnsId) {
          //  return this.http.put<commonServiceInfo>(this.restAPI.DELETE_RECORD + "/" + dnsId, { headers: this.headers });
          // }
          downloadFile(data: any, filename:string) {
            const replacer = (key, value) => value === null ? '' : value;
            const header = Object.keys(data[0]);
            let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName],replacer)).join(','));
            csv.unshift(header.join(','));
            let csvArray = csv.join('\r\n');
            var blob = new Blob([csvArray], {type: 'text/csv' })
            // saveAs(blob, filename + ".csv");
            let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = filename+".csv";
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
        }

        retrieveSecret(): Observable<commonServiceInfo> {
          this.spinner.start();
          return this.http.get<commonServiceInfo>(this.restAPI.RETRIVE_SECRET, { headers: this.headers,responseType: 'json' }).pipe(
            catchError((error) => {
              console.error("Error:", error);
              this.spinner.stop();
              this.snackBar.open(error.statusMessage, "OK", { duration: 5000 });
              return throwError(error);
            })
          )}
          saveAwsSecret(obj,dnsid,type):Observable<commonServiceInfo> {
     
            return this.http.post<commonServiceInfo>(this.restAPI.CREATE_SECRET+"/"+dnsid+"/"+type,obj, {headers: this.headers});
        
          }

          searchCase(caseNumber,dnsId){
            return this.http.get<commonServiceInfo>(this.restAPI.searchCase+"/"+caseNumber+"/"+dnsId,{headers: this.headers});
          }

          caseJsonHTML(s3folder,caseid,dnsId,dbName){
            let s3folderobj = {
              s3foldername :s3folder,
              sourceDB : dbName+"_partition_file_path"
            }
            return this.http.post(this.restAPI.CASE_JSON_HTML+"/"+caseid+"/"+dnsId,JSON.stringify(s3folderobj),{ headers: this.headers, responseType: 'text' });
          }


          auditTrailHTML(s3folder,caseid,dnsId,dbName){
            let s3folderobj = {
              s3foldername :s3folder,
              sourceDB : dbName
            }
            return this.http.post(this.restAPI.AUDIT_TRAIL_HTML+"/"+caseid+"/"+dnsId,JSON.stringify(s3folderobj),{ headers: this.headers, responseType: 'text' });
          }
  
          refreshCaseCatalog(dnsId,sourceDb){
            return this.http.get<commonServiceInfo>(this.restAPI.REFRESH_CASE_CATALOG+"/"+dnsId+"/"+sourceDb,{headers: this.headers})
          }
          retrieveS3Path(){
            return this.http.get<scheduleData>(this.restAPI.RETRIEVE_S3_PATH , {headers: this.headers});
        
          }
          buildProject(id){
            return this.http.get<scheduleData>(this.restAPI.BUILD_PROJECT+'/'+id , {headers: this.headers});
        
          }
          public checkoutFiles(body): Observable<Blob> {
            return this.http.post(this.restAPI.CHECKOUT_FILES, JSON.stringify(body),{ headers: this.headers, responseType: 'blob' });

          }
  public retrieveVaultDnsname(envName): any {
    return this.http.get(this.restAPI.RETRIEVE_DNSNAME + "/" + envName,{ headers: this.headers});
  }


  uploadExternalFileToS3(tenantId,formdata){
    return this.http.post<scheduleData>(this.restAPI.UPLOAD_EXTERNALFILE_S3+"/"+tenantId,formdata)
  }

  public downloadLibraryObject(tenantId,fileName): Observable<Blob> {
    return this.http.get(this.restAPI.DOWNLOAD_LIBRARY_OBJECT+"/"+tenantId+"/"+fileName,{ headers: this.headers, responseType: 'blob' });
  }

  retrieveObjectDetectChangesHistory(dnsId){
    return this.http.get<any>(this.restAPI.RETRIEVE_DETECT_OBJECT_CHANGES_HISTORY+"/"+dnsId,{headers: this.headers})
  }

  retrieveVaultObjectChanegs(dnsId,refreshHistoryId){
    return this.http.get<scheduleData>(this.restAPI.RETRIEVE_VAULT_OBJECT_CHANGES + '/' + dnsId+'/'+refreshHistoryId, {headers: this.headers});
  }


   detectChangesObjectMetadata(dnsName){

    return this.http.get<scheduleData>(this.restAPI.DETECT_VAULT_OBJECTS_CHANGES + '/' + dnsName, {headers: this.headers});
  }

  getVaultObjectChangesDetectionStatus(dnsId){
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_VAULT_OBJECT_CHANGES_DETECT_STATUS+"/"+dnsId,{headers: this.headers})
  }

  importVaultChanges(dnsId,changes){
    return this.http.post<commonServiceInfo>(this.restAPI.IMPORT_VAULT_CHANGES+"/"+dnsId,JSON.stringify(changes),{ headers: this.headers})
  }



  async retrieveTestCycleMstr(dnsId: number) {
   const response = await this.http.get<commonServiceInfo>(this.restAPI. GET_TEST_SCRIPTS_FOR_SELECTED_DNS + "/" + dnsId,{ headers: this.headers }).toPromise();
      return response;
  }
//  @author Kameshwar
//  @date 09-10-2024
  ImportDatasets(ObjectgroupId,formdata){
    console.log(formdata.get)
    return this.http.post(this.restAPI.IMPORT_DATASETS+"/"+ObjectgroupId,formdata)
  }
//  @author Kameshwar
//  @date 09-10-2024
  exportDatasets(exportData): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.restAPI.EXPORT_DATASETS, exportData, { headers: headers, responseType: 'blob' });
	
}

retrieveImportHistory(dnsId){
  console.log('Fetching data from API');
  return this.http.get<any>(`${this.restAPI.RETRIEVE_IMPORT_HISTORY}/${dnsId}`, {headers: this.headers});
}


  public retrieveDnsServicesforRegionandsecret(dnsId: string) {
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIVE_SECRET_AND_REGION_BASEDON_DNSID + "/" + dnsId,{ headers: this.headers })
  }

  /*
Author:Venkata Shyam 
Date:16-01-2025

*/
  public retrieveAccessTokenForUserGuide():Observable<any>{
    
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_ACCESS_TOKEN, {headers:this.headers})
  }

  insertMappingConfiguration(mapppingData) {
    return this.http.post<commonServiceInfo>(this.restAPI.INSERT_MAPPING_CONFIGURATION, mapppingData, { headers: this.headers });
  }

  retrieveMappingConfiguration(){
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_MAPPING_CONFIGURATION, { headers: this.headers })

  }
  checkIfSourceOrTarget(dataSourceType,names) {
 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.restAPI.RETIRVE_SOURCE_OR_TARGET+"/"+dataSourceType, names, { headers: headers});
 
  }
  updateMappingConfiguration(mapppingData) {
    return this.http.post<commonServiceInfo>(this.restAPI.UPDATE_MAPPING_CONFIGURATION, mapppingData, { headers: this.headers });
  }
  deleteMappingConfiguration(id) {
    return this.http.delete<commonServiceInfo>(this.restAPI.DELETE_MAPPING_CONFIGURATION + "/" + id, { headers: this.headers })
  
  }

public getTenantidBasedOnEnvName(envName): any {
  return this.http.get(this.restAPI.RETRIEVE_DNS_ID_BASED_ON_ENV_NAME + "/" + envName,{ headers: this.headers});
}
 
retrieveSourceDnsWithDetails(userid): Observable<any> {
 
  return this.http.get<any>(this.restAPI.RETRIEVE_DNS_BASED_USER+"/"+userid,{headers:this.headers});
}
  
  public getTableDataByDns(tableName,dnsName):Observable<any>{
    
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_DETAILS_OF_SCREEN_BASED_DNSNAME+ '/' + tableName + '/'+dnsName,{headers:this.headers})
  }

  public getTenantidBasedOnName(dnsName){
   
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIEVEDNSID + '/'+dnsName,{headers:this.headers})
  }
  public getTenantidBasedEnvname(envname){
   
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIEVEDNSID_BASED_ON_ENVNAME + '/'+envname,{headers:this.headers})
  }

  retrieveObjectGroupObjectsbasedDns(dnsid): Observable<any>{
    
    return this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_GROUP_OBJECTS_BASED_DNS+"/"+dnsid,{ headers: this.headers });
  }

  
  checkIfSource(names) {
  
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.restAPI.RETIRVE_SOURCE_OR_NOT, names, { headers: headers});
	
}

}

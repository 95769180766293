import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {RestApi} from '../RestApi/restApi';

interface AsignUseRrole {
  statusMessage: any;
  error: boolean;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
/*
Author:Ravi G
Date:28-06-2022
*/
export class AsignUserRoleService {
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private restAPI: RestApi,
  ) {
  }
  //retrieve Users For Assign User Role
  public retrieveUsers() {
    return this.http.get<AsignUseRrole>(this.restAPI.RETRIEVE_USERS_ASSIGN_USER_ROLE, {headers: this.headers});
  }
  //insert Assign User Role
  public insertAssignUserRole(assignUserRoleData) {
    return this.http.post<AsignUseRrole>(this.restAPI.INSERT_ASSIGN_USER_ROLE, JSON.stringify(assignUserRoleData), {headers: this.headers});
  }
  //retrieve Assign Users Role By DnsId
  public retrieveAssignUsersRoleByDnsId() {
    return this.http.get<AsignUseRrole>(this.restAPI.RETRIEVE_ASSIGN_USER_ROLE_BYDNS_ID, {headers: this.headers});

  }
  //is User Role available For User
  public isUserRoleavailableForUser(userId, tenantId) {
    return this.http.get<AsignUseRrole>(this.restAPI.IS_USER_ROLE_AVAILABLE_FOR_USER + '/' + userId + '/' + tenantId, {headers: this.headers});
  }
  //reterive user Role By Tenant Id And UserId
  public reteriveuserRoleByTenantIdAndUserId(userId) {
    return this.http.get<AsignUseRrole>(this.restAPI.RETERIVE_USER_ROLE_BY_TENANTID_AND_USERID + '/' + userId, {headers: this.headers});
  }

  public reteriveUserRoleMstrByDnsId(dnsId) {
    return this.http.get<AsignUseRrole>(this.restAPI.RETERIVE_USER_ROLEMSTR_BY_DNS_ID +"/"+ dnsId, {headers: this.headers});

  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { constants } from 'src/app/shared/common/contants';
import { LocalStorageSettingsService } from 'src/app/shared/services/local-storage-settings.service';
import { EtlService } from '../vault-data-migration-etl/etl.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { validationmethod } from 'src/app/shared/models/validationmethod';
import { ErrorMessages } from 'src/app/shared/common/errormessages';
import { CommonTs } from 'src/app/shared/common/CommonTs';
import { json } from 'stream/consumers';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-etl-migration-popup',
  templateUrl: './etl-migration-popup.component.html',
  styleUrls: ['./etl-migration-popup.component.scss']
})
export class EtlMigrationPopupComponent implements OnInit {


  backgroundColorScreen;
  buttonColor;
  backgroundColorButton;

  uploadFile = false;

  migration = {"source":"", "target":"", "dataExtractionType":"", "migrationType":"", "remarks":"", "fileData": {},"selectedObjectGroups":"","dataLakeAndReports":"","dataLakeType":"","DatasetName":"", "persistenceEmr":"No", "persistenceClusterId":"","JSONTOCSV_Validation_Reports":""};

  fileData={"fileName":"","fileData":""};
  source = [];
  target = [];
  dataLakeAndReports:any=[];
  dataLakeType:any=[];
  sourceSystemType = "";
  targetSystemType = "";
  partialData = false;
  migrationType: any = [];
  systemNames: any = [];
  persistenceEmr: any = [];
  persistenceClusterId: any = [];
  validation_source_form;

  validations_form_input;
  validation_messages;
  validations_form;
  validations_form_upload;

  fullDataRadio;
  partialDataRadio;

  public selectedTarget: string ;


  objectGroups: any[] = [];
  SourceDnsID: any;
  TargetDnsId: any;
  selectedObjectGroups: Number[] = [];

  dataLakeTypeOptions: any=[];
  dataLakeAndReportsOptions: any=[];
  persisteneceEmrOptions: any=[];
  JSONTOCSV_Validation_ReportsOptions: any=[];

  DisplayObject: boolean = false;
  selectedObjectGroupsString: string;

  constructor(private msg: ErrorMessages, private dialogref: MatDialogRef<EtlMigrationPopupComponent>, private constants: constants, private localStorageSettings: LocalStorageSettingsService, private service:EtlService, private snackbar: MatSnackBar, private spinner: NgxUiLoaderService,private dialog: MatDialog, private validation: validationmethod, private formBuilder:FormBuilder, private commonTs: CommonTs, private snackBar: MatSnackBar, private commonService: CommonService) {
    this.validation_messages = this.msg.startMigrationPopup;
  }

  ngOnInit(): void {
    this.selectedTarget =this.localStorageSettings.getSettings("SelectedDnsName");

    this.backgroundColorScreen = this.localStorageSettings.getSettings("ColorD");
    this.buttonColor = this.localStorageSettings.getSettings("ColorA");

    this.backgroundColorButton = this.localStorageSettings.getSettings("ColorA");

    this.validations_form = this.formBuilder.group({
      source: '',
      target:'',
      migrationType:'',
      fileData:'',
      fileUpload:'',
      remarks:'',
      objectGroups:'',
      dataLakeAndReports:'',
      dataLakeType:'',
      persistenceEmr:'',
      DatasetName:'',
      selectdataSet:'',
      JSONTOCSV_Validation_Reports:''
    })
    this.validations_form_input = this.formBuilder.group({
      remarks:'',
      DatasetName:'',
    })
    this.validations_form_upload = this.formBuilder.group({
      upload:'',
      fileData:'',
    })
    this.uploadFile = false;
    this.spinner.start();
    let dnsId = this.localStorageSettings.getSettings("dnsName");
    this.commonService.getlookupVauleByLookUpType("DataMigrationType").subscribe(result=>{
      console.log(result);
      this.migrationType = result;
    });
    this.commonService.getlookupVauleByLookUpType("System_name").subscribe(result=>{
      console.log(result);
      this.systemNames = result;
    });
    this.service.retrieveSourceAndTraget(dnsId, "Source").subscribe((result)=>{
      this.source = result;
      console.log(this.source);
    })
    this.service.retrieveSourceAndTraget(dnsId, "Target").subscribe((result)=>{
      this.target = result;
      console.log(this.target);
      this.migration.target = this.selectedTarget;
      let systemName = null;
      this.target.forEach((targetObject)=>{
      if(targetObject.envName === this.selectedTarget){
        systemName = targetObject.systemName;
        this.TargetDnsId=targetObject.vaultConfigMstrId;

      }
    });
    this.systemNames.forEach(system=>{
      if (system.lookupvalueid === systemName){
        this.targetSystemType = system.lookupvalue;
      }
    })
    console.log(this.targetSystemType);
    })
    this.spinner.stop();
  }

  formValidation(){
    this.validations_form = new FormGroup({
      source: new FormControl('', [Validators.required]),
      target: new FormControl('', [Validators.required]),
      migrationType: new FormControl('', [Validators.required]),
      fileData: new FormControl(''),
      fileUpload: new FormControl(''),
      remarks: new FormControl('',[Validators.required]),
      objectGroups: new FormControl('', [Validators.required]),
      dataLakeAndReports: new FormControl('',[Validators.required]),
      dataLakeType: new FormControl('',[Validators.required]),
      persistenceEmr: new FormControl('',[Validators.required]),
      DatasetName: new FormControl('',[Validators.required]),
      JSONTOCSV_Validation_Reports: new FormControl('',[Validators.required])
    })
  }

  cancel(){
    this.dialog.closeAll();
  }


storeSelectedObjectGroupsAsString(): void {
  if(this.DisplayObject===true){
    this.selectedObjectGroupsString = this.selectedObjectGroups.join(',');
  }

}

  startmigration(): void {
    Object.keys(this.validations_form.controls).forEach(key => {
      this.validations_form.get(key).markAsTouched();
    });
    Object.keys(this.validations_form_input.controls).forEach(key => {
      this.validations_form_input.get(key).markAsTouched();
    });
    this.migration.fileData = this.fileData;
    this.migration.target = this.selectedTarget;
    this.storeSelectedObjectGroupsAsString();
    this.migration.selectedObjectGroups=this.selectedObjectGroupsString;
    console.log(this.migration)
    if (this.migration.target === this.migration.source) {
      this.snackBar.open("Source and Target cannot be the same", "OK", { duration: 5000 });
    } else if (this.migration.target === "" || this.migration.source === "" || this.migration.migrationType === "" || this.migration.remarks === "") {

      this.snackBar.open("All mandatory fields must be filled", "OK", { duration: 5000 });
    } else if(this.selectedObjectGroupsString==="" && this.DisplayObject===true){
      this.snackBar.open("All mandatory fields must be filled", "OK", { duration: 5000 });
    }else if(this.migration.migrationType === "Data Virtualization"&& (this.migration.DatasetName==="" || this.migration.dataLakeAndReports==="" || this.migration.dataLakeType==="" || this.migration.persistenceEmr==="" || this.JSONTOCSV_Validation_ReportsOptions==="")){
      this.snackBar.open("All mandatory fields must be filled", "OK", { duration: 5000 });
    }
    else {
      if (this.migration.migrationType === "Data Virtualization" && this.migration.dataExtractionType === "Full Data") {
        const confirmMessage = "Performing subsequent full data load operation will delete/overwrite existing data lake. Proceed with full data load?";
        const confirmDelete = confirm(confirmMessage);

        if (!confirmDelete) {
          this.migration.migrationType = "";
          this.migration.dataExtractionType = "";
          this.DisplayObject=false;
          return;
        }
      }

      this.dialogref.close({ data: this.migration });
    }
  }

  onSourceSelection(event){
    this.spinner.start();

    this.source.forEach(item => {
      if(item.envName===event.value){
      console.log(item.vaultConfigMstrId);
      this.SourceDnsID=item.vaultConfigMstrId;
      }
    });
    console.log(event.value);
    this.migration.source = event.value;
    let systemName = null;
    // this.source.forEach(sourceObject=>{
    //   if(sourceObject.envName === event.value){
    //     systemName = sourceObject.systemName;
    //   }
    // })
    // this.systemNames.forEach(system=>{
    //   if (system.lookuptypeid = systemName){
    //     this.sourceSystemType = system.lookupvalue;
    //   }
    // })
    // Find systemName based on envName
    const sourceObject = this.source.find(sourceObject => sourceObject.envName === event.value);
    systemName = sourceObject ? sourceObject.systemName : null;

    // Find sourceSystemType based on systemName
    const system = this.systemNames.find(system => system.lookupvalueid === systemName);
    this.sourceSystemType = system ? system.lookupvalue : null;
    event.value=this.migration.migrationType;
    this.onMigrationTypeSelection(event)
    this.spinner.stop();
  }
/**
  onTargetSelection(event){
    this.spinner.start();
    this.migration.target = event.value;
    let systemName = null;
    this.target.forEach((targetObject)=>{
      if(targetObject.envName === event.value){
        systemName = targetObject.systemName;
      }
    });
    this.systemNames.forEach(system=>{
      if (system.lookuptypeid = systemName){
        this.targetSystemType = system.lookupvalue;
      }
    })
    console.log(this.targetSystemType);
    console.log(event.value);
    this.spinner.stop();
  }
*/
onObjectGroupSelection(event: any): void {
  this.spinner.start();
  this.selectedObjectGroups = event.value;
  this.spinner.stop();
}
  onMigrationTypeSelection(event){
    if (event.value === 'Data Virtualization' && (!this.dataLakeAndReportsOptions.length || !this.dataLakeTypeOptions.length)) {
      this.commonService.getlookupVauleByLookUpType("dataLakeAndReports").subscribe(result => {
        console.log(result);
        this.dataLakeAndReportsOptions = result;

      });
      this.commonService.getlookupVauleByLookUpType("DATALAKE_TYPE").subscribe(result => {
        console.log(result);
        this.dataLakeTypeOptions = result;
      });
      this.commonService.getlookupVauleByLookUpType("JSONTOCSV_Validation_Reports").subscribe(result => {
        console.log(result);
        this.persisteneceEmrOptions = result;
        this.JSONTOCSV_Validation_ReportsOptions=result;
      })
    }
    this.migration.dataLakeAndReports="";
    this.migration.dataLakeType="";

    this.partialData=false;
    this.migration.migrationType = event.value;
    console.log(this.sourceSystemType);
    console.log(this.targetSystemType);
    if (event.value === 'Data Migration'){
      if (this.sourceSystemType == 'VAULT'){
        this.partialData = true;
      }
    }else{
      this.partialData = true;
    }
    this.checkForObjectNames();

    console.log(event.value);
  }

 checkForObjectNames() {
  this.spinner.start();
  if ((this.sourceSystemType === "VAULT" && this.migration.migrationType === "Data Virtualization")
     || ((this.sourceSystemType === "VAULT" && (this.targetSystemType.toLowerCase() === "argus"||this.targetSystemType.toLowerCase() === "arisg"||this.targetSystemType.toLowerCase() === "arisj" || this.targetSystemType.toLowerCase() === "database"))
     && this.migration.migrationType === "Data Migration")) {//manit
      this.DisplayObject = true;

      console.log(this.SourceDnsID, this.TargetDnsId);
      this.service.getObjectGroupsNames(this.SourceDnsID, this.TargetDnsId).subscribe(
          result => {
              console.log(result);
              if (result===null) {
                  this.objectGroups = ["No Object Groups Found"];
                  console.log("No Data Found")
              } else {
                  this.objectGroups = result;
              }
              this.spinner.stop();
          },
          error => {
              console.error("Error fetching object groups:", error);
              this.objectGroups = [];
              this.spinner.stop();
          }
      );

  } else {
      this.DisplayObject = false;
      this.spinner.stop();
  }
}



  onSelectDataExtraction(value){
    console.log(this.selectedObjectGroups);
    this.migration.dataExtractionType = value;
    if(value === "Partial Data"){
      this.uploadFile = true;
    }else{
      this.uploadFile = false;
      this.fileData.fileData = '';
      this.fileData.fileName = '';
    }

  }



  imagePhotofunction($event,filecheck): void {
    this.readThisimage($event.target,filecheck);
  }

  readThisimage(inputValue: any,filecheck:any): void {
    let ischeckfile=false
   var file: File = inputValue.files[0];
   var FileS: File = inputValue.files[0];
   console.log("file type")
   console.log(file.type)
   let flag:boolean= this.commonTs.hasMultipleExtensions(file);
   if (!flag) {
     console.log(file.size / 1000)
     var size = file.size / 1000
     /**
      * Modified by Ashwin M
      * 27/02/2023
      * code modified to increase file upload size limit to 200MB
      */
     if (size <= 200000) {
           var fileName = FileS.name;
           //var filename = (<HTMLInputElement>document.getElementById('file-id')).value;

           var photo: FileReader = new FileReader();
           photo.onloadend = e => {
             var img = photo.result;
             var photobase64result = photo.result.toString().split(",", 2)[1];

             // this is to check the file is empty or not
             if (photobase64result == null) {

             } else {
               let imagephoto = photo.result.toString();
               this.fileData.fileData = photobase64result;
               this.fileData.fileName = fileName;
             }
           };
           photo.readAsDataURL(file);
     } else {
       this.snackBar.open("Selected file " + file.name + ", size should be less than 200MB", "OK", {
         duration: 5000,
       });
     }
   } else {
     this.snackBar.open(file.name + " is a invalid file type ", "OK", {
       duration: 5000,
     });
   }
 }
 /**
  * Added By Kameshwar
  * Date: 24-05-2024
  * @param event
  */
 onDataLakeTypeSelection(event) {
  this.spinner.start();
  this.migration.dataLakeType=event.value;
  this.spinner.stop();
}

onDataLakeAndReportsSelection(event) {
  this.spinner.start();
  this.migration.dataLakeAndReports=event.value;
  this.spinner.stop();
}

onPersistenceEmrSelection(event) {
  this.migration.persistenceEmr=event.value;
  this.migration.persistenceClusterId = "0";
}

validateInput(event: any) {
  const input = event.target.value;
  const regex = /^[a-z0-9-_]+$/;
  if (!regex.test(input)) {
    event.target.value = input.slice(0, -1);
  }
}
onValidationSelection(event) {
  this.spinner.start();
  this.migration.JSONTOCSV_Validation_Reports=event.value;
  this.spinner.stop();
}


}

<div class="maindiv fullbody">
    <div style="margin-top:1%">
        <div class="vaultDataloadHeadDiv">
            <h4>Run Migration</h4>
        </div>
    </div>
    <div>
        <form novalidate [formGroup]='validations_form'>
            <div class="dropDownDiv">
              <mat-form-field appearance="outline" class="dropdown">
                <mat-label i18n="@@RCFormulaexpression">Source</mat-label>
                <mat-select [(ngModel)]="migration.source" (selectionChange)="onSourceSelection($event)" formControlName="source" required title="Source">
                    <mat-option *ngFor="let eachtype of source" [(value)]="eachtype.envName">
                        {{eachtype.envName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngFor="let validation of validation_messages.source">
                    <span *ngIf="validations_form.get('source').hasError(validation.type) && (validations_form.get('source').dirty || validations_form.get('source').touched)">
                        {{ validation.message }}
                    </span>
                </mat-error>
            </mat-form-field>



                <!-- <mat-form-field appearance="outline" class="dropdown">
                    <mat-label i18n="@@RCFormulaexpression" >Target</mat-label>
                    <mat-select [(ngModel)]="migration.target"
                      (selectionChange)="onTargetSelection($event)"
                      formControlName="target" required title="Target">
                      <mat-option *ngFor="let eachtype of target"
                        [(value)]="eachtype.envName">
                        {{eachtype.envName}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngFor="let validation of validation_messages.target">
                      <span
                        *ngIf="validations_form.get('target').hasError(validation.type)
                        && (validations_form.get('target').dirty ||
                        validations_form.get('target').touched)">
                        {{ validation.message }}
                      </span>
                    </mat-error>
                </mat-form-field> -->
                <mat-form-field appearance="outline" class="dropdown">
                  <mat-label i18n="@@RCFormulaexpression">Target</mat-label>
                  <mat-select [(ngModel)]="selectedTarget" [disabled]="true" formControlName="target" required title="Target">
                    <mat-option [value]="selectedTarget">{{ selectedTarget }}</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of validation_messages.target">
                    <span
                      *ngIf="validations_form.get('target').hasError(validation.type)
                      && (validations_form.get('target').dirty ||
                      validations_form.get('target').touched)">
                      {{ validation.message }}
                    </span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="dropdown">
                    <mat-label i18n="@@RCFormulaexpression">Migration Types</mat-label>
                    <mat-select [(ngModel)]="migration.migrationType"
                      (selectionChange)="onMigrationTypeSelection($event)"
                      formControlName="migrationType" required title="Migration Type">
                      <mat-option *ngFor="let eachtype of migrationType"
                        [(value)]="eachtype.lookupcode">
                        {{eachtype.lookupcode}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngFor="let validation of validation_messages.migrationType">
                      <span
                        *ngIf="validations_form.get('migrationType').hasError(validation.type)
                        && (validations_form.get('migrationType').dirty ||
                        validations_form.get('migrationType').touched)">
                        {{ validation.message }}
                      </span>
                    </mat-error>
                </mat-form-field>

                <ng-container *ngIf="DisplayObject">
                  <mat-form-field appearance="outline" class="dropdown">
                      <mat-label i18n="@@RCFormulaexpression">Object Group Names</mat-label>
                      <mat-select formControlName="objectGroups" (selectionChange)="onObjectGroupSelection($event)" multiple required title="Object Groups">
                        <mat-option *ngIf="(objectGroups.length === 1 && objectGroups[0] === 'No Object Groups Found')" disabled>
                          No Object Groups Found
                      </mat-option>
                      <ng-container *ngIf="!(objectGroups.length === 1 && objectGroups[0] === 'No Object Groups Found')">
                          <mat-option *ngFor="let group of objectGroups" [value]="group.objectGroupId">
                              {{ group.objectgroupname }}
                          </mat-option>
                      </ng-container>
                      </mat-select>
                      <mat-error *ngFor="let validation of validation_messages.objectGroups">
                          <span *ngIf="validations_form.get('objectGroups').hasError(validation.type)
                          && (validations_form.get('objectGroups').dirty || validations_form.get('objectGroups').touched)">
                              {{ validation.message }}
                          </span>
                      </mat-error>
                  </mat-form-field>
              </ng-container>
            </div>
        </form>
    </div>
    <div *ngIf="migration.migrationType === 'Data Virtualization'">
      <form novalidate [formGroup]='validations_form'>
    <div class="dropDownDiv">
    <mat-form-field appearance="outline" class="dropdown">
      <mat-label i18n="@@RCFormulaexpression">Data Lake and Reports</mat-label>
      <mat-select [(ngModel)]="migration.dataLakeAndReports" (selectionChange)="onDataLakeAndReportsSelection($event)" formControlName="dataLakeAndReports" required title="Data Lake and Reports">
          <mat-option *ngFor="let eachtype of dataLakeAndReportsOptions" [(value)]="eachtype.lookupvalue">
              {{eachtype.lookupvalue}}
          </mat-option>
      </mat-select>
      <mat-error *ngFor="let validation of validation_messages.dataLakeAndReports">
          <span *ngIf="validations_form.get('dataLakeAndReports').hasError(validation.type) && (validations_form.get('dataLakeAndReports').dirty || validations_form.get('dataLakeAndReports').touched)">
              {{ validation.message }}
          </span>
      </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="dropdown">
    <mat-label i18n="@@RCFormulaexpression">Data Lake Type</mat-label>
    <mat-select [(ngModel)]="migration.dataLakeType" (selectionChange)="onDataLakeTypeSelection($event)" formControlName="dataLakeType" required title="Data Lake Type">
        <mat-option *ngFor="let eachtype of dataLakeTypeOptions" [(value)]="eachtype.lookupvalue">
            {{eachtype.lookupvalue}}
        </mat-option>
    </mat-select>
    <mat-error *ngFor="let validation of validation_messages.dataLakeType">
        <span *ngIf="validations_form.get('dataLakeType').hasError(validation.type) && (validations_form.get('dataLakeType').dirty || validations_form.get('dataLakeType').touched)">
            {{ validation.message }}
        </span>
    </mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="dropdown">
  <mat-label i18n="@@RCFormulaexpression">JSONTOCSV Validation Reports</mat-label>
  <mat-select [(ngModel)]="migration.JSONTOCSV_Validation_Reports" (selectionChange)="onValidationSelection($event)" formControlName="JSONTOCSV_Validation_Reports" required title="JSONTOCSV_Validation_Reports">
      <mat-option *ngFor="let eachtype of JSONTOCSV_Validation_ReportsOptions" [value]="eachtype.lookupvalue">
          {{eachtype.lookupvalue}}
      </mat-option>
  </mat-select>
  <mat-error *ngFor="let validation of validation_messages.JSONTOCSV_Validation_Reports">
    <span *ngIf="validations_form.get('JSONTOCSV_Validation_Reports').hasError(validation.type) && (validations_form.get('JSONTOCSV_Validation_Reports').dirty || validations_form.get('JSONTOCSV_Validation_Reports').touched)">
        {{ validation.message }}
    </span>
</mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="dropdown">
  <mat-label i18n="@@RCFormulaexpression">Persistence EMR</mat-label>
  <mat-select [(ngModel)]="migration.persistenceEmr" (selectionChange)="onPersistenceEmrSelection($event)" formControlName="persistenceEmr" required title="Persistence EMR">
      <mat-option *ngFor="let eachtype of persisteneceEmrOptions" [(value)]="eachtype.lookupvalue">
          {{eachtype.lookupvalue}}
      </mat-option>
  </mat-select>
  <mat-error *ngFor="let validation of validation_messages.persistenceEmr">
      <span *ngIf="validations_form.get('persistenceEmr').hasError(validation.type) && (validations_form.get('persistenceEmr').dirty || validations_form.get('persistenceEmr').touched)">
          {{ validation.message }}
      </span>
  </mat-error>
</mat-form-field>
</div>
</form>
<div style="margin-left: 1%;">
  <form novalidate [formGroup]="validations_form_input">
      <mat-form-field appearance="outline">
          <mat-label i18n="@@TMSTAFFMASTERfirstname">Dataset Name</mat-label>
          <input matInput type="text" placeholder="Dataset Name"
            formControlName="DatasetName"
            [(ngModel)]="migration.DatasetName" maxlength="100"
            required title="Dataset Name"
            pattern="[a-z0-9-_]+"
            (input)="validateInput($event)">
          <mat-error *ngIf="validations_form_input.get('DatasetName').invalid && validations_form_input.get('DatasetName').touched">
            <span *ngIf="validations_form_input.get('DatasetName').errors.required">
              Dataset Name is required.
            </span>
            <span *ngIf="validations_form_input.get('DatasetName').touched">
              Uppercase letters, spaces, and special characters are not allowed.
            </span>
          </mat-error>
      </mat-form-field>
  </form>
</div>

</div>
    <div class="dataExtractionLevel">
      <h6 style="margin-top: 1.3%;" title="Data Extraction Type">Data Extraction Type: </h6>
        <mat-radio-group style="margin-top: 1.2%; margin-left: 1%;">
            <mat-radio-button title="Full Data" style="font-size: medium" (click)="onSelectDataExtraction(full.value)" value="Full Data" #full>Full Data</mat-radio-button>
            <mat-radio-button title="Partial Data" *ngIf="partialData" style="font-size: medium; margin-left: 25px" (click)="onSelectDataExtraction(partial.value)" value="Partial Data" #partial>Partial Data</mat-radio-button>
        </mat-radio-group>
        <div *ngIf="uploadFile && partialData">
            <form novalidate [formGroup]="validations_form_upload" class="dataExtractionUpload">
                <mat-form-field appearance="outline">
                    <mat-label i18n="@@TMshoppingcomplexchooseimage">Choose File</mat-label>
                    <input
                      title="Choose File"
                      matInput
                      class="file-path validate"
                      type="text"
                      maxlength="250"
                      placeholder="Choose File"
                      formControlName="fileData"
                      id="imageName"
                      [(ngModel)]="fileData.fileName"
                      readonly
                      (change)="imagePhotofunction($event,'csv')"
                    />
                </mat-form-field>
                <div style="margin-left: 1%;">
                    <input type="file" style="display: none;" (change)="imagePhotofunction($event, '.csv')" #fileUploadLink>
                    <button i18n="@@TMRunButton" type="button" (click)="fileUploadLink.click()"
                    [style.background-color]="buttonColor"
                    class="popup gridColour btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize" title="Upload">
                     Upload<em style="padding: 0px 0px 0px 10px;" class="fas fa-upload example-icon" title="Upload"></em>
                </button>
                </div>
            </form>
        </div>
    </div>
    <div style="margin-left: 1%; margin-top: 3%;">
        <form novalidate [formGroup]="validations_form_input">
            <mat-form-field appearance="outline">
                <mat-label i18n="@@TMSTAFFMASTERfirstname">Remarks</mat-label>
                <input matInput type="text" placeholder="Remarks"
                  formControlName="remarks"
                  [(ngModel)]="migration.remarks" maxlength="100"
                  required title="Remarks">
                  <mat-error *ngIf="validations_form_input.get('remarks').invalid && validations_form_input.get('remarks').touched">
                    <span *ngIf="validations_form_input.get('remarks').errors.required">
                        Remarks is required.
                    </span>
                </mat-error>

            </mat-form-field>
        </form>
    </div>
    <div style="text-align: center;">
        <button i18n="@@TargetCancel" type="button" (click)="cancel()" title="Cancel"
                class="cancelButton btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize"
                style="padding: .3rem 1.6rem;font-size: .85rem;" [style.border-color]="buttonColor">
          Cancel
        </button>
        <button  i18n="@@TMRunButton" type="button" (click)="startmigration()"
                [style.background-color]="buttonColor" title="Start Run"
                class="popup gridColour btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize">
          Start Run
        </button>

      </div>
</div>

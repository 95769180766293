export const environment = {
    production: true,
    baseUrl: 'https://jraapapp.jeevlife.com/api',
    authUrl: 'https://jraapapp.jeevlife.com/auth',
    swaggerUrl: 'https://jraapapp.jeevlife.com/swaggerui',
    springBase: 'https://jraapapp.jeevlife.com/jraap',
    dropdownUrl: 'https://jraapapp.jeevlife.com/',
    environmentKnowing: 'UAT',
    idpUrl: 'https://pingfed-ut.merck.com/idp/startSSO.ping?PartnerSpId=pvdmt&ACSIdx=4',
    isSSOlogin: false
};
import { Injectable, Injector, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class validationmethod implements OnInit {
    whiteSpaceErrorMsg = "should not contain any white spaces"
    deductionsmessage = "No deductions for this month"
    requiredErrorMsg = "is required"
    FormValid: UntypedFormGroup
    public requesttypeview = "view"
    public requesttyperun = "run"
    constructor(private snackbar: MatSnackBar) {

    }
    ngOnInit() {

    }
    //Validating all form fields
    validateAllFormFields(formGroup: UntypedFormGroup) {         //{1}
        Object.keys(formGroup.controls).forEach(field => {  //{2}
            const control = formGroup.get(field);             //{3}
            if (control instanceof UntypedFormControl) {             //{4}
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {        //{5}
                this.validateAllFormFields(control);            //{6}
            }
        });
    }



    getvalidationmessage() {
        this.snackbar.open("All mandatory fields to be filled ", "OK", {
            duration: 5000,
        });
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    equalto(field_name): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

            let input = control.value;

            let isValid = control.root.value[field_name] == input
            if (!isValid)
                return { 'equalto': { isValid } }
            else
                return null;
        };
        //   }
        //   public MatchPassword(AC: AbstractControl) {
        //     let password = AC.get('password').value; // to get value in input tag
        //     let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
        //      if(password != confirmPassword) {
        //          console.log('false');
        //          AC.get('confirmPassword').setErrors( {MatchPassword: true} )
        //      } else {
        //       AC.get('cnfrmPassword').setErrors({ MatchPassword: false })
        //          console.log('true');
        //          return null
        //      }
        //  }

    }

  /**
   * Modified by Manit Kumar
   * Date: 26-07-2024
   * Adding method to validate the Special Characters
   */
    public specialCharacterValidator(control: UntypedFormControl) {
      const forbiddenChars = /[<>()'"/\\*;:=\{\}`%+\^!]/;
      const hasForbiddenChars = forbiddenChars.test(control.value);
      return hasForbiddenChars ? { 'specialCharacter': true } : null;
  }
}

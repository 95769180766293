import { catchError } from 'rxjs/internal/operators/catchError';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestApi } from "src/app/shared/RestApi/restApi";

interface toolResponse{
  message : string
  error : boolean
  statusMessage: string
}
interface objectGroup{
  groupname : string
  description : string
  objectid : string
}

@Injectable({
  providedIn: 'root'
})
export class  VaultObjectgroupService {

  
  
  private  headers= new HttpHeaders({
    'Content-Type':'application/json'
  });


  constructor(
    private http: HttpClient,
    private restAPI: RestApi
  ) { }
  insertobjectgroup(reportCreationData){
    console.log(reportCreationData);
    return this.http.post<toolResponse>(this.restAPI.INSERT_OBJECTGROUP,JSON.stringify(reportCreationData),{headers:this.headers})
  }
  retrieveobjectgroup(){
    return this.http.get<objectGroup[]>(this.restAPI.RETRIEVE_OBJECTGROUP,{headers:this.headers})
  }
  retrieveobjects(objectgroupId){
    console.log(objectgroupId);
    return this.http.get<objectGroup>(this.restAPI.RETRIEVE_VAULTOBJECTS_GROUP +"/"+objectgroupId,{headers:this.headers});
  }
  deleteObjects(objectgroupId,dnsId){
    console.log(objectgroupId);
    // console.log(this.restAPI.DELETE_OBJECTGROUP +"/"+groupname);
    return this.http.get<toolResponse>(this.restAPI.DELETE_OBJECTGROUP +"/"+objectgroupId+"/"+dnsId,{headers:this.headers});
  }
  updateObjects(objectGroupId,reportCreationData){
    return this.http.put<toolResponse>(this.restAPI.UPDATE_OBJECTGROUP+"/"+objectGroupId,JSON.stringify(reportCreationData),{headers:this.headers});
  }
  
  // retrieveobjectsbyid(id,objectgroup):Observable<objectGroup>{
  //   return this.http.get<objectGroup[]>(this.restAPI.RETRIEVE_OBJECTGROUP +"/id",{headers:this.headers})
  // }
  // getById(id,users): Observable<UsersInfo>{
  //   //      return this.http.get<UsersInfo>(this.server +'/users'+id).pipe(catchError(this.errorHandler))
       
  //   //   }

  insertDatasetGroup(datasetGroupdata){
    return this.http.post<toolResponse>(this.restAPI.INSERT_DATASET_GROUP,JSON.stringify(datasetGroupdata),{headers:this.headers})
  }

  retrieveDatasetGroup(){
    return this.http.get<toolResponse>(this.restAPI.RETRIEVE_DATASET_GROUP,{headers:this.headers})
  }

  retrieveDatasetBYGroupId(datasetGroupId){
    return this.http.get<toolResponse>(this.restAPI.RETRIEVE_DATASET_BY_DATASETGROUPID+"/"+ datasetGroupId,{headers:this.headers})
  }

  deleteDatasetGroup(datasetGroupId){
    return this.http.get<toolResponse>(this.restAPI.DETELE_DATASET_GROUP+"/"+datasetGroupId,{headers:this.headers})
  }

  updateDatasetGroup(datasetGroupId,datasetGroupData){
    return this.http.post<toolResponse>( this.restAPI.UPDATE_DATASET_GROUP+"/"+datasetGroupId,JSON.stringify(datasetGroupData),{headers:this.headers})
  }

  retrieveAllObjectGroup(tenantId){
    return this.http.get<toolResponse>(this.restAPI.RETRIEVE_ALL_OBJECTGROUPNAME+"/"+tenantId,{headers:this.headers})
  }

  insertMstrObjectGroup(objectGroupdata){
    return this.http.post<toolResponse>(this.restAPI.INSERT_MSTR_OBJECT_GROUP,JSON.stringify(objectGroupdata),{headers:this.headers})
  }

  retrieveAllobjectgroup(dnsId,groupType){
    return this.http.get<objectGroup[]>(this.restAPI.RETRIEVE_ALL_OBJECTGROUP+"/"+dnsId+"/"+groupType,{headers:this.headers})
  }

  generateMappingFile(tenantId,objectGroupId,formdata){
    return this.http.post<toolResponse>(this.restAPI.GENERATE_MAPPING_FILE+"/"+tenantId+"/"+objectGroupId,formdata)
  }

  downloadMappingFile(data){
    return this.http.post<toolResponse>(this.restAPI.zipanddownloadanyfilesFromS3UsingPathOnly,JSON.stringify(data),{headers:this.headers})
  }

  retrieveAllObjectGroupForDataset(tenantId){
    return this.http.get<toolResponse>(this.restAPI.RETRIEVE_ALL_OBJECTGROUPNAME_FOR_DATASET+"/"+tenantId,{headers:this.headers})
  }

}
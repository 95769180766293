import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestApi } from '../RestApi/restApi';

interface reportConfigurationInfo {
  message: any,
  error: boolean,
  data: any,
  statusMessage:any
}

@Injectable({
  providedIn: 'root'
})
export class ReportingServiceService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private Http: HttpClient, private rest: RestApi) { }

  retrieveObjects(vaultObject) {

    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_OBJECTS_URL+"/"+vaultObject, { headers: this.headers })

  }

  retrieveTablesFields(object) {

    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_TABLES_FIELDS_URL + "/" + object, { headers: this.headers })

  }
  
  //Insert Report Service
  insertReportData(reportData) {

    return this.Http.post<reportConfigurationInfo>(this.rest.INSERT_REPORT,JSON.stringify(reportData), { headers: this.headers })

  }

  retrieveMultiselect(dnsName, objectId, value){
    return this.Http.get<any>(this.rest.RETRIEVE_MULTISELECT_VALUE + "/" + dnsName + "/" + objectId + "/" + value, {headers : new HttpHeaders({ 'Content-Type': 'plain/text' })});
  }

  downloadDatasetTemplate(dataseId){
    return this.Http.get(this.rest.RETRIEVE_DOWNLOAD_DATASET_METADATA + "/" + dataseId, {responseType: 'arraybuffer'} );
  }

  retrieveAllObjects(dnsId) {

    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_ALL_OBJECTS_URL + "/" + dnsId, { headers: this.headers })

  }

  createFacets(facets) {

    return this.Http.post<reportConfigurationInfo>(this.rest.CREATE_FACETS_URL,JSON.stringify(facets), { headers: this.headers })

  }

  retrieveAllFacets(tenantId){
    return this.Http.get<any>(this.rest.RETRIEVE_ALL_FACETS_URL + "/" + tenantId, { headers: this.headers })
  }

  retrieveDatasetDetails(dataseId){
    return this.Http.get<String>(this.rest.RETRIEVE_DATASET_URL + "/" + dataseId);
  }
  retrieveDatasetDetailsByBinder(binderId){
    return this.Http.get<String>(this.rest.RETRIEVE_DATASET_URL_BINDER + "/" + binderId);
  }

  checkDatasetIsEditable(datasetId) {

    return this.Http.get<reportConfigurationInfo>(this.rest.CHECK_DATASET_IS_EDITABLE + "/" + datasetId, { headers: this.headers })

  }

  deleteDataset(datasetId) {

    return this.Http.get<reportConfigurationInfo>(this.rest.DELETE_DATASET + "/" + datasetId, { headers: this.headers })

  }
  
  
  retrieveDatasetGroup(vaultObject) {

    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_OBJECTS_URL+"/"+vaultObject, { headers: this.headers })

  }

  retrieveDatasetObjects(datasetGroupId){
    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_DATASET_OBJECTS+"/"+ datasetGroupId,{ headers: this.headers })
  }


  retrieveObjectGroupObject(objectGroupId){
    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_GROUP_OBJECTS+"/"+objectGroupId,{ headers: this.headers });
  }

  /**
   * @author Ashwin M
   * 28/02/2024
   * @param datasetId datasetId for which generation is to be initiated 
   * @returns status of generation request
   */
  refreshDataset(datasetId) {
    return this.Http.post<reportConfigurationInfo>(this.rest.REFRESH_DATASET + "/" + datasetId, { headers: this.headers })
  }


  getUserRoles(username: string) {
    // Replace 'your-api/user' with your actual API endpoint
    return this.Http.get<any>(this.rest.API_URL_SPRING_BASE+`/api/${username}`,{ headers: this.headers });
  }

  updateReportDatasetAccess(datasetAccess){
    return this.Http.post<any>(this.rest.API_URL_SPRING_BASE+`/updateRole`,JSON.stringify(datasetAccess),{ headers: this.headers });
  }



  /**
   * @author Ashwin M
   * 21/03/2024
   * @param userId userId for which associated source dns are to be retrieved
   * @returns List of all source dns's
   */
  retrieveSourceDNS(userId) {
    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_SOURCE_DNS + "/" + userId, { headers: this.headers });
  }

  /**
   * @author Ashwin M
   * 21/03/2024
   * @param dnsId dnsId for which objects are to be retrieved
   * @returns List of all objects for the dnsId
   */
  retrieveObjectsByDnsId(dnsId){
    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_OBJECTS_BY_DNS + "/" + dnsId, { headers: this.headers });
  }

  getDnsIdByObjectGroup(objectId:any){
    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_DNS_BY_OBJECT + "/" + objectId, { headers: this.headers })
  }

  retrieveTablesFieldsForDataset(object) {

    return this.Http.get<reportConfigurationInfo>(this.rest.RETRIEVE_TABLES_FIELDS_FOR_DATASET + "/" + object, { headers: this.headers })


  }
}


